import { Content } from 'antd/lib/layout/layout';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Collapse, Modal } from 'antd';

import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Container } from '../../components/CssFrameworkComponents';
import { IntegrationSteps } from './components/IntegrationSteps';
import { useEvent } from '../events/hooks/useEvent';
import './IntegrationPage.css';
import { InvitationForm } from '../../features/teams';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useInviteUserMutation } from '../api/appUserApiSlice';
import { formatIntegration } from '../../utils/formatIntegration';

const ONBOARDING_IMAGE_PATH = '/assets/img/onboarding/';

const integrationMethods = [
  'node',
  'cloudflare',
  'nginx',
  'apache',
  'iis',
  'ruby',
  'fastly',
  'NextJS',
  'magento-2',
  'other',
];

const collapseContent = [
  {
    header: 'What Does Prerender Do?',
    content: (
      <>
        <p className="pl-4">Prerender has 2 functionalities: </p>
        <ol>
          <li>Render and cache pages</li>
          <li>Serve cached pages only to crawlers requesting them</li>
        </ol>
      </>
    ),
  },
  {
    header: 'How Does Prerender Work?',
    content: (
      <div className="max-w-full">
        <p className="pl-4">
          If a crawler requests a page, your backend identifies it as a crawler and sends a request to Prerender. The
          first time this happens, the page won't be in the cache, called a cache miss. In this case{' '}
          <a href="https://prerender.io/" target="_blank">
            Prerender.io
          </a>{' '}
          needs to fetch the page from you, render it, store it in the cache, and return it to you. This process may
          take several seconds.
        </p>
        <img
          src="/assets/img/first-time-crawler-request.png"
          alt="First time crawler request"
          className="img-width h-auto"
        />
      </div>
    ),
  },
  {
    header: 'Do Users Also Receive the Cached Page?',
    content: (
      <p className="pl-4">
        We only interact with crawlers that request your pages. <br />
        Your users will enjoy the same experience as before without any interruptions.
      </p>
    ),
  },
  {
    header: 'How Does the Verification Work?',
    content: (
      <p className="pl-4">
        We issue a request to your main page using the Bingbot user agent. If the request reaches our system, then we
        validate the integration. <br />
        If Bingbot is not added to your integration or if you use any type of bot verification, we wait until our system
        receives any other request for your pages in order to validate the integration.
      </p>
    ),
  },
  {
    header: `I Don’t Know What Should I Choose`,
    content: (
      <p className="pl-4">
        Don't worry, we are here for you! <br />
        Reach out to us using the bottom left <b>Help!</b> button.
      </p>
    ),
  },
];

const IntegrationPage = () => {
  const history = useHistory();
  const [invitation, setInvitationResult] = useState({});
  const [inviteUser] = useInviteUserMutation();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [activeFAQKeys, setActiveFAQKeys] = useState([]);
  const chargebeePlanId = useSelector((state) => state.prerenderUser.chargebeePlanId);

  const doInviteUserAndRefresh = useCallback(
    async (userData) => {
      setInvitationResult({});
      return inviteUser(userData)
        .then(({ error: inviteUserError }) => {
          const internalError = { error: 'An unexpected error occured, please try again' };
          const invitationResult = inviteUserError
            ? inviteUserError.data
              ? inviteUserError.data
              : internalError
            : userData;

          setInvitationResult(invitationResult);
        })
        .catch((e) => console.error(e));
    },
    [inviteUser]
  );

  const onFAQCollapseItemSelected = (activeKeys) => {
    const filteredKey = activeKeys.find((item) => !activeFAQKeys.includes(item));
    if (filteredKey) {
      track('Installation FAQ Clicked', {
        faq_selected: collapseContent[filteredKey].header,
      });
    }
    setActiveFAQKeys(activeKeys);
  };

  const onCloseModal = () => {
    setShowInviteModal(false);
    setInvitationResult({});
  };

  const { track } = useEvent();

  return (
    <AdminTemplate>
      <Content>
        <Container fluid>
          <div className="row align-items-center m-4">
            <div className="row mt-2">
              <IntegrationSteps current={0} />
            </div>
            <div className="col-auto mt-5">
              <h1>You can choose from one of our sample integrations or customize your own</h1>
            </div>
            <div className="col-auto mt-2">
              <p>
                As Prerender is a server side component, technically it doesn't matter what frontend framework you have.
                The only thing that matters is the way you serve your static files, such as HTML, JS, CSS and so on. The
                advantage of opting for a CDN solution is that you’ll have no limitations when it comes to your hosting
                solution.
              </p>
            </div>
            <div className="container">
              <div className="row">
                {integrationMethods.map((integration) => (
                  <div key={integration} className="col-md-3 col-6 align-self-center p-2 h-100">
                    <button
                      type="button"
                      className="btn btn-outline-info w-100"
                      onClick={() => {
                        track('Integration Method Selected', {
                          integration_method: integration,
                          subscription_plan: chargebeePlanId,
                        });
                        history.push(`/integration/${integration}`);
                      }}
                    >
                      <img
                        src={`${ONBOARDING_IMAGE_PATH + integration.toLowerCase()}.svg`}
                        width="48px"
                        height="48px"
                        alt={integration}
                        className="pb-3"
                      />
                      <h4 className="mb-0">{formatIntegration(integration)}</h4>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="btn-container h-100">
              <button className="btn btn-outline-info" onClick={() => setShowInviteModal(true)}>
                Invite a Team Member
              </button>
            </div>
            <Modal
              title="Invite Colleagues"
              open={showInviteModal}
              onCancel={onCloseModal}
              footer={null}
              destroyOnClose
            >
              <InvitationForm invitation={invitation} onSubmit={doInviteUserAndRefresh} />
            </Modal>
          </div>
          <Collapse
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined className="collapse-icon" /> : <PlusOutlined className="collapse-icon" />
            }
            onChange={onFAQCollapseItemSelected}
          >
            {collapseContent.map((item, index) => {
              return (
                <Collapse.Panel header={item.header} key={index}>
                  {item.content}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Container>
      </Content>
      <Redirect to="/integration-wizard" />
    </AdminTemplate>
  );
};

export default {
  component: requireAuth(IntegrationPage),
};
