import { useEffect, useState } from 'react';
import { useGetDomainStatsQuery } from '../api/integrationWizardSlice';

export const useLoadDomainTechnologies = (domain, onError) => {
  const [pollingInterval, setPollingInterval] = useState(1000);
  const { currentData: domainStats, isFetching: domainStatsLoading } = useGetDomainStatsQuery(domain, {
    skip: !domain,
    refetchOnMountOrArgChange: true,
    pollingInterval,
  });

  useEffect(() => {
    if (domainStats === null && !domainStatsLoading) {
      onError();
    }
    if (Array.isArray(domainStats?.technologies)) {
      setPollingInterval(undefined);
    }
  }, [domainStats, domainStatsLoading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPollingInterval(undefined);
    }, 30_000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {
    isLoading: domainStatsLoading || pollingInterval ? !Array.isArray(domainStats?.technologies) : false,
    domainStats,
  };
};
