import { Tag, Tooltip } from 'antd';
import React from 'react';

export default function SeoScoreStatusComponent({ status }) {
  if (status === 'passed') {
    return (
      <Tooltip title="Healthy" placement="top">
        <Tag color="success">Passed</Tag>
      </Tooltip>
    );
  }
  if (status === 'improvement') {
    return (
      <Tooltip title="Require improvements" placement="top">
        <Tag color="orange">Improvement</Tag>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Will be checked on the next rendering" placement="top">
      <Tag color="default">Not Checked</Tag>
    </Tooltip>
  );
}
