import './BlurringOverlay.css';
import { LockFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useEvent } from '../../features/events/hooks/useEvent';
import { useHistory } from 'react-router';

export const BlurringOverlay = ({ content, enabled, location, buttonData, blurringRadius = 3, opacity }) => {
  const { track } = useEvent();
  let history = useHistory();

  const handleOnClick = () => {
    history.push('/integration-wizard');
    track('Integration Started', { location: location });
  };

  if (!enabled) return <></>;

  return (
    <div
      className="blurring-overlay"
      style={{
        backdropFilter: `blur(${blurringRadius}px)`,
        background: opacity ? `rgba(255, 255, 255, ${opacity})` : '',
      }}
    >
      {content && (
        <div className="blurring-content">
          <LockFilled style={{ color: '#BECAE1' }} />
          <p className="content-text">{content}</p>
          <Button
            className="content-link"
            type="primary"
            onClick={buttonData?.onClick ? buttonData.onClick : handleOnClick}
            href={buttonData?.url}
            target="_blank"
          >
            {buttonData?.title || 'Start Integration'}
          </Button>
        </div>
      )}
    </div>
  );
};
