import { Button, Input, Select } from 'antd';
import { useState } from 'react';
import './dropdownFilters.css';

const selectOptions = [
  { value: 'equals', label: 'Equals' },
  { value: 'between', label: 'Between' },
];

export const StatusDropdownFilter = ({ close, confirm, selectedKeys, setSelectedKeys, clearFilters }) => {
  const [selectedValue, setSelectedValue] = useState('equals');
  const [statusCode, setStatusCode] = useState(selectedKeys.length ? selectedKeys[0] : null);
  const [disabled, setDisabled] = useState(true);

  const onSubmit = () => {
    setSelectedKeys(statusCode == null ? [] : [statusCode]);
    confirm();
  };

  const onReset = () => {
    setStatusCode(null);
    clearFilters();
    setDisabled(true);
  };

  return (
    <div className="dropdownFilters-layout">
      Status Code
      <div className="dropdownFilters-container">
        <Select
          onChange={(value) => setSelectedValue(value)}
          options={selectOptions}
          defaultValue={selectOptions[0].value}
          style={{ width: 120 }}
        />
        {selectedValue === 'between' && (
          <>
            {' '}
            <Input
              placeholder="e.g.200"
              className="dropdownFilters-input"
              value={statusCode?.statusCodeLow}
              onChange={(e) => {
                setStatusCode({ ...statusCode, statusCodeLow: e.target.value, statusCodeEq: null });
                setDisabled(false);
              }}
            />
            and
            <Input
              placeholder="e.g.999"
              className="dropdownFilters-input"
              value={statusCode?.statusCodeHigh}
              onChange={(e) => {
                setStatusCode({ ...statusCode, statusCodeHigh: e.target.value, statusCodeEq: null });
                setDisabled(false);
              }}
            />
          </>
        )}
        {selectedValue === 'equals' && (
          <Input
            placeholder="e.g.200"
            className="dropdownFilters-input"
            value={statusCode?.statusCodeEq}
            onChange={(e) => {
              setStatusCode({ ...statusCode, statusCodeEq: e.target.value, statusCodeLow: null, statusCodeHigh: null });
              setDisabled(false);
            }}
          />
        )}
      </div>
      <div className="dropdownFilters-buttons">
        <Button size="small" type="link" onClick={onReset} disabled={disabled}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={onSubmit}>
          OK
        </Button>
      </div>
    </div>
  );
};
