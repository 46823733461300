import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Layout, Typography, Flex, Table, Input, message, Card } from 'antd';
import { useGetDomainsCountQuery } from './api/domainsCountApiSlice';
import { SearchOutlined } from '@ant-design/icons';
import { Pagination } from '../../components/PaginationLib';
import { calculateStats } from '../../assets/pagingFunctions';

const { Content } = Layout;
const { Title } = Typography;

const PAGE_SIZE = 100;

const mapToApiPayload = (params) => {
  return {
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    domain: params.filters?.domain ? params.filters?.domain[0] : undefined,
  };
};

const DomainsCountPage = () => {
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'number_of_urls',
      order: 'ascend',
    },
  };
  const [domains, setDomains] = useState([]);
  const [tableParams, setTableParams] = useState(initialParams);
  const [search, setInput] = useState('');
  const [domainStats, setDomainStats] = useState({ total: 0, pages: 0, page: 0 });
  const { data, error, isFetching } = useGetDomainsCountQuery(mapToApiPayload(tableParams), {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      setDomains(data.data);
      setDomainStats(
        calculateStats(
          {
            data: data.data,
            params: {
              pageSize: tableParams.pagination.pageSize,
              page: tableParams.pagination.current - 1,
            },
          },
          PAGE_SIZE
        )
      );
    } else if (error) {
      message.error('Failed to fetch domains count');
    }
  }, [data, error]);

  const handleTableChange = (pagination, filters, sorter) => {
    const newParams = {
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: PAGE_SIZE,
      },
      sorter,
    };
    setTableParams(newParams);
  };

  const debouncedSetTableParams = useCallback(
    debounce((newInput) => {
      const newParams = {
        ...tableParams,
        pagination: { ...tableParams.pagination, current: 1 },
        filters: { domain: [newInput] },
      };
      setTableParams(newParams);
    }, 1500),
    [tableParams]
  );

  const onChangeInput = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    debouncedSetTableParams(newInput);
  };

  const onChangePagination = (action) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: action.payload + 1,
      },
    });
  };

  return (
    <AdminTemplate>
      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <Title level={3}>Page count per domain</Title>
          <Card>
            <Input
              placeholder="Please enter domain name"
              allowClear
              value={search}
              onChange={onChangeInput}
              onPressEnter={onChangeInput}
              prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              style={{ marginBottom: 16, width: '60%' }}
            />
            <Table loading={isFetching} pagination={false} dataSource={domains} onChange={handleTableChange} bordered>
              <Table.Column title="Domain" dataIndex="domain" key="domain" width={'60%'} />
              <Table.Column title="Page Count" dataIndex="number_of_urls" key="number_of_urls" sorter={true} />
            </Table>
            <Pagination
              isLoading={isFetching}
              stats={{
                ...domainStats,
                page: tableParams.pagination.current - 1,
                amtpp: tableParams.pagination.pageSize,
              }}
              onPageChange={onChangePagination}
            ></Pagination>
          </Card>
        </Flex>
      </Content>
    </AdminTemplate>
  );
};

export default { component: requireAuth(DomainsCountPage) };
