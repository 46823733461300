import { Button, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { formatCentsToDollarLocale } from '../../../assets/currencies';
import FeatureIsInPlanIcon from '../../../assets/icons/feature-is-in-plan-icon.svg';
import { AddonBillingType } from '@prerender/billing-shared';
import { createHubSpotFormUrl } from '../../../utils/createHubSpotFormUrl';

const PackageCard = ({ packageData, onSelectPackage }) => {
  const user = useSelector((state) => state.prerenderUser);
  const buttonTitle = (packageData) => {
    if (packageData.isDisableKeywordPackageCard) {
      return 'Contact Us';
    }
    switch (packageData.billingType) {
      case AddonBillingType.Subscription:
        return packageData.isActive ? 'Unsubscribe' : 'Subscribe';
      case AddonBillingType.OneTime:
        return 'Buy';
      default:
        break;
    }
  };

  const disabledButtonForOneTime =
    packageData.billingType === AddonBillingType.OneTime && packageData.isActive === true;

  const openHubSpotForm = () => {
    const hubSpotFormUrl = createHubSpotFormUrl(user.firstName, user.email);
    window.open(hubSpotFormUrl, '_blank');
  };

  return (
    <div
      style={packageData.isActive ? { border: '2px solid #6FC49D', backgroundColor: '#FAFFFC' } : {}}
      className="card"
    >
      <div className="card-body">
        {/* Header */}
        <div className="d-flex align-items-center">
          <p style={{ fontSize: '20px' }} className="ml-2 pt-2 card-title font-weight-bold ">
            {packageData.name}
          </p>
          {packageData.current && (
            <div className="ml-2">
              <img src={CurrentPlanIcon} />
            </div>
          )}
          {!packageData.current && <div className="ml-2"></div>}
        </div>
        {/* Price */}
        <div className="d-flex align-items-end">
          <span style={{ fontSize: '60px' }} className="font-weight-bold d-flex align-items-end">
            {formatCentsToDollarLocale(packageData.costInCents)}
          </span>
          <span className="ml-1 mb-4">
            {packageData.billingType == AddonBillingType.Subscription ? 'per month' : 'one-time payment'}
          </span>
        </div>
        <div style={{ minHeight: 80 }}>
          <p>{packageData.description}</p>
        </div>
        {/* Features */}
        <div style={{ minHeight: 150 }}>
          {packageData.features.map((item, index) => (
            <div className="d-flex align-items-center" key={index}>
              <img src={FeatureIsInPlanIcon} />
              <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                {item.title} {item.description}
              </p>
            </div>
          ))}
        </div>
        {/* Subscribe / Unsubscribe Button */}
        <Tooltip
          title={
            packageData.billingType === AddonBillingType.OneTime
              ? 'This package can only be purchased once per month'
              : ''
          }
        >
          <Button
            type="primary"
            disabled={disabledButtonForOneTime}
            style={{ width: '100%' }}
            onClick={packageData.isDisableKeywordPackageCard ? openHubSpotForm : onSelectPackage}
          >
            {buttonTitle(packageData)}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PackageCard;
