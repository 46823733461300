import { Fragment } from 'react';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { ControlButton } from '../../../components/PageElementsLib';
import { Container } from '../../../components/CssFrameworkComponents';
import { PREVIOUS_STEP } from '../redux/IntegrationActions';
import { useHistory } from 'react-router';

const SUCCESS_IMAGE = '/assets/img/onboarding/integrationSuccessful.svg';
const FAILED_IMAGE = '/assets/img/onboarding/integrationFailed.svg';

const replaceCommonLink = (user, errors, integration, url, data) => {
  let { link } = data;
  if (link) {
    link = link
      .replace('{{email}}', user.email)
      .replace('{{errorCode}}', JSON.stringify(errors))
      .replace('{{integration}}', integration)
      .replace('{{url}}', url);
  }

  return {
    ...data,
    link,
  };
};

const verificationErrors = {
  0: {
    content: (
      <>
        <span>
          We couldn't fetch your page. It probably has nothing to do with your Prerender integration. It can happen when
          your site doesn't respond with an HTTP200, is not available publicly on the Internet, or has some other type
          of traffic filtering. Please adjust your site's settings and try again.
        </span>
      </>
    ),
  },
  1: {
    content: (
      <>
        <span>
          Looks like the integration is not working as expected. Failures could be caused by the following reasons:
        </span>
        <ul>
          <li>Integration misconfiguration</li>
          <li>IP restrictions on your website</li>
          <li>Bot restrictions on your website</li>
          <li>Using Cache solution</li>
        </ul>
        <span>We recommend that you try one of the following steps:</span>
        <ul>
          <li>Check your integration</li>
          <li>
            Whitelist our service IPs that you can find{' '}
            <a href="https://docs.prerender.io/docs/community-maintained-middleware" target="_blank">
              here
            </a>
          </li>
          <li>Check your CDN configuration</li>
        </ul>
      </>
    ),
  },
  2: {
    content: (
      <>
        <span>
          We couldn't fetch your page. It probably has nothing to do with your Prerender integration. It can happen when
          your site doesn't respond with an HTTP200, is not available publicly on the Internet, or has some other type
          of traffic filtering. Please adjust your site's settings and try again.
        </span>
      </>
    ),
  },
  3: {
    content: (
      <>
        <span>
          Seems like your integration is almost perfect. We have managed to fetch your site, and we also received a
          request from your backend to fetch the prerendered content, but something unexpected has happened while
          rendering it. Please double-check if your site can be fetched properly with Prerender.io's{' '}
          <a href="https://docs.prerender.io/article/33-overview-of-prerender-crawlers" target="_blank">
            user agents
          </a>
          .
        </span>
      </>
    ),
  },
};

const getDocsLinks = (integration) => {
  switch (integration.toLowerCase()) {
    case 'node':
      return {
        docs: { text: 'Node Integration Docs', link: 'https://docs.prerender.io/docs/nodejs' },
        pdf: { text: 'Node PDF Guide', link: 'https://dashboard-cdn.prerender.io/ExpressJS-Javascript-Nuxtjs.pdf' },
      };
    case 'ruby':
      return {
        docs: { text: 'Ruby Integration Docs', link: 'https://docs.prerender.io/v1/docs/rails-ruby-1' },
        pdf: { text: 'Ruby PDF Guide', link: 'https://dashboard-cdn.prerender.io/Rails-Ruby.pdf' },
      };
    case 'nginx':
      return {
        docs: { text: 'Nginx Integration Docs', link: 'https://docs.prerender.io/v1/docs/nginx-1' },
        pdf: { text: 'Nginx PDF Guide', link: 'https://dashboard-cdn.prerender.io/Nginx-Integration.pdf' },
      };
    case 'iis':
      return {
        docs: { text: 'IIS Integration Docs', link: 'https://docs.prerender.io/v1/docs/18-integrate-with-iis' },
        pdf: { text: 'IIS PDF Guide', link: 'https://dashboard-cdn.prerender.io/IIS.pdf' },
      };
    case 'cloudflare':
      return {
        docs: { text: 'Cloudflare Integration Docs', link: 'https://docs.prerender.io/v1/docs/cloudflare-1' },
        pdf: {
          text: 'Cloudflare PDF Guide',
          link: 'https://dashboard-cdn.prerender.io/Cloudflare-Integration-for-ESM-Syntax.pdf',
        },
      };
    case 'apache':
      return {
        docs: { text: 'Apache Integration Docs', link: 'https://docs.prerender.io/v1/docs/apache-2' },
        pdf: { text: 'Apache PDF Guide', link: 'https://dashboard-cdn.prerender.io/Apache.pdf' },
      };
    case 'magento-2':
      return {
        docs: { text: 'Magento 2 Integration Docs', link: 'https://docs.prerender.io/docs/magento-2' },
        pdf: { text: 'Magento 2 PDF Guide', link: 'https://dashboard-cdn.prerender.io/Magento-2-Integration.pdf' },
      };
    default:
      return {
        docs: { text: 'Integration Docs', link: 'https://docs.prerender.io/docs/integrations-1' },
      };
  }
};

const IntegrationVerificationResult = ({ cm, prerenderUser, integration, validationResult, onEvent }) => {
  if (!validationResult) return null;

  const { working, errors, url } = validationResult;
  const image = working ? SUCCESS_IMAGE : FAILED_IMAGE;
  const successOrFailed = working ? 'success' : 'failed';
  const bodyContent = cm.body[successOrFailed];
  const history = useHistory();
  const links = getDocsLinks(integration);

  return (
    <Fragment>
      <Container fluid>
        <div className="row justify-content-center m-4">
          <div className="col-12 text-center">
            <img src={image} alt="success state" style={{ maxHeight: '75px', maxWidth: '75px' }} />
          </div>
          <div className="col-12 pt-4">
            <h1 className="text-center">{bodyContent.title}</h1>
          </div>
          <div className="col-12">
            {working && (
              <>
                <p className="text-center">
                  You can now check the CDN Analytics section to see what pages are being requested by crawlers. For
                  more testing methods you can check{' '}
                  <a
                    href="https://docs.prerender.io/docs/how-to-test-your-site-after-you-have-successfully-validated-your-prerender-integration"
                    target="_blank"
                  >
                    our article
                  </a>{' '}
                  on how to test your integration.
                </p>
              </>
            )}
            {!working && (
              <div className="mt-3">
                {errors.map((errorCode, index) => (
                  <div key={index}>{verificationErrors[errorCode].content}</div>
                ))}

                <p>
                  For more info, kindly check <a href={links.docs.link}>{links.docs.text}</a>
                  {links.pdf ? (
                    <Fragment>
                      <span> or </span> <a href={links.pdf.link}>{links.pdf.text}</a>{' '}
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>

                <p>
                  {cm.body.failed.common.texts
                    .map((data) => replaceCommonLink(prerenderUser, errors, integration, url, data))
                    .map(createHtmlContentFromCmText)}
                </p>
              </div>
            )}
          </div>
        </div>
      </Container>

      <div className="card-footer" id="modal-footer">
        <div className="row align-items-center">
          <div className="col-auto pl-0">
            {!working && <ControlButton text={cm.footer.btnLeft} onclick={() => onEvent({ action: PREVIOUS_STEP })} />}
          </div>
          <div className="col text-center">
            <h3 className="mb-0"></h3>
          </div>
          <div className="col-auto pr-0">
            {<ControlButton text={cm.footer.btnRight} onclick={() => history.push('/')} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IntegrationVerificationResult;
