import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Space, Alert, Flex, Typography } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cachingActivitySearch from '../actions/cachingActivityActions';
import cachingStatsSearch from '../actions/cachingStatsActions';
import { closeModal, getContent, openModal } from '../actions/pageActions';
import { getPrerenderUser } from '../actions/prerenderUserActions';
import { getChartData } from '../assets/charts';
import ActivitySearchEditor from '../components/ActivitySearchEditor';
import { Card } from '../components/CssFrameworkComponents';
import ErrorLoader from '../components/ErrorLoader';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import './RenderHistoryPage.css';
import { useEvent } from '../features/events/hooks/useEvent';
import { RenderHistoryTable } from '../features/render-history/RenderHistoryTable';
import ExportRenderHistoryModal from '../features/render-history/ExportRenderHistoryModal';
import { getDomains } from '../actions/domainActions';
import { isFreePlan } from '../chargebee/chargebee';
import { Pagination } from '../components/PaginationLib';
import { rendersApiSlice } from '../features/api/rendersApiSlice';
import ConfirmationModal from '../components/ConfirmationModal';
import { DomainSearchDropdown } from '../components/DomainSearchDropdown';

const { Text, Title } = Typography;

const cid = 'CachingActivityPage';
const PAGE_SIZE = 50;
const urlListSearchedEventName = 'Render hisroty URL List Searched';

class CachingActivityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customMessage: null,
      initialSearchQuery: props.cachingActivity.params.query,
      searchBarId: null,
    };
  }

  static mapExportAnalyticsResponseStatusToUserText(status) {
    switch (status) {
      case 'queued':
        return 'We have scheduled your export. You will get a download link in your email once the export is ready.';
      case 'already exists':
        return "You already have an export in progress. We'll notify you via email when that export finishes.";
      default:
        return "Something bad has happened. We're looking into it...";
    }
  }

  componentDidMount() {
    const { cm, language, getContent: doGetContent } = this.props;
    if (!cm) doGetContent({ groups: ['cachingActivity', 'default'], lang: language });
    this.performSearch({ page: 0, pageSize: PAGE_SIZE, q: '', sort: 'time', sortDirection: 'DESC' });
    this.loadDomains();
    this.setState({ searchBarId: new Date().getTime() });
  }

  performQuerySearch(q) {
    const { track } = useEvent();
    if (q.length > 2 || q.length === 0) {
      this.performSearch({
        ...this.props.cachingActivity.params,
        page: 0,
        q,
        qCondition: this.props.cachingActivity.params.qCondition || 'like',
      });
      if (q.length > 2) {
        track(urlListSearchedEventName, {
          search_string: q,
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
        });
      }
    }
  }

  performQuerySearchAnyway(q) {
    const { track } = useEvent();
    this.setState({ querySearchInProgress: false });
    this.performSearch({
      ...this.props.cachedPages.params,
      page: 0,
      q,
      qCondition: this.props.cachingActivity.params.qCondition || 'like',
    });
    track(urlListSearchedEventName, {
      search_string: q,
      subscription_plan: this.props.prerenderUser.chargebeePlanId,
    });
  }

  onSearchConditionChanged(qCondition) {
    if (this.props.cachingActivity.params.q.length === 0 || this.props.cachingActivity.params.q.length > 2) {
      this.performSearch({ ...this.props.cachingActivity.params, page: 0, qCondition });
    }
  }

  performSearch(params, options = { onlyMainSearch: false }) {
    const {
      cachingActivitySearch: doCachingActivitySearch,
      cachingStatsSearch: doCachingStatsSearch,
      cachingActivity,
      cachingStats,
    } = this.props;
    if (!cachingActivity.inProgress) doCachingActivitySearch('all', params);
    if (!cachingStats.inProgress && !options.onlyMainSearch) {
      doCachingStatsSearch('overview', params);
      doCachingStatsSearch('errorStats', params);
    }
  }

  loadDomains(query = null) {
    const { getDomains: getDomains } = this.props;
    getDomains(query);
  }

  performDomainSearch(domain) {
    const { cachingActivity } = this.props;
    this.performSearch({ ...cachingActivity.params, page: 0, domain });
  }

  reloadData(page = this.props.cachingActivity.params.page) {
    const { getPrerenderUser: doGetPrerenderUser, cachingActivity } = this.props;
    doGetPrerenderUser();
    this.performSearch({ ...cachingActivity.params, page });
  }

  clearFilters() {
    this.setState({ querySearchInProgress: false });
    this.performSearch({ page: 0, pageSize: PAGE_SIZE });
  }

  didReceiveAction({ action, payload }) {
    const { cachingActivity, openModal: doOpenModal, closeModal: doCloseModal, exportHistory } = this.props;
    switch (action) {
      case 'modalCancelled':
        doCloseModal();
        break;
      case 'changePage':
        this.performSearch({ ...cachingActivity.params, page: payload }, { onlyMainSearch: true });
        break;
      case 'openParamsModal':
        doOpenModal(
          'activitySearch',
          <ActivitySearchEditor
            activityData={cachingActivity}
            onEvent={(eventAction) => this.didReceiveAction(eventAction)}
          />
        );
        break;
      case 'updateSearchParams': {
        const {
          statusCodeEq,
          statusCodeLow,
          statusCodeHigh,
          responseTimeLow,
          responseTimeHigh,
          timedout,
          adaptive_type,
          renderedTimeLow,
          renderedTimeHigh,
          sort,
          sortDirection,
        } = payload;
        this.performSearch({
          ...cachingActivity.params,
          statusCodeEq,
          statusCodeLow,
          statusCodeHigh,
          responseTimeLow,
          responseTimeHigh,
          timedout,
          adaptive_type,
          renderedTimeLow,
          renderedTimeHigh,
          sort,
          sortDirection,
          page: 0,
        });
        break;
      }
      case 'openExportModal':
        doOpenModal(
          // not sure if this ID needs to be unique so that it doesn't conflict with CSV export from CachedPagesPage
          'exportRenderHistoryModal',
          <ExportRenderHistoryModal onEvent={(onEventAction) => this.didReceiveAction(onEventAction)} />
        );
        break;
      case 'exportAnalytics':
        doCloseModal();
        exportHistory({ ...payload, ...cachingActivity.params })
          .then(({ data, error }) => {
            this.didReceiveAction(
              data
                ? { action: 'openExportSuccessModal', payload: data }
                : { action: 'openExportErrorModal', payload: error }
            );
          })
          .catch((e) => console.error('Unexpected error:', e));
        break;
      case 'openExportSuccessModal':
        doOpenModal(
          'exportRenderHistorySuccessModal',
          <ConfirmationModal
            title={'Export Render History'}
            text={CachingActivityPage.mapExportAnalyticsResponseStatusToUserText(payload.status)}
            options={[{ action: 'cancel', text: 'Close' }]}
            onAction={(_action) => {}}
          />
        );
        break;
      case 'openExportErrorModal':
        doOpenModal(
          'exportRenderHistoryErrorModal',
          <ConfirmationModal
            title={'Export Render History'}
            text={"Something bad has happened. We're looking into it..."}
            options={[{ action: 'cancel', text: 'Close' }]}
            onAction={(_action) => {}}
          />
        );
        break;
      default:
        console.warn(`Unknown action: ${action}`); // TODO: handle error
    }
  }

  render() {
    const {
      cm,
      apiErrTxt,
      lastErrorCode,
      cachingActivity,
      cachingStats,
      prerenderUser,
      modalVisible,
      isMobile,
      domains,
    } = this.props;
    const { customMessage } = this.state;
    const { query, page, pageSize } = cachingActivity.params;

    const paidPlan = !isFreePlan(prerenderUser.plan);

    if (!cm) return null;
    return (
      <AdminTemplate metaTags={cm.meta}>
        <Flex vertical style={{ marginBottom: 32 }}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={2} style={{ marginBottom: 8 }}>
              Render History
            </Title>
            <Space>
              {domains.domains.length > 1 && paidPlan && (
                <DomainSearchDropdown
                  domains={domains.domains}
                  performDomainSearch={(domain) => this.performDomainSearch(domain)}
                />
              )}
            </Space>
          </Flex>
          <Text type="secondary">Pages that have been recently rendered</Text>
          <ErrorLoader code={modalVisible ? '' : lastErrorCode} custom={customMessage} scrollTop>
            <div className="mt-4 mb-0">
              <Alert
                className="col-12"
                showIcon
                type="error"
                message={apiErrTxt[lastErrorCode] ? apiErrTxt[lastErrorCode] : customMessage?.text}
              />
            </div>
          </ErrorLoader>
        </Flex>

        <div className="container-fluid" style={{ padding: 0 }}>
          <div className="row">
            <Card width="col-12" title="Render Usage - Last 24 Hours">
              <div style={{ height: '240px', padding: '12px' }}>
                <Line
                  ref="chart"
                  data={getChartData('area', cachingStats.overview.params.timePeriod, [
                    { source: cachingStats.errorStats.requests, label: 'Failed Renders', color: 'warning' },
                    { source: cachingStats.overview.requests, label: 'Total Renders', color: 'success' },
                  ])}
                  options={{
                    animation: false,
                    plugins: {
                      legend: { display: true, position: isMobile ? 'top' : 'right', labels: { boxWidth: 10 } },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        ticks: { stepSize: 1, beginAtZero: true, autoSkipPadding: 20 },
                        grid: { color: '#f0f0f0' },
                      },
                      x: { grid: { color: '#f0f0f0' } },
                    },
                    tooltips: {
                      enabled: true,
                    },
                  }}
                />
              </div>
            </Card>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mb-3"></div>
            <Card width="col-12">
              <RenderHistoryTable
                dataSource={cachingActivity}
                onChangeTable={(onEventAction) => this.didReceiveAction(onEventAction)}
                onUpdateTable={() => this.reloadData()}
                clearFilters={() => this.clearFilters()}
                performQuerySearch={(q) => {
                  this.performQuerySearch(q);
                }}
                performQuerySearchAnyway={(q) => {
                  this.performQuerySearchAnyway(q);
                }}
                onSearchConditionChanged={(c) => this.onSearchConditionChanged(c)}
                state={this.state}
              />
              <Pagination
                onPageChange={(action) => this.didReceiveAction(action)}
                stats={{
                  total: query ? cachingActivity.stats.total : cachingActivity.params.pageSize,
                  amt: cachingActivity.stats.amt,
                  pages:
                    cachingActivity.stats.amt < cachingActivity.params.pageSize
                      ? cachingActivity.stats.pages
                      : page + 1,
                  page,
                  amtpp: pageSize,
                }}
              />
            </Card>
          </div>
        </div>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    isMobile: state.page.mobileDevice,
    editorCm: state.page.contentData.ActivitySearchEditor,
    ssrEntryUrl: state.page.ssrEntryUrl,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
    language: state.page.language,
    cachingActivity: state.cachingActivity.all,
    cachingStats: state.cachingStats,
    prerenderUser: state.prerenderUser,
    modalVisible: state.page.modalVisible,
    domains: state.domains,
    initialSearchQuery: state.cachingStats,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      cachingActivitySearch,
      getPrerenderUser,
      openModal,
      closeModal,
      cachingStatsSearch,
      getDomains,
      exportHistory: rendersApiSlice.endpoints.exportHistory.initiate,
    },
    dispatch
  );
}

function loadData(store) {
  const state = store.getState();
  return store.dispatch(getContent({ groups: ['cachingActivity', 'default'], lang: state.page.language }));
}

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(CachingActivityPage)),
  loadData,
};
