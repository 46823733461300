import { Link } from 'react-router-dom';
import { DeleteOutlined, EyeOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Empty, Table, Space, Popconfirm, Tag } from 'antd';

function DomainsTable({ isLoading, rows = [], onDeleteClick, pagination }) {
  const tableColumns = [
    {
      title: 'Domain',
      key: 'domain',
      dataIndex: 'domain',
      width: '99%',
      className: 'text-truncate url-shortener',
      fixed: 'left',
      render: (text) => <span style={{ fontFamily: 'monospace' }}>{text}</span>,
    },
    {
      title: 'Keywords',
      key: 'keywords',
      dataIndex: 'keywords',
      className: 'col-min',
      render: (keywords) => {
        const firstFiveKeywords = keywords.slice(0, 5);
        const remainingKeywords = keywords.length - firstFiveKeywords.length;
        return (
          <>
            {firstFiveKeywords.map((keyword, i) => (
              <Tag key={`${keyword}-${i}`}>{keyword}</Tag>
            ))}
            {remainingKeywords > 0 && <Tag key="--more">+ {remainingKeywords}</Tag>}
          </>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      className: 'col-min',
      fixed: 'right',
      render: (_text, item) => (
        <Space>
          <Link to={{ pathname: `/rank-tracker/${item.id}`, state: { domain: item.domain } }}>
            <Button icon={<EyeOutlined />}></Button>
          </Link>
          <Popconfirm
            title={`Delete "${item.domain}"`}
            description="Are you sure to delete this domain?"
            onConfirm={() => onDeleteClick(item.id)}
            okText="Delete"
            cancelText="Cancel"
            okType="danger"
            icon={<WarningOutlined />}
            placement="left"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You don't have any domain yet, start by adding one." />
      )}
    >
      <Table
        bordered={!isLoading}
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={rows}
        pagination={pagination}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default DomainsTable;
