import { Typography, Alert, Space, Button, Divider, Flex, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph, Title } = Typography;
function IntegrationError() {
  const { token } = theme.useToken();

  return (
    <Space size={token.sizeXXL} direction="vertical">
      <Alert
        message={<Title level={4}>Integration not detected</Title>}
        type="error"
        showIcon
        description="We can reach your site but can't detect the Prerender integration."
      />

      <Flex justify="center" gap="large">
        <div>
          <Title level={5}>Review the integration guide</Title>
          <Paragraph>Double-check the steps to make sure everything is set up correctly.</Paragraph>
          <Button type="link" size="small">
            <Link to="/integration-wizard/suggested-integration">Go back</Link>
          </Button>
        </div>
        <div>
          <Divider type="vertical" style={{ height: '100%' }} />
        </div>
        <div>
          <Title level={5}>Need help?</Title>
          <Paragraph>Reach out to us to set up a free 1-hour onboarding call with us.</Paragraph>
        </div>
      </Flex>
    </Space>
  );
}

export default IntegrationError;
