import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import internalApi from '../assets/internalApi';
import config from '../assets/config';
import requireAuth from '../components/hocs/requireAuth';

const RawHtmlPage = () => {
  const [cachedPage, setCachedPage] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const id = query.get('id');

  useEffect(() => {
    if (!cachedPage) {
      internalApi(
        'GET',
        `${config.apiUrl.dataServer}/cached-html?id=${id}`
      )
        .then((result) => {
          setCachedPage(result);
        })
        .catch((err) => {
          console.log(err);
          setErrorText('Internal error!');
        });
    }
  }, [cachedPage, id]);

  if (cachedPage) {
    const rawHtmlBlob = new Blob([cachedPage], {
      type: 'text/html',
    });

    const urlObject = URL.createObjectURL(rawHtmlBlob);
    return <iframe style={{ height: '100vh', width: '100vw' }} title={id} src={urlObject}></iframe>;
  } else {
    return <div className='text-center'>
        { errorText && <h2 style={{ margin: '45vh' }}>{errorText}</h2> }
        { !errorText && <div className='spinner-border' style={{ margin: '45vh' }} role="status">
          <span className='sr-only'>Loading...</span>
        </div>
        }
      </div>;
  }
};

export default {
  component: requireAuth(RawHtmlPage),
};
