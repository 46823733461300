import { Content } from 'antd/lib/layout/layout';
import { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent } from '../actions/pageActions';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const InsightRouter = lazy(() => import('./insight/InsightRouter'));

const InsightPage = ({ cm, language, getContent: doGetContent, user }) => {
  useEffect(() => {
    if (!cm) {
      doGetContent({ component: 'FeatureRequestPage', lang: language });
    }
  }, [doGetContent, language, cm]);

  if (!cm) {
    return null;
  }

  return (
    <AdminTemplate>
      <Content>
        <Suspense fallback={<h1>Loading...</h1>}>
          <InsightRouter user={user} />
        </Suspense>
      </Content>
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  cm: state.page.contentData['FeatureRequestPage'],
  user: state.prerenderUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContent,
    },
    dispatch
  );

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(InsightPage)),
};
