import { Alert } from 'antd';
import React from 'react';

export default function ExpFeatureNotice() {
  return (
    <Alert
      showIcon
      type="warning"
      bordered
      description="Features with experimental tag, are only available for preview, and the data may be incorrect or not fresh enough for production usage."
      message="Experimental Feature Warning!"
      closable
    />
  );
}
