import { Modal } from 'antd';

const ExportCSVModal = ({ onClose, onExport, showModal }) => {
  return (
    <Modal
      title="Export CSV"
      closable
      cancelText="Cancel"
      okText="Export"
      open={showModal}
      onCancel={onClose}
      onOk={onExport}
    >
      Export SEO Report for this domain as CSV?
    </Modal>
  );
};

export default ExportCSVModal;
