import { Alert, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import UpgradeDetails from '../../components/billing/UpgradeDetails';
import BillingHistory from './history/BillingHistory';
import BillingPayment from './payment/BillingPayment';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Header, NavTabsComponent } from '../../components/CssFrameworkComponents';
import BillingPlans from './plans/BillingPlans';
import BillingOverview from './overview/BillingOverview';
import { useEvent } from '../../features/events/hooks/useEvent';
import { usePageContent } from '../../hooks/usePageContent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import BillingPackages from './packages/BillingPackages';
import { CouponsOverview } from '../Coupons';
import ReferralOverview from './referral/ReferralOverview';
import { isEnterprise } from '@prerender/billing-shared';

const PAGES = {
  overview: 'overview',
  plans: 'plans',
  packages: 'packages',
  history: 'history',
  payment: 'payment',
  coupons: 'coupons',
  referral: 'refer-and-earn',
};

const HIDE_RETIRE_PLAN_NOTIFICATION_FOR_USERS = [130475, 132638, 22323];

const BillingPage = () => {
  const history = useHistory();
  const route = useRouteMatch();
  const { pathname } = useLocation();
  const user = usePrerenderUser();
  const [isOnLegacy, setIsOnLegacy] = useState(false);
  const [isUpgradedByUS, setIsUpgradedByUS] = useState(false);
  const [showUpgradeDetails, setShowUpgradeDetails] = useState(false);

  const { track } = useEvent();

  const isEnterpriseUser = isEnterprise(user?.chargebeePlanId);

  const { cm } = usePageContent('FeatureRequestPage', { groups: ['cachedPages', 'default'] });

  useEffect(() => {
    if (user) {
      setIsOnLegacy(!user.isMeteredBilling);

      if (user?.upgradedFromChargebeePlanId === 'prerender-base-plan') {
        // Check if the user.upgradedAt is within 30 days
        const upgradedAt = new Date(user?.upgradedAt);
        const today = new Date();
        const diffTime = Math.abs(today - upgradedAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays <= 30) {
          setIsUpgradedByUS(true);
        }
      }
    }
  }, []);

  if (!cm) {
    return null;
  }

  const tabs = [
    { id: PAGES.overview, text: 'Overview' },
    { id: PAGES.plans, text: 'Subscription Plans', hidden: typeof user.customPrice === 'number' },
    { id: PAGES.packages, text: 'Packages' },
    { id: PAGES.history, text: 'Billing History' },
    { id: PAGES.payment, text: 'Billing Info' },
    { id: PAGES.coupons, text: 'Offers & Discounts' },
    { id: PAGES.referral, text: 'Refer & Earn', hidden: isEnterpriseUser },
  ];

  const activeTab = pathname.split('/').pop() || PAGES.overview;

  const onTabChange = ({ payload }) => {
    const params = { subscription_plan: user.planName, location: 'Navigation' };
    switch (payload) {
      case PAGES.overview:
        track('Billing Overview Page Viewed', params);
        break;
      case PAGES.plans:
        track('Billing Plans Page Viewed', params);
        break;
      case PAGES.packages:
        track('Billing Packages Page Viewed', params);
        break;
      case PAGES.history:
        track('Billing History Viewed', params);
        break;
      case PAGES.payment:
        track('Payment Settings Viewed', params);
        break;
      case PAGES.coupons:
        track('Coupons Page Viewed', params);
        break;
      case PAGES.referral:
        track('Referral Page Viewed', params);
        break;
      default:
        break;
    }
    history.push(`${route.path}/${payload}`);
  };

  return (
    <AdminTemplate>
      <Content>
        <Header title="Billing">
          <NavTabsComponent tabs={tabs} activeTab={activeTab} onAction={onTabChange} />
        </Header>

        {isOnLegacy && !HIDE_RETIRE_PLAN_NOTIFICATION_FOR_USERS.includes(user.companyId) && (
          <div className="px-5 mb-4">
            <Alert
              showIcon
              type="warning"
              message={
                <>
                  Your subscription plan has been <b>retired</b>, and will be upgraded soon.
                </>
              }
              action={
                <Button type="primary" onClick={() => setShowUpgradeDetails(true)} loading={showUpgradeDetails}>
                  Learn More
                </Button>
              }
            />
            <UpgradeDetails open={showUpgradeDetails} onClose={() => setShowUpgradeDetails(false)} />
          </div>
        )}
        {isUpgradedByUS && (
          <div className="px-5 mb-4">
            <Alert
              showIcon
              type="success"
              message={
                <>
                  Your previous subscription plan has been <b>retired</b> in 2021 and we are no longer able to offer it.
                </>
              }
              description={
                <>
                  For this reason, our system on <b>{new Date(user?.upgradedAt).toDateString()}</b> found and upgraded
                  your plan to <b>{user?.plan?.name}</b> which is closest match to your current usage.
                </>
              }
              action={
                <a href="https://docs.prerender.io/docs/billing-2">
                  <Button type="primary">Learn More</Button>
                </a>
              }
            />
          </div>
        )}
        <Switch>
          <Route path={`/billing/${PAGES.overview}`} exact>
            <BillingOverview />
          </Route>
          <Route path={`/billing/${PAGES.plans}`} exact>
            <BillingPlans />
          </Route>
          <Route path={`/billing/${PAGES.packages}`} exact>
            <BillingPackages />
          </Route>
          <Route path={`/billing/${PAGES.history}`} exact>
            <BillingHistory />
          </Route>
          <Route path={`/billing/${PAGES.payment}`} exact>
            <BillingPayment />
          </Route>

          <Route path={`/billing/${PAGES.coupons}`} exact>
            <CouponsOverview userEmail={user.email} />
          </Route>
          {!isEnterpriseUser && (
            <Route path={`/billing/${PAGES.referral}`} exact>
              <ReferralOverview />
            </Route>
          )}
          <Route path="*">
            <Redirect to="/billing/overview" />
          </Route>
        </Switch>
      </Content>
    </AdminTemplate>
  );
};

export default requireAuth(BillingPage);
