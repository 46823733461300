import { getAccessToken } from '../../auth';
import config from '../../../assets/config';

export const useUserflowSignature = () => {
  const baseUrl = `${config.apiUrl.restServer}/v3`;

  return {
    sign: (...ids) => {
      const accessToken = getAccessToken();

      return fetch(`${baseUrl}/userflow/sign`, {
        method: 'POST',
        body: JSON.stringify({ ids: ids.map((id) => `${id}`) }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .catch((err) => {
          console.error(`Failed to get signature for userflow on id ${id}`);
          console.error(err);
        });
    },
  };
};
