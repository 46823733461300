import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../Routes';
import { getAccessToken } from '../auth';
import { useEvent } from './hooks/useEvent';

export function ViewEventListener() {
  const accessToken = getAccessToken();

  const allRoutes = useMemo(() => {
    return routes.reduce((acc, route) => {
      if (route.routes) {
        return [...acc, ...route.routes.filter((r) => !!r.event).map((r) => ({ event: r.event, path: r.path }))];
      }
      return acc;
    }, []);
  });

  const location = useLocation();
  const { track } = useEvent();

  useEffect(() => {
    const route = allRoutes.find((route) => route.path === location.pathname);
    if (route && accessToken) {
      track(route.event);
    }
  }, [accessToken, location.pathname]);

  return '';
}
