import { Modal, Select, Form } from 'antd';

import { userRoleOptions } from './appUserRoles';

function UpdateRoleModal({ open, onClose, onSubmit, isLoading = false, userData = {} }) {
  const onFinish = (values) => {
    onSubmit({ loginUserId: userData.loginUserId, role: values.role });
  };

  return (
    <Modal
      title="User settings"
      open={open}
      onCancel={onClose}
      okText="Update"
      okButtonProps={{ htmlType: 'submit', form: 'userSettings', loading: isLoading }}
    >
      <Form layout="vertical" name="userSettings" onFinish={onFinish} initialValues={{ role: userData?.role }}>
        <Form.Item label="User role" name="role">
          <Select id="role" options={userRoleOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateRoleModal;
