import { usePrerenderUserFeatures } from './usePrerenderUser';

/**
 *
 * @param {string[]} requiredFeatureFlags
 * @returns boolean
 */
function useFeatureFlag(requiredFeatureFlags) {
  const features = usePrerenderUserFeatures();

  for (const requiredFeatureFlag of requiredFeatureFlags) {
    if (!features.includes(requiredFeatureFlag)) {
      return false;
    }
  }

  return true;
}

export const DOMAINS_DETAILS_FEATURE = 'domains-details';
export const SEO_REPORT_FEATURE = 'seo-report';
export const DOMAIN_SCHEDULER_SETTINGS = 'domain-scheduler-settings';
export const DISABLE_CLEAR_CACHE_FEATURE = 'disable-clear-cache';

export default useFeatureFlag;
