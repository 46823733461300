import { Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import React from 'react';

export const ViewCachedHtmlButton = ({ disabled, item, tooltip }) => (
  <a
    type="button"
    className={disabled ? 'btn btn-sm btn-white ml-2 disabled' : 'btn btn-sm btn-white ml-2'}
    href={`/raw-html?id=${item.id}`}
    target="_blank"
    rel="noreferrer"
    data-tip={tooltip}
  >
    <i className="fe fe-eye"></i>
  </a>
);

export const DeletePageButton = ({ disabled, tooltip, onClick }) => (
  <Popconfirm
    title="Are you sure you want to delete this URL?"
    placement="left"
    onConfirm={onClick}
    okText="Yes"
    cancelText="Cancel"
  >
    <Tooltip placement="top" title="Remove the URL from the cache">
      <Button className="ml-2" danger disabled={disabled} data-tip={tooltip} icon={<DeleteOutlined />}></Button>
    </Tooltip>
  </Popconfirm>
);

// This is for the ignored URLs
export const SaveButton = ({ disabled, tooltip, onClick }) => (
  <Button
    className="ml-2"
    type="primary"
    ghost
    danger={false}
    disabled={disabled}
    onClick={onClick}
    data-tip={tooltip}
    icon={<CheckOutlined />}
  ></Button>
);
