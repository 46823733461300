import PrerenderGoogleLogin from './components/PrerenderGoogleLogin';
import UserContextProvider from './components/UserContext';
import SearchConsoleActions from './components/SearchConsoleActions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Container } from '../../components/CssFrameworkComponents';
import { Content } from 'antd/lib/layout/layout';
import { usePageContent } from '../../hooks/usePageContent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

import ExpFeatureNotice from '../../components/ExpFeatureNotice';
import { Flex, Typography } from 'antd';

const { Title, Text } = Typography;

const getGoogleClientID = (node_env) => {
  return node_env === 'development'
    ? '159418071530-8jce97vsdt2kpa9havt33booq7ch7r6p.apps.googleusercontent.com'
    : '573209089028-ajljj8r60pkla1ja0kpcnmuln10vrhth.apps.googleusercontent.com';
};

function GoogleSearchConsolePage() {
  const { cm } = usePageContent('Onboarding');

  if (!cm) {
    return <h1>Loading...</h1>;
  }

  return (
    <AdminTemplate>
      <Content>
        <Container fluid>
          <GoogleOAuthProvider clientId={getGoogleClientID(process.env.NODE_ENV)}>
            <ExpFeatureNotice />
            <Flex vertical style={{ paddingTop: 16, paddingBottom: 32 }}>
              <Flex justify="space-between">
                <Title level={2} style={{ marginBottom: 8 }}>
                  Google Search Console
                </Title>
                <PrerenderGoogleLogin key="btn" />
              </Flex>
              <Text type="secondary">Get your sitemaps into Prerender</Text>
            </Flex>

            <SearchConsoleActions />
          </GoogleOAuthProvider>
        </Container>
      </Content>
    </AdminTemplate>
  );
}

export default requireAuth(GoogleSearchConsolePage);
