export interface UserPlans {
  readonly currentPlan: ChargeBeePlan;
  readonly addonCostInCents: number;
  readonly suggestedPlan: ChargeBeePlan;
  readonly plans: Array<ChargeBeePlan>;
}

export enum PlanTier {
  Legacy = 'legacy',
  Custom = 'custom',
  Free = 'free-001',
  P2020 = 'p2020-001',
  P2022 = 'p2022-001',
  P2024 = 'p2024-001',
}

export enum ChargeBeePlanId {
  Basic = 'basic-001',
  Professional = 'pro-001',
  Startup = 'startup-001',
  Business = 'business-001',
  Enterprise = 'enterprise-001',
  Startup2022 = 'startup-2022',
  Scaleup2022 = 'scaleup-2022',
  Enterprise2022 = 'enterprise-2022',
  CustomEnterprise = 'custom-enterprise-001',
  CustomEnterpriseYearly = 'custom-enterprise-yearly-001',
  Legacy = 'prerender-base-plan',
  LegacyYearly = 'prerender-yearly-base-plan',
  Adhoc = 'adhoc',
  Lite2024 = 'plan-2024-050',
  Essential2024 = 'plan-2024-100',
  Advanced2024 = 'plan-2024-200',
  Custom2024 = 'plan-2024-300',
}

export enum PlanName {
  Basic = 'Free',
  Professional = 'Professional',
  Startup = 'Startup',
  Business = 'Business',
  Scaleup = 'Scaleup',
  Enterprise = 'Enterprise',
  CustomEnterprise = 'Custom Enterprise',
  CustomEnterpriseYearly = 'Custom Enterprise Yearly',
  Legacy = 'Prerender Base',
  LegacyYearly = 'Prerender Yearly Base',
  Adhoc = 'Ad Hoc',
  Lite = 'Lite',
  Essential = 'Essential',
  Advanced = 'Advanced',
  Custom = 'Custom',
}

export enum CacheLocation {
  shared = 'shared',
  both = 'both',
}

export enum Support {
  none = 'none',
  low = 'low',
  normal = 'normal',
  high = 'high',
}

export enum BillingPeriod {
  monthly = 'monthly',
  yearly = 'yearly',
}

export interface ChargeBeePlan {
  readonly name: PlanName;
  readonly chargeBeePlanId: ChargeBeePlanId;
  readonly index: number;
  readonly legacy: boolean;
  readonly costInCents: number;
  readonly addonCostInCents: number;
  readonly currencyCode: string;
  readonly includedRenderedPages: number;
  readonly chargeBeeAddonId?: ChargeBeeAddonId;
  readonly suggested: boolean;
  readonly current: boolean;
  readonly cacheRefreshInterval: Array<number>;
  readonly cacheLocation: CacheLocation;
  readonly available: boolean;
  readonly support: Support;
  readonly billingPeriod: BillingPeriod;
  readonly customPlan: boolean;
  readonly contactUsToUse: boolean;
  readonly tier: PlanTier;
  readonly apiAccess: boolean;
}

export enum ChargeBeeAddonId {
  customPlan = 'prerender-custom-plan',
  legacyCacheFreshnessPostfix = '-day-cache-freshness-legacy',
  legacyYearlyCacheFreshnessPostfix = '-day-cache-freshness-yearly-legacy',
  basePageCachingTrial = 'base-page-caching-trial-period',
  professionalOverages = 'pro-plan-extra-cache-budget-001',
  startupOverages = 'startup-plan-extra-cache-budget-001',
  businessOverages = 'business-plan-extra-cache-budget-001',
  enterpriseOverages = 'enterprise-plan-extra-cache-budget-001',
  startup2022Overages = 'startup-plan-extra-cache-budget-2022',
  scaleup2022Overages = 'scaleup-plan-extra-cache-budget-2022',
  enterprise2022Overages = 'enterprise-plan-extra-cache-budget-2022',
  lite2024Overages = 'extra-2024-050',
  essential2024Overages = 'extra-2024-100',
  advanced2024Overages = 'extra-2024-200',
  custom2024Overages = 'extra-2024-300',
}

export interface PlanWithCost {
  readonly name: string;
  readonly totalInCents: number;
  readonly costPerMonth: number;
  readonly currencyCode: string;
  readonly planPriceInCents: number;
  readonly addonsPriceInCents: number;
  readonly suggestedPlanName?: string;
  readonly limit: number;
  readonly cacheRefreshInterval: Array<number> | null;
}

export interface PlanCost {
  readonly free: number;
  readonly startup: number;
  readonly business: number;
  readonly growth: number;
  readonly premium: number;
  readonly enterprise: number;
  readonly upToOneMillion: number;
  readonly upToTwoMillion: number;
  readonly upToThreeMillion: number;
  readonly upToFourMillion: number;
  readonly upToFiveMillion: number;
}
