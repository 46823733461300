import React from 'react';
import { Alert, Select, Divider, Button, Input, Form, theme } from 'antd';

import { userRoleOptions } from './appUserRoles';

function InvitationForm({ onSubmit, invitation, selectRoles = false, isLoading = false }) {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  const onFinish = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  return (
    <>
      <div style={{ marginBottom: token.marginMD }}>
        {/* Non keycloak case where we now the invitation link */}
        {invitation.email && <Alert type="success" message="Invitation Sent" />}
        {invitation.error && <Alert type="error" message={invitation.error} />}
      </div>

      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ role: userRoleOptions[0].value }}>
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input a valid email address',
            },
          ]}
        >
          <Input name="email" />
        </Form.Item>
        {selectRoles && (
          <Form.Item label="User role" name="role">
            <Select placeholder="Role" id="role" options={userRoleOptions} />
          </Form.Item>
        )}
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default InvitationForm;
