import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SuggestedIntegrationStep from './SuggestedIntegrationStep';
import IntegrationError from './IntegrationError';

function SuggestedIntegrationRouter(props) {
  return (
    <Switch>
      <Route path="/integration-wizard/suggested-integration/failed" exact component={IntegrationError} />
      <Route
        path="/integration-wizard/suggested-integration"
        exact
        component={(routerProps) => <SuggestedIntegrationStep {...routerProps} {...props} />}
      />
    </Switch>
  );
}

export default SuggestedIntegrationRouter;
