import { Steps, Layout, Flex } from 'antd';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import ImportSitemapsStep from './components/steps/ImportSitemapsStep';
import SuggestedIntegrationRouter from './components/steps/SuggestedIntegrationStep';
import ScanDomainStep from './components/steps/ScanDomainStep/ScanDomainStep';
import VerificationSuccessfulStep from './components/steps/VerificationSuccessfulStep';
import { useIntegrationWizardSteps } from './useIntegrationWizardSteps';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const { Content } = Layout;
const stepsConfig = [
  { path: 'domain', title: 'Scan Domain', component: ScanDomainStep, disabled: true },
  /*{ path: 'sitemap', title: 'Import Sitemaps', component: ImportSitemapsStep, disabled: true },*/
  { path: 'suggested-integration', title: 'Integrate', component: SuggestedIntegrationRouter, disabled: true },
  { path: 'verification', title: 'Start Rendering', component: VerificationSuccessfulStep, disabled: true },
];

const IntegrationWizardPage = () => {
  const { Step, onGoBack, onGoNext, goToStart, currentStep, prevStep, setCurrentStep } =
    useIntegrationWizardSteps(stepsConfig);

  return (
    <AdminTemplate>
      <Content style={{ display: 'flex', flexDirection: 'column' }}>
        <Flex vertical gap="middle" style={{ flex: 1 }}>
          <Steps current={currentStep} items={stepsConfig} onChange={setCurrentStep} />
          <Step
            onGoBack={onGoBack}
            onGoNext={onGoNext}
            goToStart={goToStart}
            currentStep={currentStep}
            prevStep={prevStep}
          />
        </Flex>
        <Switch>
          <Route path="/integration-wizard">
            <Redirect to="/integration-wizard/domain" exact />
          </Route>
        </Switch>
      </Content>
    </AdminTemplate>
  );
};

export default {
  component: requireAuth(IntegrationWizardPage),
};
