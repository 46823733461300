import { Layout, Steps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './RegistrationPage.css';
import { useEffect, useState } from 'react';
import { RoleAndIndustryForm } from './components/RoleAndIndustryForm';
import { GoalsForm } from './components/GoalsForm';
import { ReferralSourceForm } from './components/ReferralSourceForm';
import { DomainForm } from './components/DomainForm';
import { LineOutlined } from '@ant-design/icons';
import requireAuth from '../../components/hocs/requireAuth';
import { useHistory } from 'react-router-dom';
import { useGetMarketingDataQuery, useSetMarketingDataMutation } from '../api/marketingApiSlice';
import SpinningWheel from '../../components/SpinningWheel';

const INITIAL_STATE = {
  industry: null,
  role: null,
  goals: [],
  referralSources: [],
  domain: null,
};

const REDIRECT_STEP = 5;

const forms = [RoleAndIndustryForm, GoalsForm, ReferralSourceForm, DomainForm];

const RegistrationPage = () => {
  const [setMarketingData, { data: updatedData, isLoading: isUpdating }] = useSetMarketingDataMutation();
  const { data, error, isLoading } = useGetMarketingDataQuery();
  let history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [registrationData, setRegistrationData] = useState(INITIAL_STATE);

  useEffect(() => {
    if (data) {
      setRegistrationData(data);
      const stepConditions = {
        1: [data.industry, data.role],
        2: [data.goals?.length],
        3: [data.referralSources?.length],
        4: [data.domain],
      };

      const [step] = Object.entries(stepConditions).find(([step, conditions]) => {
        return !conditions.every(Boolean);
      }) || [REDIRECT_STEP];

      if (step !== REDIRECT_STEP) {
        setCurrentStep(Number(step));
      } else {
        history.push('/');
      }
    }
  }, [data]);

  useEffect(() => {
    if (currentStep === REDIRECT_STEP && updatedData) {
      history.push('/');
    }
  }, [currentStep, updatedData]);

  const onStepComplete = (updatedFields) => {
    let nextState = { ...registrationData, ...updatedFields };
    setRegistrationData(nextState);
    setMarketingData(updatedFields);
    setCurrentStep(currentStep + 1);
  };

  const onGoBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const Step = forms[currentStep - 1];

  if (isLoading || currentStep === 5) return <SpinningWheel />;

  return (
    <>
      <Layout className="registrationPage">
        <Content className="registrationPage__content">
          <img src="/assets/img/v2/Prerender-Logo.svg" alt="logo" className="registrationPage__img"></img>
          <Step onFinish={onStepComplete} onGoBack={onGoBack} values={registrationData} />
        </Content>
        <div className="registrationPage__steps">
          <Steps
            onChange={setCurrentStep}
            current={currentStep}
            responsive={false}
            items={[
              { disabled: true, status: 'finish', icon: <LineOutlined /> },
              { disabled: true, icon: <LineOutlined /> },
              { disabled: true, icon: <LineOutlined /> },
              { disabled: true, icon: <LineOutlined /> },
              { disabled: true, icon: <LineOutlined /> },
            ]}
          ></Steps>
        </div>
      </Layout>
    </>
  );
};

export default {
  component: requireAuth(RegistrationPage),
};
