import { useEffect, useState, useMemo } from 'react';

import { formatDays } from '../../assets/dateFormatter';
import RecacheIntervalSetting from './recacheIntervalSetting';

import './recacheIntervalCard.css';
import './recacheIntervalSetting.css';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const RecacheIntervalCard = ({ cm, onEvent }) => {
  const prerenderUser = usePrerenderUser();

  const didReceiveAction = ({ action, payload }) => {
    if (onEvent) {
      onEvent({ action, payload });
    }
  };

  return prerenderUser.isCacheFreshnessDisabled ? (
    <div className="row">
      <div className="col-12 ml-2 mb-3">
        {cm.card.cacheFreshness} {formatDays(prerenderUser.cacheFreshness)}
      </div>
    </div>
  ) : (
    <>
      <div className="card" style={{ width: '370px' }}>
        <div className="card-body py-3">
          <RecacheIntervalSetting
            onSubmit={({ days }) =>
              didReceiveAction({ action: 'updateCacheFreshnessSlider', payload: { interval: days } })
            }
            onCancel={() => {
              didReceiveAction({ action: 'closeCacheFreshnessCard' });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RecacheIntervalCard;
