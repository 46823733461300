import App from './App';
import BillingPage from './features/billing/BillingPage';
import DashboardPage from './features/dashboard/DashboardPage';
import Domain404CheckerPage from './features/domain-404-checker/Domain404CheckerPage';
import GoogleSearchConsolePage from './features/gsc-integrations/GoogleSearchConsolePage';
import IntegrationDetailsPage from './features/integration/IntegrationDetailsPage';
import IntegrationPage from './features/integration/IntegrationPage';
import IntegrationVerificationPage from './features/integration/IntegrationVerificationPage';
import IntegrationWizardPage from './features/integrationWizard/IntegrationWizardPage';
import RegistrationPage from './features/registration/RegistrationPage';
import SitemapRouter from './features/sitemaps/SitemapRouter';
import CachedPagesPage from './pages/CachedPagesPage';
import CachingActivityPage from './pages/CachingActivityPage';
import CrawlerActivityPage from './pages/CrawlerActivityPage';
import CustomDealTrackerPage from './pages/CustomDealTrackerPage';
import DashboardPageOld from './pages/DashboardPage';
import { DevTestPage } from './pages/DevTestPage';
import Error404Page from './pages/Error404Page';
import FeatureRequestPage from './pages/FeatureRequestPage';
import GettingStartedPage from './pages/GettingStartedPage';
import InsightPage from './pages/InsightPage';
import QualityAssurancePage from './pages/QualityAssurancePage';
import RawHtmlPage from './pages/RawHtmlPage';
import RecacheQueuePage from './pages/RecacheQueuePage';
import RedirectPage from './pages/RedirectPage';
import SettingsPage from './pages/SettingsPage';
import SignOutPage from './pages/SignOutPage';
import UncaughtError from './pages/UncaughtError';
import DemoSitemapPage from './pages/demo/DemoSitemapPage';
import NewlyAddedDomainsPage from './features/added-domains/NewlyAddedDomainsPage';
import DomainsCountPage from './features/domains-count/DomainsCountPage';
import DomainManagerPage from './pages/DomainsManager';
import SecurityAndAccess from './pages/SecurityAndAccess';
import SeoReportPage from './features/SeoReport/SeoReportPage';
import OversightPage from './features/oversightSystem/OversightPage';
import Keywords from './pages/RankTracker';

const routes = [
  {
    component: App,
    routes: [
      { ...RedirectPage, path: '/login', exact: true },
      { ...RedirectPage, path: '/reset-password', exact: true },
      { ...RedirectPage, path: '/signup', exact: true },
      { ...RedirectPage, path: '/verify-email', exact: true },
      { ...RedirectPage, path: '/accept-invitation', exact: true },
      { component: DashboardPage, path: '/', exact: true, event: 'Dashboard Page Viewed' },
      { ...DashboardPageOld, path: '/dashboard', exact: true, event: 'Dashboard Page Viewed' },
      { ...CachedPagesPage, path: '/cache', exact: true, event: 'Cache Manager Viewed' },
      { ...CachingActivityPage, path: '/recache-stats', exact: true, event: 'Render History Viewed' },
      { ...RecacheQueuePage, path: '/recache-queue', exact: true, event: 'Render Queue Viewed' },
      { ...CrawlerActivityPage, path: '/crawl-stats', exact: true, event: 'CDN Analytics Viewed' },
      { ...SettingsPage, path: '/settings', exact: true, event: 'Settings Viewed' },
      { ...SecurityAndAccess, path: '/security', exact: false, event: 'Security and Access Viewed' },
      { ...IntegrationPage, path: '/integration', exact: true },
      { ...IntegrationDetailsPage, path: '/integration/:name', exact: true },
      { ...IntegrationVerificationPage, path: '/integration/:name/verification', exact: true },
      { ...GettingStartedPage, path: '/getting-started', exact: true },
      { ...SignOutPage, path: '/signout', exact: true }, // this is used by keycloak too
      { ...FeatureRequestPage, path: '/feature-request', exact: true },
      { ...RawHtmlPage, path: '/raw-html', exact: true },
      { component: BillingPage, path: '/billing', exact: false, event: 'Billing Page Viewed' },
      { ...Error404Page, path: '/error404' },
      { ...UncaughtError, path: '/error-uncaught' },
      { ...RegistrationPage, path: '/registration', exact: true },
      { component: GoogleSearchConsolePage, path: '/gsc', exact: true, event: 'Google Search Colsole Viewed' },
      {
        ...IntegrationWizardPage,
        path: '/integration-wizard',
        exact: false,
        event: 'Integration Started',
      },
      {
        ...DomainManagerPage,
        path: '/domain-manager',
        event: 'Domain Manager Viewed',
      },
      {
        ...SeoReportPage,
        path: '/seo-report/:id',
        event: 'SEO Report Viewed',
      },
      {
        component: SitemapRouter,
        path: '/sitemaps',
        event: 'Sitemap Viewed',
      },
      {
        component: Domain404CheckerPage,
        path: '/domain-404-checker',
        event: '404 Checker Page Viewed',
      },
      {
        component: DevTestPage,
        path: '/dev',
      },
      {
        ...InsightPage,
        path: '/insight',
        event: 'Deep Insight Viewed',
      },
      {
        component: OversightPage,
        path: '/oversight',
        event: 'Oversight Page Viewed',
        exact: false,
      },
      {
        ...CustomDealTrackerPage,
        path: '/custom-deal',
      },
      {
        ...NewlyAddedDomainsPage,
        path: '/added-domains',
      },
      {
        ...QualityAssurancePage,
        path: '/qa',
      },
      {
        ...DomainsCountPage,
        path: '/page-count',
      },
      {
        ...DemoSitemapPage,
        path: '/demo/sitemap',
      },
      {
        component: Keywords,
        path: '/rank-tracker',
        event: 'Rank Tracker Viewed',
      },
      { ...Error404Page, path: '*' },
    ],
  },
];

export default routes;
