import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

const RedirectPage = (props) => {
  const { location } = props;

  if (location.pathname === '/signup') {
    const urlParams = new URLSearchParams(location.search);
    const plan = urlParams.get('plan');
    const username = urlParams.get('username');


    if (plan) {
      upsertJourneyCookie();
      logJourney(window.location, 'signup.visit');
    }

    if (username) {
      sessionStorage.setItem("username", username);
    }
  }

  return <Redirect to="/" />;
};

export default {
  component: withRouter(RedirectPage),
};
