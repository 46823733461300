import React from 'react';
import { Flex, Typography, Layout, Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { useEvent } from '../events/hooks/useEvent';

import { usePageContent } from '../../hooks/usePageContent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useIsMobileView } from '../../hooks/useIsMobileView';

const { Title, Text } = Typography;
const { Content } = Layout;

import { DashboardUsageOverview } from './components/DashboardUsageOverview';
import { DeliveredToCrawlerCharts } from './components/DeliveredToCrawlerCharts';
import { DeliveredByStatusCharts } from './components/DeliveredByStatusCharts';
import { CrawlerVisitsCharts } from './components/CrawlerVisitsCharts';
import { LatestVisitsAndPagesInfo } from './components/LatestVisitsAndPagesInfo';

import Onboarding from '../onboarding';

const items = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
];

function DashboardPage() {
  const { cm } = usePageContent('DashboardPage', { groups: ['cachedPages', 'default'] });
  const prerenderUser = usePrerenderUser();

  const trackingCodeInstalled = prerenderUser.trackingCodeInstalled;

  const { track } = useEvent();

  const [days, setTimeFilterValueInDays] = React.useState(7);

  const isMobile = useIsMobileView();

  // We need this check for legacy reasons, otherwise site breaks
  if (!cm) {
    return null;
  }

  return (
    <AdminTemplate>
      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <Flex vertical={true} style={{ marginBottom: '16px' }}>
            <Flex justify={'space-between'} align={'center'}>
              <Title level={2} style={{ marginBottom: 8 }}>
                Dashboard
              </Title>
              <Dropdown
                disabled={!trackingCodeInstalled}
                menu={{
                  defaultSelectedKeys: [`${days}`],
                  selectable: true,
                  items,
                  onSelect: ({ key }) => {
                    track('Dashboard Timeframe Selected', {
                      time_selected: 1 ? `${key} day` : `${key} days`,
                      subscription_plan: prerenderUser.planName,
                    });
                    setTimeFilterValueInDays(Number(key));
                  },
                }}
                placement="bottom"
                arrow
              >
                <Button disabled={!trackingCodeInstalled}>
                  <Space>
                    {days !== 1 ? `Last ${days} days` : `Last ${days} day`}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Flex>
            <Text type="secondary">Track, manage and forecast your Prerender activity and usage.</Text>
          </Flex>

          {/* not removed for now to switch back to the old onboarding */}
          <Flex justify={'space-between'}>
            {!trackingCodeInstalled && (
              <Flex style={{ width: '100%' }}>
                <Onboarding.Card />
              </Flex>
            )}
          </Flex>
          <Flex justify={'space-between'} vertical={isMobile} gap={isMobile ? 10 : 0}>
            <DashboardUsageOverview days={days} />
            <DeliveredToCrawlerCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <Flex justify={'space-between'}>
            <DeliveredByStatusCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <Flex justify={'space-between'}>
            <CrawlerVisitsCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <LatestVisitsAndPagesInfo />
        </Flex>
      </Content>
    </AdminTemplate>
  );
}

export default requireAuth(DashboardPage);
