import React from 'react';
import RockedIcon from '../../../assets/icons/rocket-icon.svg';
import CurrentPlanIcon from '../../../assets/icons/current-plan.svg';
import RecommendedPlanIcon from '../../../assets/icons/recommended-plan.svg';
import PlanUpgradeWhiteIcon from '../../../assets/icons/plan-upgrade-white-icon.svg';
import PlanUpgradeGreyIcon from '../../../assets/icons/plan-upgrade-grey-icon.svg';
import FeatureIsInPlanIcon from '../../../assets/icons/feature-is-in-plan-icon.svg';
import FeatureNotInPlanIcon from '../../../assets/icons/feature-not-in-plan-icon.svg';
import { createUsNumberFormat } from '../../../utils/createUsNumberFormat';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';
import { formatCentsToDollarLocale } from '../../../assets/currencies';
import { useSelector } from 'react-redux';
import { useEvent } from '../../events/hooks/useEvent';
import { Button, Badge } from 'antd';
import { ChargeBeePlanId } from '@prerender/billing-shared';
import { createHubSpotFormUrl } from '../../../utils/createHubSpotFormUrl';

const BillingPlansCard = ({ plan, currentPlanIndex, onPlanClicked }) => {
  const user = useSelector((state) => state.prerenderUser);
  const userPlan = user.planName;
  const { track } = useEvent();

  const onShowMoreLinkClick = React.useCallback(() => {
    track('Plans Pricing Page Viewed', { subscription_plan: userPlan, selected_plan: plan });
  }, [userPlan, plan]);

  const hubSpotFormUrl = createHubSpotFormUrl(user.firstName, user.email);

  return (
    <div style={plan.current ? { border: '2px solid #2da01d', backgroundColor: '#FAFFFC' } : {}} className="card">
      <Badge.Ribbon
        text="Exclusive Offer for You!"
        color="purple"
        style={plan.id === ChargeBeePlanId.Lite2024 ? { display: 'block' } : { display: 'none' }}
      >
        <div className="card-body">
          {/* Header */}
          <div className="d-flex align-items-center">
            <img src={RockedIcon} />
            <p style={{ fontSize: '20px' }} className="ml-2 pt-2 card-title font-weight-bold ">
              {plan.name} Plan
            </p>
            {plan.current && (
              <div className="ml-2">
                <img src={CurrentPlanIcon} />
              </div>
            )}
            {!plan.current && plan.suggested && (
              <div className="ml-2">
                <img src={RecommendedPlanIcon} />
              </div>
            )}
          </div>
          {/* Price */}
          <div className="d-flex align-items-end">
            {plan.costInCents === 0 ? (
              <span style={{ fontSize: '60px' }} className="font-weight-bold d-flex align-items-end">
                Free
              </span>
            ) : plan.contactUsToUse ? (
              <span style={{ fontSize: '60px' }} className="font-weight-bold d-flex align-items-end">
                Custom
              </span>
            ) : (
              <>
                <span style={{ fontSize: '60px' }} className="font-weight-bold d-flex align-items-end">
                  {formatCentsToDollarLocale(plan.costInCents)}
                </span>
                <span className="ml-1 mb-4">per month</span>
              </>
            )}
          </div>
          {/* Features */}
          {plan.contactUsToUse ? (
            <div style={{ minHeight: 140 }}>
              {/* Enterprise unique Features */}
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Dedicated success manager
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Personalized onboarding
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Support & service SLAs
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Multiple user accounts
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Security assessments
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  SSO
                </p>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: 140 }}>
              <div className="d-flex align-items-center" style={{ height: '100%' }}>
                {plan.includedCaches !== 0 ? <img src={FeatureIsInPlanIcon} /> : <img src={FeatureNotInPlanIcon} />}
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  {`No. of Renders: ${createUsNumberFormat(plan.includedCaches)}`}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                  Cache Freshness Interval: {formatCacheRefreshInterval(plan.cacheRefreshInterval)}
                </p>
              </div>
              {plan.addonCostInCents ? (
                <div className="d-flex align-items-center">
                  <img src={FeatureIsInPlanIcon} />
                  <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                    Cost per 1000 Extra Renders: {formatCentsToDollarLocale(plan.addonCostInCents)}
                  </p>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex align-items-center">
                <img src={FeatureIsInPlanIcon} />
                {plan.support !== 'none' ? (
                  <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                    Customer Support
                  </p>
                ) : (
                  <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                    Knowledge Base Support
                  </p>
                )}
              </div>
              <div className="d-flex align-items-center">
                {plan.apiAccess ? (
                  <>
                    <img src={FeatureIsInPlanIcon} />
                    <p style={{ fontSize: '14px' }} className="ml-2 mb-0">
                      API control
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          )}
          {plan.tier === 'free-001' || plan.tier === 'p2024-001' ? (
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="link"
                onClick={onShowMoreLinkClick}
                style={{ fontSize: '14px', fontWeight: '500' }}
                href="https://prerender.io/pricing/"
                target="_blank"
                rel="noreferrer"
              >
                Show more
              </Button>
            </div>
          ) : (
            <div style={{ height: 30 }}></div>
          )}
          <hr />
          {/* Upgrade / Downgrade Button */}
          <div>
            {!plan.current && !plan.contactUsToUse && (
              <Button
                size="large"
                type={plan.index - 1 === currentPlanIndex ? 'primary' : 'default'}
                className="container-fluid d-flex align-items-center justify-content-center font-weight-bold"
                onClick={() => onPlanClicked(plan)}
              >
                <img src={plan.index - 1 === currentPlanIndex ? PlanUpgradeWhiteIcon : PlanUpgradeGreyIcon} />
                <span className="ml-3">
                  {currentPlanIndex < plan.index ? `Upgrade to ${plan.name}` : `Downgrade to ${plan.name}`}
                </span>
              </Button>
            )}
            {/* Contact Us Button */}
            {!plan.current && plan.contactUsToUse && (
              <a href={hubSpotFormUrl} target="_blank">
                <Button
                  size="large"
                  type={plan.index - 1 === currentPlanIndex ? 'primary' : 'default'}
                  className="container-fluid d-flex align-items-center justify-content-center font-weight-bold"
                  onClick={() => {
                    track('Custom plan Contact us button clicked', { subscription_plan: userPlan });
                  }}
                >
                  <img src={plan.index - 1 === currentPlanIndex ? PlanUpgradeWhiteIcon : PlanUpgradeGreyIcon} />
                  <span className="ml-3">Contact Us</span>
                </Button>
              </a>
            )}
          </div>
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export default BillingPlansCard;
