import React from 'react';
import { Button, Form, Slider, Switch } from 'antd';
import { expirationIndexToReadable } from './utils/expirationIndexToReadable';

const DEFAULT_CACHE_EXPIRATION_INDEX = 23 + 14; // 23 hours + 14 days
const DEFAULT_SLOW_RENDER_TRIGGER = 10; // 10 seconds

function expirationHoursToIndex(hours) {
  if (hours < 0) return null;
  if (hours > 24) return hours / 24 + 23;
  return hours;
}

function SchedulerSettings({
  isLoading,
  disabled,
  onSave,
  slowRenderTrigger,
  cacheExpiration,
  automaticRecache = true,
}) {
  const [slowRenderTriggerLabel, setSlowRenderTriggerLabel] = React.useState(`${DEFAULT_SLOW_RENDER_TRIGGER} seconds`);
  const [cacheExpirationLabel, setCacheExpirationLabel] = React.useState(
    expirationIndexToReadable(DEFAULT_CACHE_EXPIRATION_INDEX)
  );
  const [automaticCacheEnabled, setAutomaticCacheEnabled] = React.useState(cacheExpiration > 0);
  const [form] = Form.useForm();

  const onSlowRenderTriggerChange = (value) => setSlowRenderTriggerLabel(`${value} seconds`);
  const onCacheExpirationChange = (value) => setCacheExpirationLabel(expirationIndexToReadable(value));

  const onAutomaticRecacheChange = (enabled) => {
    const cacheExpirationIndex = enabled ? DEFAULT_CACHE_EXPIRATION_INDEX : null;
    form.setFieldsValue({ cacheExpiration: cacheExpirationIndex });
    setCacheExpirationLabel(expirationIndexToReadable(cacheExpirationIndex));
    setAutomaticCacheEnabled(enabled);
  };

  const onSubmit = (values) => {
    let cacheExpirationValue = values.cacheExpiration;
    if (!values.cacheExpiration) {
      cacheExpirationValue = -1;
    } else if (values.cacheExpiration > 24) {
      // BE expects cacheExpiration in hours
      cacheExpirationValue = (values.cacheExpiration - 23) * 24;
    }

    onSave({
      slowRenderTrigger: values.slowRenderTrigger,
      cacheExpiration: cacheExpirationValue,
    });
  };

  React.useEffect(() => {
    const cacheExpirationIndex = expirationHoursToIndex(cacheExpiration);
    form.setFieldsValue({
      slowRenderTrigger,
      cacheExpiration: cacheExpirationIndex,
      automaticRecache: cacheExpiration > 0,
    });

    onSlowRenderTriggerChange(slowRenderTrigger);
    onCacheExpirationChange(cacheExpirationIndex);
  }, [slowRenderTrigger, cacheExpiration, automaticRecache]);

  return (
    <Form layout="vertical" onFinish={onSubmit} form={form} disabled={disabled}>
      <Form.Item name="slowRenderTrigger" label={`Slow Render Trigger (${slowRenderTriggerLabel})`}>
        <Slider min={10} max={70} marks={{ 10: '10s', 70: '70s' }} onChangeComplete={onSlowRenderTriggerChange} />
      </Form.Item>
      <Form.Item name="cacheExpiration" label={`Cache Expiration (${cacheExpirationLabel})`}>
        <Slider
          min={1}
          max={23 + 30} // 23 hours + 30 days
          marks={{ 1: '1h', 53: '30d' }}
          tooltip={{ formatter: expirationIndexToReadable }}
          onChangeComplete={onCacheExpirationChange}
          disabled={!automaticCacheEnabled}
        />
      </Form.Item>
      <Form.Item name="automaticRecache" label="Automatic Recache">
        <Switch onChange={onAutomaticRecacheChange} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SchedulerSettings;
