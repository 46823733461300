import { useEffect, useState } from 'react';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import userflow from 'userflow.js';
import { useUserflowSignature } from './hooks/useUserflowSignature';
import config from '../../assets/config';

export function UserFlow() {
  const user = usePrerenderUser();
  const { sign } = useUserflowSignature();
  const [userIdentified, setUserIdentified] = useState(false);

  useEffect(() => {
    if (user && user.id && userIdentified === false) {
      setUserIdentified(true);

      (async () => {
        const signatures = await sign(user.id, user.companyId);

        userflow.init(config.userflowToken);
        userflow.identify(
          user.id,
          {
            // We don't have a user name
            // name: (user.firstName || user.lastName) ?? `${user.firstName} ${user.lastName}`.trim(),
            email: user.adminEmail,
            signed_up_at: user.signupDate.toISOString(),
          },
          {
            signature: signatures[user.id],
          }
        );
        userflow.group(
          user.companyId,
          {
            // We don't have a company name yet
            //   name: company.name,
          },
          {
            signature: signatures[user.companyId],
          }
        );
      })();
    }
  }, [user, userIdentified]);

  return '';
}
