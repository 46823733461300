import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Table, Card, Input, Button, Popconfirm, Typography, Space, Flex, Row, Col } from 'antd';
import { SearchOutlined, DeleteOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';

import { revokeInvitation } from '../../../actions/prerenderUserActions';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import useKeycloakRealm from '../../../hooks/useKeycloakRealm';
import { useDeleteUserMutation } from '../../teams/teamApiSliceLegacy';
import { AppUserRoles, UpdateRoleModal, InvitationForm } from '../../teams';
import {
  useInviteUserMutation,
  useGetInvitedLoginUsersQuery,
  useUpdateLoginUserRoleMutation,
} from '../../api/appUserApiSlice';
import { datetimeDisplayFormat } from '../../../utils/datetimeDisplayFormat';

const { Paragraph, Title } = Typography;

const TeamMembersPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isRevokingInvite, setIsRevokingInvite] = useState(false);
  const [userBeingUpdated, setUserBeingUpdated] = useState(null);
  const prerenderUser = usePrerenderUser();
  const dispatch = useDispatch();
  const kcRealm = useKeycloakRealm();

  const shouldDisplayRoles = ['prerender', 'prerender-staging'].includes(kcRealm);

  const [deleteUser, deleteUserStatus] = useDeleteUserMutation();
  const [inviteUser, inviteUserStatus] = useInviteUserMutation();
  const [updateUserRole, updateUserRoleStatus] = useUpdateLoginUserRoleMutation();
  const {
    data: invitedLoginUsers = [],
    isFetching: isFetchingInvitedUsers,
    refetch: listInvitedUsers,
  } = useGetInvitedLoginUsersQuery('teams-page', { refetchOnMountOrArgChange: true });

  const doRevokeInvitationAndRefresh = (email) => {
    setIsRevokingInvite(true);
    dispatch(revokeInvitation(email))
      .then(listInvitedUsers)
      .catch((e) => console.error(e))
      .finally(() => setIsRevokingInvite(false));
  };

  const doDeleteUserAndRefresh = (email) => {
    deleteUser(email)
      .then(listInvitedUsers)
      .catch((e) => console.error(e));
  };

  const invitedUsersData = useMemo(
    () =>
      invitedLoginUsers
        .filter((item) => item.email.includes(searchTerm.toLowerCase()))
        .map((user) => ({
          ...user,
          invitedAtFormatted: user.invitedAt ? datetimeDisplayFormat.format(new Date(+user.invitedAt)) : '',
          joinedAtFormatted: user.joinedAt ? datetimeDisplayFormat.format(new Date(+user.joinedAt)) : '',
          role: AppUserRoles[user.role],
        })),
    [invitedLoginUsers, searchTerm]
  );

  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const onUpdateUserRole = (data) => {
    updateUserRole(data)
      .then(() => setUserBeingUpdated(null))
      .catch((e) => console.error(e));
  };

  const inviteUserResult = useMemo(() => {
    if (inviteUserStatus.isError) return inviteUserStatus.error;

    if (inviteUserStatus.isSuccess) return { email: 'success' };

    return {};
  }, [inviteUserStatus.isError, inviteUserStatus.isSuccess, inviteUserStatus.error]);

  const isTableLoading = isFetchingInvitedUsers || deleteUserStatus.isLoading;

  return (
    <>
      <UpdateRoleModal
        open={!!userBeingUpdated}
        userData={userBeingUpdated}
        onClose={() => setUserBeingUpdated(null)}
        onSubmit={onUpdateUserRole}
        isLoading={updateUserRoleStatus.isLoading}
      />
      <Title level={5}>Team Members</Title>
      <Paragraph type="secondary">
        Manage multiple accounts within your application, you can invite your team...
      </Paragraph>

      <Row gutter={[24, 24]}>
        <Col xs={24} xxl={20}>
          <Card>
            <Flex vertical gap="small">
              <Input
                placeholder="Please enter the email address"
                allowClear
                onChange={onSearchChange}
                onPressEnter={onSearchChange}
                value={searchTerm}
                prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              />
              <Table
                bordered
                dataSource={invitedUsersData}
                rowKey="id"
                pagination={false}
                scroll={{ x: true }}
                size="small"
                loading={isTableLoading}
              >
                <Table.Column
                  title="Email Address"
                  dataIndex="email"
                  key="email"
                  render={(_text, record) => {
                    return (
                      <span>
                        {record.email}
                        {record.email === prerenderUser.adminEmail ? ' (Admin)' : ''}
                      </span>
                    );
                  }}
                />
                <Table.Column title="Status" dataIndex="status" key="status" />
                {shouldDisplayRoles && <Table.Column title="Role" dataIndex="role" key="role" className="col-min" />}
                <Table.Column
                  title="Invited At"
                  dataIndex="invitedAtFormatted"
                  key="invitedAtFormatted"
                  className="col-min"
                />
                <Table.Column
                  title="Joined At"
                  dataIndex="joinedAtFormatted"
                  key="joinedAtFormatted"
                  className="col-min"
                />
                <Table.Column
                  title="Actions"
                  key="Actions"
                  render={(_text, record) => {
                    if (record.status !== 'pending') {
                      return (
                        !(record.email === prerenderUser.email || record.email === prerenderUser.adminEmail) && (
                          <Popconfirm
                            title="Are you sure you want to delete this user?"
                            placement="left"
                            onConfirm={() => doDeleteUserAndRefresh(record.email)}
                            okType="danger"
                            okText="Delete"
                            cancelText="Cancel"
                          >
                            <Tooltip placement="top" title={prerenderUser.isAdmin ? 'Delete User' : 'Admins only'}>
                              <Button danger icon={<DeleteOutlined />} disabled={!prerenderUser.isAdmin} />
                            </Tooltip>
                          </Popconfirm>
                        )
                      );
                    }

                    return (
                      <Space size="small">
                        <Tooltip placement="top" title="Resend Invite">
                          <Button icon={<ReloadOutlined />} onClick={() => inviteUser({ email: record.email })} />
                        </Tooltip>
                        <Tooltip placement="top" title="Settings">
                          <Button icon={<SettingOutlined />} onClick={() => setUserBeingUpdated(record)} />
                        </Tooltip>
                        <Tooltip placement="top" title="Revoke Invitation">
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => doRevokeInvitationAndRefresh(record.email)}
                            loading={isRevokingInvite}
                          />
                        </Tooltip>
                      </Space>
                    );
                  }}
                />
              </Table>
            </Flex>
          </Card>
        </Col>
        <Col xs={24} lg={14}>
          <Card title="Invite Colleagues">
            <InvitationForm
              isLoading={inviteUserStatus.isLoading}
              invitation={inviteUserResult}
              onSubmit={inviteUser}
              selectRoles={shouldDisplayRoles}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TeamMembersPage;
