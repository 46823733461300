import { Form, Input, Button } from 'antd';

function ApplyCouponForm({ applyCoupon, isLoading = false }) {
  const onFinish = (values) => applyCoupon(values.code);

  return (
    <Form
      name="applyCoupon"
      layout="vertical"
      style={{
        maxWidth: '100%',
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Coupon's code"
        name="code"
        rules={[
          {
            required: true,
            message: "Please input your coupon's code.",
          },
          {
            pattern: /^[a-zA-Z0-9_-]+$/,
            message: 'The code cannot contain special characters like spaces or dashes.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Apply
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ApplyCouponForm;
