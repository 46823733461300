import { Button, Dropdown, Tooltip } from 'antd';
import ClearCacheComponent from '../../clearCache/clearCacheCard';
import RecacheIntervalCard from '../../recache-interval-settings/recacheIntervalCard';
import { useMemo, useState } from 'react';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';

const CacheManagerHeader = ({ prerenderUser, cachedPages, domainsCount, cm, onAddUrlClick, onEvent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const tooltipTitle = useMemo(() => {
    const isTooltip = true;
    if (prerenderUser.chargebeePlanId === 'basic-001') {
      return 'The Basic plan only allows a fixed cache expiration of 3 days';
    } else {
      return `Your pricing plan allows a cache freshness between ${formatCacheRefreshInterval(
        prerenderUser.plan.current.cacheRefreshInterval,
        isTooltip
      )}`;
    }
  }, [prerenderUser.chargebeePlanId, prerenderUser.plan.current.cacheRefreshInterval]);

  const onRecacheIntervalEvent = (e) => {
    if (e.action === 'closeCacheFreshnessCard') {
      setIsOpen(false);
      return;
    }
    if (e.action === 'updateCacheFreshnessSlider') {
      setIsOpen(false);
      onEvent(e);
      return;
    }

    onEvent(e);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          {cachedPages.params.query
            ? cachedPages.stats.amt === 1
              ? cm.card.titleQuery1
              : cm.card.titleQuery
            : domainsCount > 1
            ? `You have ${prerenderUser.numPagesCached.toLocaleString('en-US')} URLs from ${domainsCount.toLocaleString(
                'en-US'
              )} domains cached in total`
            : `You have ${prerenderUser.numPagesCached.toLocaleString('en-US')} URLs cached in total`}

          {cachedPages.params.query && <b className="ml-2">{cachedPages.params.query}</b>}
        </div>
        <div className="col">
          <div className="float-right">
            <Button type="primary" className="ml-2" onClick={() => onAddUrlClick()}>
              Add URLs
            </Button>
            <Dropdown
              dropdownRender={() => (
                <div style={{ maxWidth: 500, background: '#ffffff' }}>
                  <RecacheIntervalCard cm={cm} onEvent={onRecacheIntervalEvent} />
                </div>
              )}
              trigger={['click']}
              placement="bottomRight"
              onOpenChange={setIsOpen}
              open={isOpen}
            >
              <Tooltip title={tooltipTitle}>
                <Button className="ml-2" type="primary">
                  Cache Settings
                </Button>
              </Tooltip>
            </Dropdown>
            <ClearCacheComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default CacheManagerHeader;
