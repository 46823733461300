import { Flex, Typography, Progress } from 'antd';
import { memo } from 'react';
import { toFixedDuration } from '../../../../utils/duration';

const ColorToStatus = {
  green: 'success',
  red: 'exception',
};

const DeliveryProgressBar = ({ value, percent, title, color }) => {
  return (
    <Flex vertical gap={8}>
      <Typography.Title
        level={4}
        style={{ margin: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <span>{title}</span>
        <span>{toFixedDuration(value)} sec</span>
      </Typography.Title>
      <Progress showInfo={false} percent={percent} status={ColorToStatus[color] || 'normal'} size={['100%', 16]} />
    </Flex>
  );
};

export default memo(DeliveryProgressBar);
