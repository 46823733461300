import { useHistory } from 'react-router';
import { useMemo } from 'react';
import { Typography } from 'antd';
import { isFree } from '@prerender/billing-shared';

import './recacheIntervalCaption.css';
import { useEvent } from '../events/hooks/useEvent';

const { Link, Text } = Typography;

export default function RecacheIntervalCaption({ prerenderUser }) {
  const history = useHistory();
  const { track } = useEvent();
  const isFreeUser = useMemo(() => isFree(prerenderUser?.plan?.current?.id), [prerenderUser]);
  const isCustomPrice = useMemo(() => typeof prerenderUser?.customPrice === 'number', [prerenderUser]);

  return (
    <div className="col recache-interval-caption">
      {isFreeUser && (
        <>
          <Text>Your Basic plan includes a fixed cache expiration period of 3 days. </Text>
          <Link
            href="https://docs.prerender.io/docs/cache-expiration"
            onClick={() => {
              track('Recache settings learn more link clicked', {
                subscription_plan: prerenderUser?.chargebeePlanId,
              });
            }}
            target="_blank"
            rel="noreferrer"
          >
            Learn more{' '}
          </Link>
          <Text>about cache expiration or </Text>
          <Link
            onClick={() => {
              track('Recache settings upgrade link clicked', {
                subscription_plan: prerenderUser?.chargebeePlanId,
              });
              history.push('/billing/plans');
            }}
          >
            Upgrade now
          </Link>
          <Text> for customizable settings and more features.</Text>
        </>
      )}
      {!isFreeUser && (
        <>
          <Text>Choose how regularly your site's pages are recached to ensure content freshness. </Text>
          <Link
            href="https://docs.prerender.io/docs/cache-expiration"
            onClick={() => {
              track('Recache settings learn more link clicked', {
                subscription_plan: prerenderUser?.chargebeePlanId,
              });
            }}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </Link>
          {!isCustomPrice && (
            <>
              <Text> or </Text>
              <Link
                onClick={() => {
                  track('Recache settings learn more link clicked', {
                    subscription_plan: prerenderUser?.chargebeePlanId,
                  });
                  history.push('/billing/plans');
                }}
              >
                upgrade your plan
              </Link>
            </>
          )}
          <Text>.</Text>
        </>
      )}
    </div>
  );
}
