import { Button, Card, Space, Tooltip, Popconfirm, Col, Row, Statistic, Tag, message, Flex, Typography } from 'antd';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ReloadOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';

import { Container } from '../../components/CssFrameworkComponents';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Alert as ErrorAlert } from '../../components/CssFrameworkComponents';
import { usePageContent } from '../../hooks/usePageContent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';

import {
  useGetSitemapQuery,
  useEditSitemapMutation,
  useDeleteSitemapMutation,
  useReCacheSitemapMutation,
} from './api/sitemapApiSlice';
import { isDesktopCrawlingEnabled, isMobileCrawlingEnabled } from './sitemapUtil';
import { SitemapSettingsModal } from './components/SitemapSettingsModal';
import { SitemapCrawlDetailsModal } from './components/SitemapCrawlDetailsModal';
import { RecacheSitemapModal } from './components/RecacheSitemapModal';
import SitemapCrawlsTable from './components/SitemapCrawlsTable';

const { Text, Title } = Typography;

const cid = 'SitemapSettings';

function SitemapDetailsPage() {
  const rhistory = useHistory();
  const { id } = useParams();

  // Load sitemap
  const {
    data: getSitemapResult,
    isLoading: isFirstLoading,
    isError,
    refetch,
  } = useGetSitemapQuery(id, {
    pollingInterval: 3000,
  });

  const { track } = useEvent();

  // Edit hook
  const [editSitemap, { isLoading: isEditLoading }] = useEditSitemapMutation();

  // Recache hook
  const [reCacheSitemap, { isLoading: isReCacheLoading, isError: isReCacheSitemapError, reset: resetRecacheError }] =
    useReCacheSitemapMutation();

  // Delete sitemap hook
  const [deleteSitemap, { isLoading: isDeleteSitemapLoading }] = useDeleteSitemapMutation();

  const { cm } = usePageContent(cid, { groups: ['cachedPages', 'default'] });
  const prerenderUser = usePrerenderUser();

  const [isReCacheModalVisible, setIsReCacheModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  const [openedCrawl, setOpenedCrawl] = useState(null);

  const isLoading = isFirstLoading || isEditLoading || isDeleteSitemapLoading || prerenderUser.inProgress;
  const sitemapSettings = getSitemapResult?.data ? getSitemapResult.data : {};
  const intervalHours = parseInt(sitemapSettings.revisitInterval, 10) / 60 / 60;

  // Delete sitemap
  const onDeleteSitemap = async () => {
    try {
      await deleteSitemap(getSitemapResult.data.id).unwrap();
      rhistory.push('..');
    } catch (deleteSitemapError) {
      console.error({ deleteSitemapError });
    }
  };

  // Toggle sitemaps (enabled/disabled)
  const sitemapToggled = async () => {
    try {
      await editSitemap({ id: getSitemapResult.data.id, enabled: !getSitemapResult.data.isEnabled }).unwrap();
    } catch (editSitemapError) {
      console.error({ editSitemapError });
    }
  };

  const updateSitemapSettings = async ({ adaptiveType, revisitInterval }) => {
    try {
      await editSitemap({ id: sitemapSettings.id, adaptiveType, revisitInterval }).unwrap();
      track('Sitemap Settings Updated', {
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    } catch (error) {
      console.error(error);
      message.error('Cannot save sitemap settings');
    }
    setIsSettingsModalVisible(false);
  };

  // We need this check for legacy reasons, otherwise site breaks
  if (!cm) {
    return null;
  }

  const isHealthy =
    sitemapSettings.isHealthy === null ? (
      <Tag>Unknown</Tag>
    ) : sitemapSettings.isHealthy ? (
      <Tag color="green">Healthy</Tag>
    ) : (
      <Tag color="red">Unhealthy</Tag>
    );

  const isEnabled = sitemapSettings.isEnabled ? (
    <>
      <div className="d-flex flex-wrap">
        <div>
          <small>
            <i data-tip="Desktop" className="fe fe-monitor" style={{ marginRight: 8 }} />
          </small>
          {isDesktopCrawlingEnabled(sitemapSettings.adaptiveType) || !prerenderUser.mobileAdaptive ? (
            <Tag color="blue">Enabled</Tag>
          ) : (
            <Tag color="red">Disabled</Tag>
          )}
        </div>
        <div>
          <small>
            <i data-tip="Mobile" className="fe fe-smartphone" style={{ marginRight: 8 }} />
          </small>
          {isMobileCrawlingEnabled(sitemapSettings.adaptiveType) && prerenderUser.mobileAdaptive ? (
            <Tag color="blue">Enabled</Tag>
          ) : (
            <Tag color="red">Disabled</Tag>
          )}
        </div>
      </div>
    </>
  ) : (
    <Tag color="red">Disabled</Tag>
  );

  const createdAt = sitemapSettings.createdAt?.substring(0, 10);
  const lastCheckedAt = sitemapSettings.lastCheckedAt ? sitemapSettings.lastCheckedAt?.substring(0, 10) : '-';

  const nextVisit = sitemapSettings.lastCheckedAt
    ? new Date(new Date().setTime(new Date(sitemapSettings.lastCheckedAt).getTime() + intervalHours * 60 * 60 * 1000))
        .toISOString()
        .substring(0, 10)
    : '-';

  const source = sitemapSettings?.type?.toLowerCase().includes('user') ? (
    <Tooltip title={`Created by you`}>
      <UserAddOutlined />
    </Tooltip>
  ) : sitemapSettings?.type?.toLowerCase().includes('auto') ? (
    <Tooltip title={`Automaticaly discovered`}>
      <SearchOutlined />
    </Tooltip>
  ) : (
    '-'
  );

  const crawls = (sitemapSettings.crawls ? [...sitemapSettings.crawls] : []).sort((a, b) => {
    return b.id - a.id;
  });
  const isCrawling = crawls.some((c) => c.status === 'processing' || c.status === 'queued');

  const onReCacheSitemap = async ({ adaptiveType }) => {
    resetRecacheError();
    try {
      await reCacheSitemap({ sitemapId: id, adaptiveType }).unwrap();
      track('Sitemap ReCrawled', {
        subscription_plan: prerenderUser.chargebeePlanId,
      });
      setIsReCacheModalVisible(false);
    } catch (reCacheSitemapError) {
      console.error({ reCacheSitemapError });
    }
  };

  const cancelReCacheDialog = () => {
    resetRecacheError();
    setIsReCacheModalVisible(false);
  };

  const seeFailedCrawlDetails = (crawl) => {
    setOpenedCrawl(crawl);
  };

  return (
    <AdminTemplate>
      <RecacheSitemapModal
        open={isReCacheModalVisible}
        onOk={onReCacheSitemap}
        confirmLoading={isReCacheLoading}
        onCancel={cancelReCacheDialog}
        sitemapSettings={sitemapSettings}
        prerenderUser={prerenderUser}
        isReCacheSitemapError={isReCacheSitemapError}
      />
      <SitemapSettingsModal
        open={isSettingsModalVisible}
        onCancel={() => setIsSettingsModalVisible(false)}
        onOk={updateSitemapSettings}
        sitemapSettings={sitemapSettings}
        prerenderUser={prerenderUser}
        intervalHours={intervalHours}
      />
      <SitemapCrawlDetailsModal crawlResult={openedCrawl} onClose={() => setOpenedCrawl(null)} />
      {isError && <ErrorAlert modal={false} content={{ text: 'Failed to load sitemap' }} />}

      <Flex vertical style={{ marginBottom: 32 }}>
        <Flex justify={'space-between'} align={'center'}>
          <Title level={2} style={{ marginBottom: 8 }}>
            Sitemap details
          </Title>
          <Flex gap={8}>
            <Tooltip key="reload-tooptip" title="Refresh details">
              <Button
                key="reload"
                icon={<ReloadOutlined />}
                onClick={() => {
                  refetch();
                }}
                disabled={isLoading}
              />
            </Tooltip>

            <Tooltip key="crawl-now-tooptip" title={isCrawling ? 'Recache is in progress' : null}>
              <Button disabled={isLoading || isCrawling} type="primary" onClick={() => setIsReCacheModalVisible(true)}>
                Crawl now
              </Button>
            </Tooltip>

            <Button disabled={isLoading} key="disable" onClick={() => sitemapToggled()}>
              {sitemapSettings.isEnabled ? 'Disable' : 'Enable'}
            </Button>

            <Button disabled={isLoading} onClick={() => setIsSettingsModalVisible(true)}>
              Settings
            </Button>

            <Popconfirm
              key="delete"
              title="Delete this sitemap?"
              okText="Yes, delete"
              cancelText="Cancel"
              placement="left"
              onConfirm={() => onDeleteSitemap()}
            >
              <Button disabled={isLoading} type="primary" danger={true} onClick={() => null}>
                Delete
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
        <Text type="secondary">See detailed information about your sitemap like URLs found and health.</Text>
      </Flex>

      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Container addClass={'p-0'} fluid>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={15}>
              <Card loading={isLoading}>
                <Statistic title="URL" value={sitemapSettings.url ? sitemapSettings.url : '-'} />
              </Card>
            </Col>
            <Col span={5}>
              <Card loading={isLoading}>
                <Statistic title="Status" prefix={isEnabled} value={' '} />
              </Card>
            </Col>
            <Col span={4}>
              <Card loading={isLoading}>
                <Statistic title="Revisit interval" value={`${intervalHours} hours`} />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col span={5}>
              <Card loading={isLoading}>
                <Statistic title="Created At" value={createdAt} />
              </Card>
            </Col>
            <Col span={5}>
              <Card loading={isLoading}>
                <Statistic title="Last visited" value={lastCheckedAt} />
              </Card>
            </Col>
            <Col span={5}>
              <Card loading={isLoading}>
                <Statistic title="Next visit" value={nextVisit} />
              </Card>
            </Col>
            <Col span={5}>
              <Card loading={isLoading}>
                <Statistic title="Origin" prefix={source} value={' '} />
              </Card>
            </Col>
            <Col span={4}>
              <Card loading={isLoading}>
                <Statistic title="Health" value={' '} prefix={isHealthy} />
              </Card>
            </Col>
          </Row>
        </Container>

        <Container addClass={'p-0'} fluid>
          <Card title="Past crawls">
            <SitemapCrawlsTable isLoading={isLoading} rows={crawls} onFailedCrawlDetailsClick={seeFailedCrawlDetails} />
          </Card>
        </Container>
      </Space>
    </AdminTemplate>
  );
}

export default requireAuth(SitemapDetailsPage);
