import { Fragment } from 'react';
import { createHtmlContentFromCmText } from '../../assets/contentFormatter';
import { ControlButton } from '../../components/PageElementsLib';
import { Container } from '../../components/CssFrameworkComponents';
import { INTEGRATION_VERIFICATION_DONE, PREVIOUS_STEP } from './onboardingActions';
import { useHistory } from 'react-router';

const SUCCESS_IMAGE = '/assets/img/onboarding/integrationSuccessful.svg';
const FAILED_IMAGE = '/assets/img/onboarding/integrationFailed.svg';

const replaceCommonLink = (user, errors, integration, url, data) => {
  let { link } = data;
  if (link) {
    link = link
      .replace('{{email}}', user.email)
      .replace('{{errorCode}}', JSON.stringify(errors))
      .replace('{{integration}}', integration)
      .replace('{{url}}', url);
  }

  return {
    ...data,
    link,
  };
};

const IntegrationVerificationResult = ({ cm, prerenderUser, integration, validationResult, onEvent }) => {
  if (!validationResult) return null;

  const { working, errors, url } = validationResult;
  const image = working ? SUCCESS_IMAGE : FAILED_IMAGE;
  const successOrFailed = working ? 'success' : 'failed';
  const bodyContent = cm.body[successOrFailed];
  const history = useHistory();

  return (<Fragment> 
    <Container fluid>
      <div className="row justify-content-center m-4">
        <div className="col-12 text-center">
          <img src={image} alt = 'success state' style={{ maxHeight: '75px', maxWidth: '75px' }}/>
        </div>
        <div className="col-12 pt-4">
          <h1 className='text-center'>{bodyContent.title}</h1>
        </div>
        <div className="col-12">
          { working && <p className='text-center'>{bodyContent.subtitle}</p> }
          {!working && (
            <div className='mt-3'>
              {errors.map((errorCode, index) => (
              <p key={index}>{cm.body.failed.errors[errorCode].texts.map(createHtmlContentFromCmText)}</p>
              ))}
              <p>{cm.body.failed.common.texts
                .map((data) => replaceCommonLink(prerenderUser, errors, integration, url, data))
                .map(createHtmlContentFromCmText)}</p>
            </div>
          )}
        </div>
      </div>
    </Container>
    
    <div className="card-footer" id="modal-footer">
    <div className="row align-items-center">
        <div className="col-auto pl-0">{!working && <ControlButton text={cm.footer.btnLeft} onclick={() => onEvent({ action: PREVIOUS_STEP })} />}</div>
        <div className="col text-center">
          <h3 className="mb-0"></h3>
        </div>
        <div className="col-auto pr-0">{<ControlButton
        text={cm.footer.btnRight}
        onclick={() => history.push('/')} />}</div>
      </div>
    </div>
  
</Fragment>);
};

export default IntegrationVerificationResult;
