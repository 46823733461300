import { Alert as AntDAlert, Button, Modal, Typography, message, Layout } from 'antd';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  cachedPagesSearch,
  deleteCachedUrls,
  exportSitemap,
  requestCaching,
  saveSitemap,
} from '../actions/cachedPagesActions';
import { closeModal, getContent, openModal } from '../actions/pageActions';
import { getPrerenderUser, setCacheFreshness } from '../actions/prerenderUserActions';
import { getDomains } from '../actions/domainActions';
import { deleteUrlParams, setUrlParam, updateUrlParams, urlParamsSearch } from '../actions/urlParamsActions';
import UrlParamEditor from '../components/UrlParamEditor';
import CachedPagesEditor from '../components/CachedPagesEditor';
import CachedPagesExport from '../components/CachedPagesExport';
import ConfirmationModal from '../components/ConfirmationModal';
import { Alert, Header, NavTabs } from '../components/CssFrameworkComponents';
import ErrorLoader from '../components/ErrorLoader';
import requireAuth from '../components/hocs/requireAuth';
import SeoScoreDetails, { SEO_SCORE_DETAILS_MODAL_NAME } from '../features/seoScoreDetailsModal/SeoScoreDetailsModal';
import CachedPagesAdd from '../features/cache-manager/components/CachedPagesAdd';
import CacheManagerHeader from '../features/cache-manager/components/cacheManagerHeader.jsx';
import CacheManagerTable from '../features/cache-manager/components/cacheManagerTable.jsx';
import UrlParamsSettings from '../features/cache-manager/components/urlParametersSettings.jsx';
import IgnoredUrlsSettings from '../features/cache-manager/components/IgnoredUrlsSettings.jsx';
import SeoScoreModal, { SEO_SCORE_MODAL_NAME } from '../features/seoScoreModal/seoScoreModal';
import { saveSettings, setMobileRenderingSetting } from '../features/settings/redux/settingsActions';
import URLFilterModal from '../features/urlFilterModal/urlFilterModal';
import { useEvent } from '../features/events/hooks/useEvent';
import { cachedPagesApi } from '../features/api/cachedPagesApiSlice';
import AdminTemplate from '../layout/AdminTemplate';
import { presentAsDownload } from '../assets/lib';
import { getHashParam } from '../assets/hashParams';
import { formatDays } from '../assets/dateFormatter';

const { Text } = Typography;

const cid = 'CachedPagesPage';
const PAGE_SIZE = 100;
const MAX_URL_COUNT_FOR_SORT = 10000000;
const URL_LIST_SEARCHED_EVENT_NAME = 'Cache URL List Searched';
const DEFAULT_SORT = {
  name: 'last_refresh',
  asc: false,
};

const formatRecacheIntervalSuccessMessage = (days) =>
  `The recaching interval for all URLs has been set to ${formatDays(days)}.`;

class CachedPagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: getHashParam('tab') || 'cacheManager',
      settings: props.settings,
      selectedUrls: [],
      showCheckListOptions: true,
      querySearchInProgress: false,
      initialSearchQuery: props.cachedPages.params.query,
      searchBarId: new Date().getTime(),
      confirmCacheFreshnessModal: { opened: false, value: 0 },
      sort: DEFAULT_SORT,
      editUrlParamModal: { opened: false, urlParam: null },
      cachedPagesAddModal: { opened: false, urls: [] },
    };
  }

  componentDidMount() {
    const { cm, language, getContent: doGetContent } = this.props;
    if (!cm) doGetContent({ groups: ['cachedPages', 'default'], lang: language });

    this.performSearch({ page: 0, pageSize: PAGE_SIZE, sort: this.state.sort });
    this.reloadUrlParamsData();
    this.loadDomains();
    this.setState({
      selectedItems: [],
    });
  }

  performQuerySearch(query) {
    const { track } = useEvent();
    this.setState({ querySearchInProgress: false });
    if (query.length === 0 || query.length > 2) {
      this.performSearch({ ...this.props.cachedPages.params, page: 0, query });
      if (query.length > 2) {
        track(URL_LIST_SEARCHED_EVENT_NAME, {
          search_string: query,
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
        });
      }
    }
  }

  performQuerySearchAnyway(query) {
    const { track } = useEvent();
    this.setState({ querySearchInProgress: false });
    this.performSearch({ ...this.props.cachedPages.params, page: 0, query });
    track(URL_LIST_SEARCHED_EVENT_NAME, {
      search_string: query,
      subscription_plan: this.props.prerenderUser.chargebeePlanId,
    });
  }

  onSearchConditionChanged(queryCondition) {
    this.setState({ querySearchInProgress: false });
    if (this.props.cachedPages.params.query.length === 0 || this.props.cachedPages.params.query.length > 2) {
      this.performSearch({ ...this.props.cachedPages.params, page: 0, queryCondition });
    }
  }

  performDomainSearch(domain) {
    if (domain) {
      this.setState({ querySearchInProgress: false });
      this.performSearch({ ...this.props.cachedPages.params, page: 0, domain });
    } else {
      this.performSearch({ ...this.props.cachedPages.params, page: 0, domain: null });
    }
  }

  reloadUrlParamsData(page = this.props.urlParams.params.page) {
    this.performUrlParamsSearch({ ...this.props.urlParams.params, page });
  }

  performSearch(params) {
    if (!this.props.cachedPages.inProgress) {
      const { cachedPagesSearch: doCachedPagesSearch } = this.props;
      doCachedPagesSearch('all', params);
    }
  }

  performUrlParamsSearch(params) {
    const { urlParamsSearch: doUrlParamsSearch } = this.props;
    this.lastSearch = new Date();
    doUrlParamsSearch(params);
  }

  loadDomains(query = null) {
    const { getDomains: getDomains } = this.props;
    getDomains(query);
  }

  reloadData(page = this.props.cachedPages.params.page) {
    const { getPrerenderUser: doGetPrerenderUser, cachedPages } = this.props;
    doGetPrerenderUser();
    this.performSearch({ ...cachedPages.params, page });
  }

  clearSearch() {
    this.setState({ querySearchInProgress: false, searchBarId: new Date().getTime() });
    this.performSearch({
      ...this.props.cachedPages.params,
      page: 0,
      query: '',
      filter: null,
      sort: DEFAULT_SORT,
    });
  }

  transformCsvExportResponse(response) {
    const { prerenderUser: { email } = {} } = this.props;
    const cm = this.props.cm.exportCsvResult;
    switch (response?.status) {
      case 'queued':
        return email
          ? `Your export is processing and will take a couple of minutes. We\'ll email it to you at "${email}" when it\'s ready.`
          : 'We have scheduled your export. You will get a download link in your email once the export is ready.';
      case 'not queued':
        return cm.notQueued;
      case 'processing':
        return cm.processing;
      case 'already exists':
        return 'CSV export has been already requested. Please try again later.';
      case 'limited':
        return 'Please upgrade your plan to use this feature.';
      default:
        return cm.unknownError;
    }
  }

  updateCacheFreshness(cacheFreshness, track) {
    const { prerenderUser, setCacheFreshness: doSetCacheFreshness } = this.props;
    doSetCacheFreshness(prerenderUser, cacheFreshness);
    const message = formatRecacheIntervalSuccessMessage(cacheFreshness);

    this.setState({
      customMessage: { text: message, color: 'success' },
    });
    track('Cache Expiration Set', {
      subscription_plan: prerenderUser.chargebeePlanId,
      cache_expiration_time: cacheFreshness,
    });
  }

  didReceiveAction({ action, payload }) {
    const {
      cm,
      prerenderUser,
      setCacheFreshness: doSetCacheFreshness,
      requestCaching: doRequestCaching,
      cachedPages,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      exportSitemap: doExportSitemap,
      saveSitemap: doSaveSitemap,
      deleteCachedUrls: doDeleteCachedUrls,
      lastOpenedModal,
      setUrlParam: doSetUrlParam,
      deleteUrlParams: doDeleteUrlParams,
      updateUrlParams: doUpdateUrlParams,
      exportCsv: doExportCsv,
    } = this.props;
    const { selectedUrls, activeTab } = this.state;
    const { track } = useEvent();

    switch (action) {
      case 'modalCancelled':
        doCloseModal();
        break;
      case 'changePage':
        if (lastOpenedModal !== SEO_SCORE_MODAL_NAME || lastOpenedModal !== SEO_SCORE_DETAILS_MODAL_NAME)
          this.performSearch({ ...cachedPages.params, page: payload });
        break;
      case 'openEditUrlsModal':
      case 'openEditUrlModal':
        doOpenModal(
          'editModal',
          <CachedPagesEditor
            selectedUrls={action === 'openEditUrlsModal' ? selectedUrls : payload}
            onEvent={(onEventAction) => this.didReceiveAction(onEventAction)}
          />
        );
        break;
      case 'openExportModal':
        doOpenModal(
          'exportModal',
          <CachedPagesExport selectedUrls={payload} onEvent={(onEventAction) => this.didReceiveAction(onEventAction)} />
        );
        break;
      case 'openAddModal':
        this.setState({ customMessage: null });
        this.setState({ cachedPagesAddModal: { opened: true } });
        break;
      case 'updateCacheFreshnessSlider':
        if (payload.interval !== prerenderUser.cacheFreshness) {
          if (payload.interval < 1) {
            this.setState({
              confirmCacheFreshnessModal: { opened: true, value: payload.interval },
            });
          } else {
            this.updateCacheFreshness(payload.interval, track);
          }
        }
        break;
      case 'updateCacheFreshnessConfirmed': {
        this.updateCacheFreshness(payload.interval, track);
        this.setState({
          confirmCacheFreshnessModal: { opened: false },
        });
        if (window.ctrack) window.ctrack(action, payload.interval);
        break;
      }
      case 'requestRecache':
        doCloseModal();
        doRequestCaching('all', payload || selectedUrls, () => {
          const amt = selectedUrls.length > 0 ? selectedUrls.length : 1;
          this.setState({
            selectedUrls: [],
            customMessage:
              amt === 1
                ? { ...cm.success.recache1, linkTarget: '/recache-queue', color: 'success' }
                : {
                    ...cm.success.recache,
                    linkTarget: '/recache-queue',
                    text: cm.success.recache.text.replace('{{amt}}', amt),
                    color: 'success',
                  },
          });
        });
        track('URL Added To Recache Queue', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
          is_bulk_action: payload.length > 1,
        });
        if (window.ctrack) window.ctrack(action);
        break;
      case 'exportCsv': // This is a callback from the exportModal
        doCloseModal();
        doExportCsv(cachedPages.params)
          .then(({ data: response }) =>
            doOpenModal(
              'exportModal',
              <ConfirmationModal
                title={cm.exportCsvResult.modal.title}
                text={this.transformCsvExportResponse(response || {})}
                options={[{ action: 'cancel', text: cm.exportCsvResult.modal.closeButton }]}
                onAction={(_action) => {}}
              />
            )
          )
          .catch(() => {});
        break;
      case 'exportSitemap':
        doExportSitemap('all', payload, (data) => {
          presentAsDownload(data, 'sitemap.xml');
          doCloseModal();
          track('Cache URL List Exported', {
            subscription_plan: this.props.prerenderUser.chargebeePlanId,
          });
        });
        break;
      case 'urlsAdded': {
        doCloseModal();
        const addedElements = (payload || []).filter((r) => !r.error);
        const amt = addedElements.length;
        this.setState({
          customMessage:
            amt === 1
              ? { ...cm.success.addCache1, linkTarget: '/recache-queue', color: 'success' }
              : {
                  ...cm.success.addCache,
                  linkTarget: '/recache-queue',
                  text: cm.success.addCache.text.replace('{{amt}}', amt),
                  color: 'success',
                },
        });
        track('Cache Manager URL Added', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
        });
        this.reloadData(0); //  0: page
        break;
      }
      case 'saveSitemap':
        doSaveSitemap('all', payload, () => {
          doCloseModal();
        });
        break;
      case 'deleteUrls':
        doCloseModal();
        doDeleteCachedUrls('all', { urls: payload }, () => {
          this.reloadData(0); //  0: page
          this.setState({ selectedUrls: [] });
        });
        track('URL Removed From Cache', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
          is_bulk_action: payload.length > 1,
        });
        if (window.ctrack) window.ctrack(action, { urls: payload });
        break;
      case 'openURLFilterModal':
        doOpenModal(
          'URLFilterModal',
          <URLFilterModal onEvent={(onEventAction) => this.didReceiveAction(onEventAction)} />
        );
        break;
      case 'applyFilter':
        this.performSearch({ ...this.props.cachedPages.params, ...payload, page: 0 });
        break;
      case 'openSeoScoreModal':
        if (payload.seo_score !== null) {
          doOpenModal(SEO_SCORE_MODAL_NAME, <SeoScoreModal cachedUrl={payload} />);
        }
        break;
      case 'openSeoScoreStatusModal':
        if (payload.seo_score_status !== 'unknown') {
          doOpenModal(SEO_SCORE_DETAILS_MODAL_NAME, <SeoScoreDetails url={payload} />);
        }
        break;
      case 'tabSelected':
        if (activeTab !== payload) {
          this.setState({ activeTab: payload });
        }
        break;
      case 'openUrlParamEditor':
        this.setState({ editUrlParamModal: { opened: true, urlParam: payload } });
        break;
      case 'changePage':
        this.performSearch({ ...urlParams.params, page: payload });
        break;
      case 'setUrlParam':
        doSetUrlParam(payload, () => {
          doCloseModal();
          this.reloadData(0);
          this.setState({ selectedItems: [] });
          this.reloadUrlParamsData(0);
        });
        track('URL Parameter Added', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
          caching_rule: payload.value,
        });
        break;
      case 'deleteParams':
        doDeleteUrlParams(payload, () => {
          doCloseModal();
          this.reloadData(0);
          this.setState({ selectedItems: [] });
          this.reloadUrlParamsData(0);
        });
        track('URL Parameter Deleted', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
          is_bulk_action: payload.length > 1,
        });
        break;
      case 'updateRule':
        doUpdateUrlParams(payload, () => {
          doCloseModal();
          this.reloadData(0);
          this.setState({ selectedItems: [] });
          this.reloadUrlParamsData(0);
        });

        track('URL Parameter Rule Updated', {
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
          is_bulk_action: payload.length > 1,
          caching_rule: payload.ignore ? 'ignore when caching' : 'use when caching',
        });
        break;
      case 'updateRuleIgnore': {
        const ignorePayload = payload.map((id) => ({ id, ignore: true }));
        doUpdateUrlParams(ignorePayload, () => {
          doCloseModal();
          this.reloadData(0);
          this.setState({ selectedItems: [] });
        });
        break;
      }
      case 'updateRuleUse': {
        const usePayload = payload.map((id) => ({ id, ignore: false }));
        doUpdateUrlParams(usePayload, () => {
          doCloseModal();
          this.reloadData(0);
          this.setState({ selectedItems: [] });
        });
        break;
      }
      default:
        console.log(`Unknown action: ${action}`); // TODO: handle error
    }
  }

  onSortChanged(col, index) {
    if (this.props.prerenderUser.allURLCount >= MAX_URL_COUNT_FOR_SORT) return;
    let sort = null;

    if (col.sort) {
      sort = {
        index,
        name: col.sort.name,
        asc: col.sort.asc,
      };
    }

    this.setState({ sort });
    this.performSearch({ ...this.props.cachedPages.params, sort });
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, cachedPages, prerenderUser, modalVisible, domains, domainsCount } =
      this.props;
    const {
      selectedItems,
      customMessage,
      querySearchInProgress,
      showCheckListOptions,
      activeTab,
      confirmCacheFreshnessModal,
    } = this.state;
    const { track } = useEvent();

    if (!cm) return null;
    return (
      <AdminTemplate metaTags={cm.meta}>
        <Modal
          title="Cache Settings"
          open={confirmCacheFreshnessModal.opened}
          okText={'Confirm'}
          transitionName=""
          maskTransitionName=""
          onOk={() => {
            this.didReceiveAction({
              action: 'updateCacheFreshnessConfirmed',
              payload: { interval: confirmCacheFreshnessModal.value },
            });
          }}
          onCancel={() => {
            this.setState({ confirmCacheFreshnessModal: { opened: false } });
          }}
        >
          <div className="text-danger">
            Decreasing the cache freshness interval will lead to an increased number of renders. Would you like to
            proceed with the change? You can read more details regarding our rendering process in{' '}
            <a href="https://docs.prerender.io/docs/what-you-pay-for" target="_blank" rel="noreferrer">
              this
            </a>{' '}
            article.
          </div>
        </Modal>
        <UrlParamEditor
          urlParam={this.state.editUrlParamModal.urlParam}
          onEvent={(onEvent) => this.didReceiveAction(onEvent)}
          showModal={this.state.editUrlParamModal.opened}
          onModalClose={() => this.setState({ editUrlParamModal: { opened: false, urlParam: null } })}
        />
        <CachedPagesAdd
          onEvent={(onEventAction) => this.didReceiveAction(onEventAction)}
          mobileAdaptive={prerenderUser.mobileAdaptive}
          showModal={this.state.cachedPagesAddModal.opened}
          onModalClose={() => this.setState({ cachedPagesAddModal: { opened: false } })}
        />

        <Header title="Cache Manager">
          <Text type="secondary">
            This section allows you to keep an eye on your cached pages with the options to manually re-cache or remove
            them as well as adjust your cache settings.
          </Text>
          <NavTabs
            tabs={[
              { id: 'cacheManager', text: cm.header.tabs.cacheManager.text },
              { id: 'urlParams', text: cm.header.tabs.urlparams.text },
              { id: 'ignoredUrls', text: cm.header.tabs.ignoredUrls.text },
            ]}
            activeTab={activeTab}
            onAction={(action) => this.didReceiveAction(action)}
          />
        </Header>

        <div>
          <ErrorLoader code={modalVisible ? '' : lastErrorCode} custom={customMessage} scrollTop>
            <div className="row">
              <Alert
                className="col-12"
                content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : customMessage}
              />
            </div>
          </ErrorLoader>

          {!this.props.settings.renderingSettings.mobile.enabled && (
            <AntDAlert
              message="Warning! Mobile optimized rendering is disabled, requests from mobile crawlers like GoogleBot Mobile will receive a desktop version of your website."
              type="warning"
              size="large"
              action={
                <Button
                  type="primary"
                  onClick={() => {
                    const newState = cloneDeep(this.props.settings);
                    newState.renderingSettings.mobile.enabled = true;
                    this.props.setMobileRenderingSetting(newState.renderingSettings.mobile);

                    this.props
                      .saveSettings({
                        settings: newState,
                        userId: this.props.prerenderUser.id,
                      })
                      .then(() => {
                        this.props.getPrerenderUser();

                        message.success('Optimization applied!');
                      })
                      .catch(() => {
                        message.error('Something went wrong!');
                      });
                  }}
                >
                  Quick Fix
                </Button>
              }
              showIcon
              style={{ margin: '5px 0 20px 0' }}
            />
          )}
          {/* Cache freshness slider */}
          {activeTab === 'cacheManager' && (
            <div className="row justify-content-center">
              <div className="col">
                <CacheManagerHeader
                  prerenderUser={prerenderUser}
                  cachedPages={cachedPages}
                  domainsCount={domainsCount}
                  cm={cm}
                  onAddUrlClick={() => this.didReceiveAction({ action: 'openAddModal' })}
                  onEvent={(event) => {
                    this.didReceiveAction(event);
                  }}
                />
              </div>
              <div className="col-12">
                <CacheManagerTable
                  cachedPages={cachedPages}
                  domains={domains}
                  prerenderUser={prerenderUser}
                  cm={cm}
                  onSortChanged={this.onSortChanged}
                  state={this.state}
                  props={this.props}
                  setState={(s) => this.setState(s)}
                  didReceiveAction={(a) => this.didReceiveAction(a)}
                  performQuerySearch={(q) => {
                    this.performQuerySearch(q);
                  }}
                  performQuerySearchAnyway={(q) => {
                    this.performQuerySearchAnyway(q);
                  }}
                  onSearchConditionChanged={(c) => this.onSearchConditionChanged(c)}
                  performDomainSearch={(d) => {
                    this.performDomainSearch(d);
                    track('Cache URL list serched: Domain', {
                      subscription_plan: this.props.prerenderUser.chargebeePlanId,
                      domain: d,
                    });
                  }}
                  performSearch={(q) => {
                    this.performSearch(q);
                  }}
                  reloadData={(q) => this.reloadData(q)}
                  clearSearch={() => this.clearSearch()}
                />
              </div>
            </div>
          )}

          {activeTab === 'urlParams' && (
            <div className="row justify-content-center">
              <UrlParamsSettings
                setState={(s) => this.setState(s)}
                selectedItems={selectedItems}
                querySearchInProgress={querySearchInProgress}
                showCheckListOptions={showCheckListOptions}
                didReceiveAction={(a) => this.didReceiveAction(a)}
                performSearch={(q) => this.performSearch(q)}
              />
            </div>
          )}
          {activeTab === 'ignoredUrls' && (
            <div className="row justify-content-center">
              <IgnoredUrlsSettings />
            </div>
          )}
        </div>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    ssrEntryUrl: state.page.ssrEntryUrl,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
    language: state.page.language,
    cachedPages: state.cachedPages.all,
    prerenderUser: state.prerenderUser,
    modalVisible: state.page.modalVisible,
    lastOpenedModal: state.page.lastOpenedModal,
    settings: state.settings,
    urlParams: state.urlParams,
    initialSearchQuery: state.urlParams.params.query,
    domains: state.domains,
    domainsCount: state.domains.totalCount,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      cachedPagesSearch,
      getPrerenderUser,
      openModal,
      closeModal,
      setCacheFreshness,
      requestCaching,
      exportSitemap,
      saveSitemap,
      deleteCachedUrls,
      setMobileRenderingSetting,
      saveSettings,
      setUrlParam,
      urlParamsSearch,
      deleteUrlParams,
      updateUrlParams,
      getDomains,
      exportCsv: cachedPagesApi.endpoints.exportCsv.initiate,
    },
    dispatch
  );
}

function loadData(store) {
  const state = store.getState();
  return store.dispatch(getContent({ groups: ['cachedPages', 'default'], lang: state.page.language }));
}

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(CachedPagesPage)),
  loadData,
};
