import { useEffect, useState } from 'react';
import SpinningWheel from '../../../../../../components/SpinningWheel';
import { Flex } from 'antd';

const LOADING_TEXTS = [
  'Scan in Progress',
  'Getting your site’s content.',
  'Making a static copy for bots.',
  'Speeding up scripts and styles.',
  'Ensuring your site is search-friendly.',
  'Hang tight—we’re almost there!',
];

const TextLoadingAnimation = () => {
  const [loadingIndex, setLoadingIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingIndex((prevIndex) => {
        if (prevIndex === LOADING_TEXTS.length - 1) {
          clearInterval(intervalId);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex vertical gap="small" align="center" justify="center" style={{ zIndex: 2 }}>
      <SpinningWheel matchParent />
      {LOADING_TEXTS[loadingIndex]}
    </Flex>
  );
};

export default TextLoadingAnimation;
