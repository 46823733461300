import React, { useEffect, useMemo } from 'react';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Layout, Tabs, Typography, message } from 'antd';
import EventHistoryTab from './tabs/EventHistoryTab';
import { Switch, Route, useHistory, useLocation, useRouteMatch, Redirect } from 'react-router-dom';
import EventDescriptionTab from './tabs/EventDescriptionTab';
import { useGetEventsQuery } from './oversightApiSlice';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const { TabPane } = Tabs;

const PAGES = {
  eventHistory: 'event-history',
  eventDescription: 'event-description',
};

const OversightTabs = ({ activeTab, onTabClick, tabs }) => (
  <Tabs activeKey={activeTab} onTabClick={onTabClick}>
    {tabs.map((tab) => (
      <TabPane tab={tab.label} key={tab.key} />
    ))}
  </Tabs>
);

const OversightPage = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const activeTab = useMemo(() => location.pathname.split('/').pop(), [location.pathname]);
  const { data: eventTypes, error: eventTypesError, isFetching: isFetchingEventTypes } = useGetEventsQuery();

  useEffect(() => {
    if (eventTypesError) {
      message.error('Error fetching events');
    }
  }, [eventTypesError?.data?.error]);

  const onTabClick = (tabKey) => {
    history.push(`${path}/${tabKey}`);
  };

  const tabs = [
    {
      key: PAGES.eventHistory,
      label: 'Event History',
      component: EventHistoryTab,
    },
    {
      key: PAGES.eventDescription,
      label: 'Event Descriptions',
      component: EventDescriptionTab,
    },
  ];

  return (
    <AdminTemplate>
      <Content>
        <Title level={2}>Oversight</Title>
        <Paragraph type="secondary">
          This section allows you to monitor notifications and messages from our system.
        </Paragraph>
        <OversightTabs activeTab={activeTab} onTabClick={onTabClick} tabs={tabs} />
        <Switch>
          {tabs.map((tab) => (
            <Route key={tab.key} path={`${path}/${tab.key}`} exact>
              <tab.component
                eventTypes={eventTypes}
                eventTypesError={eventTypesError}
                loadingEventTypes={isFetchingEventTypes}
              />
            </Route>
          ))}
          <Route path="*">
            <Redirect to={`${path}/${PAGES.eventHistory}`} />
          </Route>
        </Switch>
      </Content>
    </AdminTemplate>
  );
};

export default requireAuth(OversightPage);
