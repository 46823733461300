// Define action creators that dispatch actions:
import config from '../assets/config';
import { environmentApi } from '../features/api/environmentApiSlice';
import internalApi, { THROW_ON_ERROR_V1, THROW_ON_ERROR_V2 } from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { clearLastErrorCode, setLastErrorCode } from './pageActions';
import { setShowHasBillingAddressBadge, setShowHasBillingInfoBadge } from './billingInfoActions';
import { setOriginalState } from '../features/settings/redux/settingsActions';

import {
  PRERENDER_USER_SET,
  PRERENDER_USER_IN_PROGRESS,
  PRERENDER_USER_UNAUTHORIZED,
  UPDATE_PRERENDER_USER_TRACKING_CODE,
  UPDATE_PRERENDER_TOKEN,
  SET_SHOW_ONBOARDING,
  ERASE_PAGE_REDUCER,
  CACHEDPAGES_RESET,
  CRAWLERACTIVITY_RESET,
  RECACHEQUEUE_RESET,
  CACHINGACTIVITY_RESET,
  URLPARAMS_RESET,
  PRERENDER_USER_CLEAR,
  INVITATION_SUCCEEDED,
  INVITATION_FAILED,
  INVITATION_CLEAR,
  INVITATION_IN_PROGRESS,
  REVOKE_INVITATION_SUCCEEDED,
  REVOKE_INVITATION_FAILED,
  REVOKE_INVITATION_IN_PROGRESS,
  USER_DELETION_SUCCEEDED,
  USER_DELETION_FAILED,
  USER_DELETION_IN_PROGRESS,
} from './types';

import { keycloakLogout } from '../features/auth/keycloakAuth';

const API_URL = config.apiUrl.authServer;

const SKIP_CLEAR_ERROR = true;
const DONT_SKIP_CLEAR_ERROR = false;

const doGetPrerenderUser = async (dispatch, skipErrorClear = DONT_SKIP_CLEAR_ERROR) => {
  try {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/user`;
    const data = await internalApi('get', requestUri, null);
    dispatch({ type: PRERENDER_USER_SET, payload: data });
    if (!skipErrorClear) {
      dispatch(clearLastErrorCode());
    }
    dispatch(setShowHasBillingAddressBadge(!data.hasBillingAddress));
    dispatch(setShowHasBillingInfoBadge(!data.hasBillingInfo));
    dispatch(setOriginalState(data));
  } catch (error) {
    dispatch({ type: PRERENDER_USER_UNAUTHORIZED, payload: { value: false } });
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    throw error;
  }
};

export const getPrerenderUserThrows = () => async (dispatch) => {
  await doGetPrerenderUser(dispatch);
};

export const tryToGetPrerenderUser = () => async (dispatch) => {
  await doGetPrerenderUser(dispatch, SKIP_CLEAR_ERROR);
};

export const getPrerenderUser = () => async (dispatch) => {
  try {
    await doGetPrerenderUser(dispatch);
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const getPrerenderUserSkipClearError = () => async (dispatch) => {
  try {
    await doGetPrerenderUser(dispatch, SKIP_CLEAR_ERROR);
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const savePrerenderUser = (prerenderUser) => async (dispatch) => {
  try {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/user`;
    const payload = { user: prerenderUser };
    await internalApi('patch', requestUri, payload);
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    dispatch(clearLastErrorCode());
  } catch (error) {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
    throw error;
  }
};

export const changeEmail =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/change-email`;
      const payload = { email };
      await internalApi('post', requestUri, payload, THROW_ON_ERROR_V1);
      dispatch(clearLastErrorCode());
    } catch (error) {
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
      throw error;
    } finally {
      dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    }
  };

export const cancelSubscription =
  ({ password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/cancel-subscription`;
      const payload = { password };
      await internalApi('post', requestUri, payload, THROW_ON_ERROR_V1);
      dispatch(clearLastErrorCode());
    } catch (error) {
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    } finally {
      dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    }
  };

export const setCacheFreshness = (prerenderUser, value) => async (dispatch) => {
  try {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
    const requestUri = `${config.apiUrl.dataServer}/api/set-cache-freshness`;
    await internalApi('post', requestUri, { value });
    dispatch({ type: PRERENDER_USER_SET, payload: { ...prerenderUser, cacheFreshness: parseFloat(value) } });
    dispatch(environmentApi.util.invalidateTags(['EnvironmentHistory']));
    dispatch(clearLastErrorCode());
  } catch (error) {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const togglePauseRecaching = (prerenderUser, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: true } });
    const requestUri = `${config.apiUrl.dataServer}/api/toggle-pause-recaching`;
    await internalApi('post', requestUri, {});
    dispatch({
      type: PRERENDER_USER_SET,
      payload: { ...prerenderUser, userPausedRecaching: !prerenderUser.userPausedRecaching },
    });
    dispatch(clearLastErrorCode());
    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({ type: PRERENDER_USER_IN_PROGRESS, payload: { value: false } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

// ---------------------------------------

export const setShowOnboarding = (value) => ({ type: SET_SHOW_ONBOARDING, payload: value });

export const signOutUser = () => async (dispatch) => {
  keycloakLogout();
  dispatch({ type: RECACHEQUEUE_RESET });
  dispatch({ type: CACHINGACTIVITY_RESET });
  dispatch({ type: URLPARAMS_RESET });
  dispatch({ type: ERASE_PAGE_REDUCER });
  dispatch({ type: CACHEDPAGES_RESET });
  dispatch({ type: CRAWLERACTIVITY_RESET });
  dispatch({ type: PRERENDER_USER_UNAUTHORIZED });
  dispatch({ type: PRERENDER_USER_CLEAR });
};

export const regenerateApiToken = async (dispatch) => {
  try {
    const { newToken } = await internalApi('post', `${API_URL}/api/regenerate-token`, {}, THROW_ON_ERROR_V1);
    dispatch(clearLastErrorCode());
    dispatch({ type: UPDATE_PRERENDER_TOKEN, payload: newToken });
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
    throw error; // Notify the caller that the call failed
  }
};

export const updateTrackingCodeInstalledLocal = (installed) => ({
  type: UPDATE_PRERENDER_USER_TRACKING_CODE,
  payload: { installed },
});

export const revokeInvitation = (emailInput) => async (dispatch) => {
  const email = emailInput.trim();
  try {
    dispatch({ type: REVOKE_INVITATION_IN_PROGRESS });
    const { invitationLink } = await internalApi(
      'post',
      `${API_URL}/api/v2/revoke-invitation`,
      { email },
      THROW_ON_ERROR_V2
    );
    dispatch(clearLastErrorCode());
    dispatch({ type: REVOKE_INVITATION_SUCCEEDED, payload: { email, invitationLink } });
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
    dispatch({ type: REVOKE_INVITATION_FAILED, payload: { email, ...error } });
  }
};

export const clearInvitationMessage = () => async (dispatch) => {
  dispatch({ type: INVITATION_CLEAR });
};
