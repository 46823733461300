import { Flex, Typography } from 'antd';
import { memo, useMemo } from 'react';
import DeliveryProgressBar from './DeliveryProgressBar';

const { Title, Text } = Typography;

const MAX_DELIVERY_TIME = 5000;
const DIFF_TO_SHOW = 80;

const DeliveryTime = ({ base, improved }) => {
  const diff = useMemo(() => {
    return (improved * 100) / base;
  }, [improved, base]);

  if (diff > DIFF_TO_SHOW) {
    return null;
  }

  const percentWithoutPrerender = useMemo(() => {
    const percent = (base * 100) / MAX_DELIVERY_TIME;

    return Math.min(percent.toFixed(0), 100);
  }, [base]);

  const percentWithPrerender = useMemo(() => {
    const percent = (improved * 100) / Math.min(base, improved > MAX_DELIVERY_TIME ? improved : MAX_DELIVERY_TIME);

    return Math.min(percent.toFixed(0), 100);
  }, [improved, base]);

  return (
    <div className="mt-5 mb-5">
      <Flex vertical align="center" gap={16}>
        <Title level={3} style={{ marginBottom: 0 }}>
          Delivery speed
        </Title>
        <Text>Your page now delivers quicker</Text>
      </Flex>
      <Flex vertical gap={16}>
        <DeliveryProgressBar
          title="Delivery time without Prerender"
          value={base}
          percent={percentWithoutPrerender}
          color="red"
        />
        <DeliveryProgressBar
          title="Delivery time with Prerender"
          value={improved}
          percent={percentWithPrerender}
          color="green"
        />
      </Flex>
    </div>
  );
};

export default memo(DeliveryTime);
