import { Route, Switch } from 'react-router-dom';

import { DomainsListPage, DomainDetailsPage } from '../features/domain-manager';
import requireAuth from '../components/hocs/requireAuth';
import useFeatureFlag, { DOMAIN_SCHEDULER_SETTINGS, DOMAINS_DETAILS_FEATURE } from '../hooks/useFeatureFlag';
import Error404Page from './Error404Page';

function DomainsManagerPage() {
  const canUseDomainDetails = useFeatureFlag([DOMAINS_DETAILS_FEATURE]);
  const canUseSchedulerSettings = useFeatureFlag([DOMAIN_SCHEDULER_SETTINGS]);
  const isDetailsPageAvailable = canUseDomainDetails || canUseSchedulerSettings;

  return (
    <Switch>
      <Route path="/domain-manager" component={DomainsListPage} exact />
      {isDetailsPageAvailable && <Route path="/domain-manager/:domain" component={DomainDetailsPage} exact />}
      <Route path="*" component={Error404Page.component} />
    </Switch>
  );
}

export default { component: requireAuth(DomainsManagerPage) };
