import { Alert, Button, Space } from 'antd';
import { ChargeBeePlanId, isLitePlanAvailable } from '@prerender/billing-shared';
import { useHistory } from 'react-router';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { isFreePlan } from '../../chargebee/chargebee';
import React, { useEffect } from 'react';
import './UpgradeToPaidAlert.css';

// AB Testing messages and groups according to the https://www.notion.so/saas-group/Header-Banner-Messages-0ed3cf6893184b86adf9ab323f06d82b
const MessageGroups = [
  {
    abGroup: '4',
    title: '260% Faster Indexing Starts Now',
    description: (
      <span>
        Experience the full potential of Prerender! Upgrade and <b>get found faster</b> by search engines and{' '}
        <b>boost SEO performance.</b>
      </span>
    ),
  },
  {
    abGroup: '6',
    title: 'Unlock Your Website’s Full Potential Now!',
    description: 'Your website can achieve more with Prerender. Unlock all the benefits you and your website deserve.',
  },
];

const UpgradeToPaidAlert = () => {
  const user = usePrerenderUser();
  const history = useHistory();

  const { track } = useEvent();
  // AB Testing based on user id
  const message = MessageGroups[user.id % 2];

  useEffect(() => {
    track('Upgrade To Paid Alert Shown', { group: message.abGroup });
  }, []);

  const onUpgradeClick = () => {
    track('Upgrade To Paid Alert Clicked', { group: message.abGroup });
    history.push(`/billing/plans?utm_source=opportunity_banner_${message.abGroup}`);
  };

  return (
    <Alert
      message={message.title}
      description={message.description}
      banner={true}
      type="success"
      action={
        <Button type="primary" onClick={onUpgradeClick}>
          Upgrade Now
        </Button>
      }
    />
  );
};

const LitePlanAlert = () => {
  const { track } = useEvent();
  const history = useHistory();

  useEffect(() => {
    track('Upgrade To Paid Alert Shown', { group: 'lite' });
  }, []);

  const onUpgradeClick = () => {
    track('Upgrade To Paid Alert Clicked', { group: 'lite' });
    history.push('/billing/plans?utm_source=opportunity_banner_lite');
  };

  return (
    <Alert
      message="🚀 Exclusive Offer: Essential Features, Lite Price! 🚀"
      // eslint-disable-next-line max-len
      description="Get the power of the Essential Plan at a fraction of the cost. Secure 15,000 renders p/m, cache freshness flexibility, and customer support for just $30/month."
      banner={true}
      type="success"
      action={
        <Button type="primary" onClick={onUpgradeClick}>
          Upgrade to Lite Plan
        </Button>
      }
    />
  );
};

const KeywordAlert = () => {
  const { track } = useEvent();
  const history = useHistory();

  useEffect(() => {
    track('Keyword ranking Alert Shown');
  }, []);

  const onTryNowClick = () => {
    track('Keyword ranking Alert Clicked');
    history.push('/rank-tracker?utm_source=banner');
  };

  return (
    <Alert
      message="New! Rank Tracker Now Available"
      // eslint-disable-next-line max-len
      description="Monitor your keyword rankings directly in Prerender. Accurate, daily updates included with your plan."
      banner={true}
      type="success"
      action={
        <Button type="primary" onClick={onTryNowClick}>
          Try Now
        </Button>
      }
    />
  );
};

const UpgradePlanAlert = () => {
  const user = usePrerenderUser();
  const location = useHistory().location;
  const isLitePlanAv = isLitePlanAvailable({
    billing_period: user.billingPeriod,
    cached_num_cached: user.numPagesCached,
    cached_render_counter: user.renderCounter,
    chargebee_plan_id: user.chargebeePlanId,
    environment: {
      cache_freshness: user.cacheFreshness,
    },
    had_paid_plan: user.hadPaidPlan,
    is_metered_billing: user.isMeteredBilling,
    custom_cached_pages_limit: user.customCachedPagesLimit,
    custom_price: user.customPrice,
    tracking_code_installed_date: user.trackingCodeInstalledDate,
  });

  const showLitePlanAlert =
    isLitePlanAv && user.chargebeePlanId !== ChargeBeePlanId.Lite2024 && !location.pathname.startsWith('/billing');

  const showUpgradeAlert =
    isFreePlan(user.plan) &&
    !user.isCancelled &&
    user.trackingCodeInstalled &&
    !location.pathname.startsWith('/billing');

  const showKeywordAlert =
    !isFreePlan(user.plan) && !user.isCancelled && !location.pathname.startsWith('/rank-tracker');

  const upgradePlanAlert = () => {
    if (showLitePlanAlert) {
      return <LitePlanAlert />;
    } else if (showUpgradeAlert) {
      return <UpgradeToPaidAlert />;
    } else if (showKeywordAlert) {
      return <KeywordAlert />;
    }
  };

  if (!showUpgradeAlert && !showLitePlanAlert && !showKeywordAlert) return <></>;

  return (
    <Space className="upgrade-to-paid-alert" direction="vertical" style={{ width: '100%' }}>
      {upgradePlanAlert()}
    </Space>
  );
};

export default UpgradePlanAlert;
