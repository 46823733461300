import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

import { getContent } from '../actions/pageActions';

import './FeatureRequestPage.css';

const cid = 'FeatureRequestPage';

const FeatureRequestPage = ({ cm, language, getContent: doGetContent }) => {
  useEffect(() => {
    if (!cm) {
      doGetContent({ component: cid, lang: language });
    }
  }, [doGetContent, language, cm]);

  if (!cm) {
    return null;
  }
  return (
    <AdminTemplate>
      <div className="p-3">
        <iframe
          id="feature-request-page-iframe"
          title="productboard-product-portal"
          src={cm.iframe.src}
          frameBorder="0"
        />
      </div>
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  cm: state.page.contentData[cid],
  language: state.page.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContent,
    },
    dispatch
  );

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(FeatureRequestPage)),
};
