import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { cachedPagesSearch } from '../actions/cachedPagesActions';
import crawlerActivitySearch from '../actions/crawlerActivityActions';
import dashboardStatsSearch from '../actions/dashboardStatsActions';
import { closeModal, getContent, openModal } from '../actions/pageActions';
import { getPrerenderUser, setShowOnboarding } from '../actions/prerenderUserActions';
import { objectsAreEqual, removeTrailingString } from '../assets/lib';
import { getAgeString } from '../assets/time';

import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { formatPercent } from '../assets/numberFormatter';
import { isEnterprisePlan } from '../chargebee/chargebee';
import { Card, ListGroup, ListGroupItem } from '../components/CssFrameworkComponents';
import DataPointCard from '../components/DataPointCard';
import requireAuth from '../components/hocs/requireAuth';
import { CrawlerIcon, LoadingTextBar } from '../components/PageElementsLib';
import PageLimitCard, { getPageLimit, isLimitReached } from '../components/PageLimitCard';
import PlanPriceCards from '../components/PlanPriceCards';
import TypeformBanner from '../components/TypeformBanner';
import Onboarding from '../features/onboarding';
import UsageStatisticsComponent from '../features/usage-statistics/usageStatisticsCard';
import AdminTemplate from '../layout/AdminTemplate';

const cid = 'DashboardPage';
const HEALT_ISSUE_RECACHING_DISABLED = 'recachingDisabled';
const HEALTH_ISSUE_HAS_BILLING_INFO = 'hasBillingInfo';
const HEALTH_ISSUE_HAS_BILLING_ADDRESS = 'hasBillingAddress';
const HEALTH_ISSUE_HAS_VERIFY_EMAIL = 'emailVerified';
const SHOW_NEVER = true;
const SHOW_TYPEFORM_BANNER = false;

const TIMEDOUT_RATE_UPPER_LIMIT = 0.01;

const timedoutRate = (timeoutStats) => {
  if (!timeoutStats) return 0;
  const allPages = (timeoutStats.allAnalytics || 0) + (timeoutStats.allRecacheAnalytics || 0);
  const timedoutPages = (timeoutStats.timedoutAnalytics || 0) + (timeoutStats.timedoutRecacheAnalytics || 0);

  if (allPages === 0) return 0;

  return timedoutPages / allPages;
};

const setHealthIssues = (cm, prerenderUser, timeoutStats, showHasBillingInfoBadge, showHasBillingAddressBadge) => {
  if (!cm || !prerenderUser) return [];

  const healthIssues = cm.healthCheck.items.filter((item) => {
    if (item.ref === HEALTH_ISSUE_HAS_BILLING_INFO) return showHasBillingInfoBadge;
    if (item.ref === HEALTH_ISSUE_HAS_BILLING_ADDRESS) return showHasBillingAddressBadge;
    // verifyEmail is handled by keycloak, so we filter it but later we intend to restore it
    if (item.ref === HEALTH_ISSUE_HAS_VERIFY_EMAIL) return false;

    // General badges without recachingDisabled
    return item.ref !== HEALT_ISSUE_RECACHING_DISABLED && !prerenderUser[item.ref];
  });

  // Recaching disabled
  if (prerenderUser.recachingDisabled || prerenderUser.userPausedRecaching) {
    healthIssues.push({ ref: HEALT_ISSUE_RECACHING_DISABLED });
  }

  // has suggested plan
  if (prerenderUser.isMeteredBilling && prerenderUser.plan.suggested) {
    healthIssues.push({
      ref: 'Upgrade Plan',
      falseText: cm.healthCheck.suggestedPlan,
      fixLink: '/billing/plans',
    });
  }

  // Limit reached
  const reachedLimit = isLimitReached(prerenderUser);
  if (!prerenderUser.isMeteredBilling && reachedLimit) {
    const pageLimit = getPageLimit(prerenderUser);
    healthIssues.push({
      ref: 'Page Limit Reached',
      trueText: cm.healthCheck.pageLimitOkay,
      falseText: cm.healthCheck.pageLimitReached.replace(/{{pageLimit}}/, pageLimit),
    });
  }

  // Timeout stats
  const timeoutRate = timedoutRate(timeoutStats);
  if (TIMEDOUT_RATE_UPPER_LIMIT < timeoutRate) {
    healthIssues.push({
      ref: 'Page timeouts',
      trueText: cm.healthCheck.pageTimeoutOkay,
      falseText: cm.healthCheck.pageTimeoutHigh.replace(/{{pageTimeoutRate}}/, formatPercent(timeoutRate)),
      fixLink: 'https://docs.prerender.io/article/62-why-do-my-pages-time-out-during-rendering',
    });
  }

  return healthIssues;
};

const formatPageRenderingTime = (t) => {
  const value = parseFloat(t) / 1000;
  return Number.isNaN(value) ? '- ' : value.toFixed(2);
};

const formatPageRenderImprovement = (dashboardStats) => {
  const improvement = dashboardStats.avgRenderingTime / dashboardStats.avgPrerenderedResponseTime;
  return Number.isNaN(improvement) ? '-' : parseInt(improvement, 10);
};

const showUpgradePlanIcon = (prerenderUser) => {
  const { isMeteredBilling, plan } = prerenderUser;
  return isMeteredBilling && !isEnterprisePlan(plan);
};

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthIssues: setHealthIssues(
        props.cm,
        props.prerenderUser,
        props.dashboardStats?.timeoutStats,
        props.showHasBillingInfoBadge,
        props.showHasBillingAddressBadge
      ),
    };
  }

  didReceiveAction({ action }) {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      clearOnboardingContent: doClearOnboardingContent,
      setShowOnboarding: doSetShowOnboarding,
    } = this.props;
    switch (action) {
      case 'modalCancelled':
        doCloseModal();
        break;
      case 'openInstallTokenModal':
        doOpenModal(
          'onboarding',
          <Onboarding.Modal
            prerenderUser={this.props.prerenderUser}
            onEvent={(onEventAction) => this.didReceiveAction(onEventAction)}
          />
        );
        break;
      case Onboarding.actions.MODAL_CANCALLED_ACTION:
      case Onboarding.actions.INTEGRATION_VERIFICATION_DONE:
        doClearOnboardingContent();
        doSetShowOnboarding(false);
        doCloseModal();
        break;
      default:
        console.log(`Unknown action: ${action}`); // TODO: handle error
    }
  }

  componentDidMount() {
    const {
      cm,
      language,
      getContent: doGetContent,
      cachedPagesSearch: doCachedPagesSearch,
      prerenderUser,
      crawlerActivitySearch: doCrawlerActivitySearch,
      dashboardStatsSearch: doDashboardStatsSearch,
    } = this.props;
    if (!cm) doGetContent({ component: cid, lang: language });
    doCachedPagesSearch('dashboard', {
      page: 0,
      pageSize: 6,
      sort: { name: 'last_refresh', asc: false },
      notnull: 'last_refresh',
    });
    doCrawlerActivitySearch('dashboard', { page: 0, pageSize: 6, sort: 'date' });
    doDashboardStatsSearch();

    if (prerenderUser.showOnboarding) {
      this.didReceiveAction({ action: 'openInstallTokenModal' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    // transfers an updated copy of props into local state for values in local state that are supposed to trigger actions in componentDidUpdate():
    const newHealth = setHealthIssues(
      props.cm,
      props.prerenderUser,
      props.dashboardStats?.timeoutStats,
      props.showHasBillingInfoBadge,
      props.showHasBillingAddressBadge
    );
    if (!props.prerenderUser.inProgress && !objectsAreEqual(newHealth, state.healthIssues))
      return { healthIssues: newHealth };
    return null;
  }

  getRecacheWaring() {
    const { cm, prerenderUser } = this.props;
    const { recachingDisabled, userPausedRecaching } = prerenderUser;
    return recachingDisabled
      ? { text: cm.refreshRate.disabled, theme: cm.refreshRate.disabledTheme }
      : userPausedRecaching
      ? { text: cm.refreshRate.paused, theme: cm.refreshRate.pausedTheme }
      : {};
  }

  render() {
    const { cm, prerenderUser, cachedPages, crawlerActivity, dashboardStats } = this.props;
    const { healthIssues } = this.state;
    if (!cm) return null;

    const { plan, inProgress } = prerenderUser;

    const recacheWarning = this.getRecacheWaring();
    const forLoop = [0, 1, 2, 3, 4, 5];
    const pageLimitHealthCheckIssue = healthIssues.find((issue) => issue.ref === 'Page Limit Reached');
    const updgradePlanHealthCheckIssue = healthIssues.find((issue) => issue.ref === 'Upgrade Plan');
    const timeoutHealthCheckIssue = healthIssues.find((issue) => issue.ref === 'Page timeouts');

    console.log({ cachedPages, crawlerActivity });

    return (
      <AdminTemplate>
        <Layout.Content>
          <div className="container-fluid">
            <PageHeader title="Overview" />

            {SHOW_TYPEFORM_BANNER && <TypeformBanner prerenderUser={prerenderUser} />}

            {!prerenderUser.trackingCodeInstalled && <Onboarding.Card />}

            <div className="row" style={{ marginBottom: 24 }}>
              <UsageStatisticsComponent cm={cm} />
            </div>

            <div className="row">
              {/* Average response times */}
              <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <Card
                  width="col-12"
                  title={cm.responseCheck.title}
                  badge={cm.responseCheck.badge.replace(/{{factor}}/, formatPageRenderImprovement(dashboardStats))}
                  badgeTheme="success"
                >
                  <ListGroup>
                    <ListGroupItem>
                      <div className="col-auto">
                        <i className={`fe fe-${cm.responseCheck.items[0].icon}`}></i>
                      </div>
                      <div className="col-8 ml-2">{cm.responseCheck.items[0].text}</div>
                      <div className="col-auto ml-auto">
                        {cm.responseCheck.items[0].time.replace(
                          /{{time}}/,
                          formatPageRenderingTime(dashboardStats.avgRenderingTime)
                        )}
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div className="col-auto">
                        <i className={`fe fe-${cm.responseCheck.items[1].icon}`}></i>
                      </div>
                      <div className="col-8 ml-2">{cm.responseCheck.items[1].text}</div>
                      <div className="col-auto ml-auto">
                        {cm.responseCheck.items[1].time.replace(
                          /{{time}}/,
                          formatPageRenderingTime(dashboardStats.avgPrerenderedResponseTime)
                        )}
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </div>

              {/* Recache queue lengths */}
              <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <Card
                  width="col-12"
                  title={cm.queueStats.title}
                  badge={recacheWarning.text}
                  badgeTheme="success"
                  btn={cm.queueStats.btnText}
                  btnTheme="outline-primary"
                  btnTarget="/recache-queue"
                >
                  <ListGroup>
                    <ListGroupItem>
                      <div className="col-auto">
                        <i className={`fe fe-${cm.queueStats.items[0].icon}`}></i>
                      </div>
                      <div className="col-8 ml-2">{cm.queueStats.items[0].text}</div>
                      <div className="col-auto ml-auto">
                        {`${cm.queueStats.items[0].amt.replace(/{{amt}}/, dashboardStats.recacheQueueSize)}${
                          dashboardStats.recacheQueueSize !== 1 ? 's' : ''
                        }`}
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div className="col-auto">
                        <i className={`fe fe-${cm.queueStats.items[1].icon}`}></i>
                      </div>
                      <div className="col-8 ml-2">{cm.queueStats.items[1].text}</div>
                      <div className="col-auto ml-auto">
                        {`${cm.queueStats.items[1].amt.replace(/{{amt}}/, dashboardStats.priorityQueueSize)}${
                          dashboardStats.priorityQueueSize !== 1 ? 's' : ''
                        }`}
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </div>

              <div
                className="col-12 col-md-12 col-lg-7 col-xl-8 test--health"
                style={{ display: healthIssues.length === 0 ? 'block' : 'block' }}
              >
                {/* Health check issues */}
                <Card
                  width="col-12"
                  title={cm.healthCheck.title}
                  badge={
                    healthIssues.length
                      ? `${cm.healthCheck.issues.replace(/{{issues}}/, healthIssues.length)}${
                          healthIssues.length > 1 ? 's' : ''
                        }`
                      : ''
                  }
                  badgeTheme="danger"
                >
                  <ListGroup>
                    {cm.healthCheck.items.map((item, index) => {
                      const passed = healthIssues.findIndex((i) => i.ref === item.ref) === -1;
                      if ((passed && item.trueText) || !passed) {
                        return (
                          <ListGroupItem
                            key={`lg${item.ref}`}
                            addClass={index === 0 ? 'pt-2' : index === 5 ? 'pb-1' : ''}
                            addStyle={index === healthIssues.length - 1 ? { paddingBottom: '9px' } : null}
                          >
                            <div className="col-auto">
                              <i
                                className={`fe fe-${
                                  passed ? 'check-circle text-success' : 'fe fe-alert-triangle text-danger'
                                }`}
                              ></i>
                            </div>
                            <div className="col-8 ml-2">{passed ? item.trueText : item.falseText}</div>
                            <div className="col-auto ml-auto mr-n3">
                              {!passed && item.ref !== 'trackingCodeInstalled' && (
                                <Link
                                  to={item.fixLink || '/billing/payment'}
                                  className="btn btn-sm btn-outline-primary"
                                >
                                  Fix
                                </Link>
                              )}
                              {!passed && item.ref === 'trackingCodeInstalled' && (
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => this.didReceiveAction({ action: 'openInstallTokenModal' })}
                                >
                                  Fix
                                </button>
                              )}
                            </div>
                          </ListGroupItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {pageLimitHealthCheckIssue && (
                      <ListGroupItem>
                        <div className="col-auto">
                          <i className={'fe fe-alert-triangle text-danger'}></i>
                        </div>
                        <div className="col-8 ml-2">{pageLimitHealthCheckIssue.falseText}</div>
                        <div className="col-auto ml-auto mr-n3">
                          <Link to="/billing/payment" className="btn btn-sm btn-outline-primary">
                            Fix
                          </Link>
                        </div>
                      </ListGroupItem>
                    )}
                    {updgradePlanHealthCheckIssue && (
                      <ListGroupItem>
                        <div className="col-auto">
                          <i className={'fe fe-alert-triangle text-info'}></i>
                        </div>
                        <div className="col-8 ml-2">{updgradePlanHealthCheckIssue.falseText}</div>
                        <div className="col-auto ml-auto mr-n3">
                          <Link to={updgradePlanHealthCheckIssue.fixLink} className="btn btn-sm btn-outline-primary">
                            View
                          </Link>
                        </div>
                      </ListGroupItem>
                    )}
                    {timeoutHealthCheckIssue && (
                      <ListGroupItem>
                        <div className="col-auto">
                          <i className={'fe fe-alert-triangle text-danger'}></i>
                        </div>
                        <div className="col-8 ml-2">{timeoutHealthCheckIssue.falseText}</div>
                        <div className="col-auto ml-auto mr-n3">
                          <a href={timeoutHealthCheckIssue.fixLink} target="_blank" rel="noreferrer">
                            <button className="btn btn-sm btn-outline-primary">Fix</button>
                          </a>
                        </div>
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </Card>
              </div>

              <div className="col-12 col-md-12 col-lg-5 col-xl-4 test--usage">
                {/* Monthly usage */}
                <PageLimitCard cm={cm} prerenderUser={prerenderUser} />

                {/* Current plan */}
                <DataPointCard
                  width="col-12"
                  title="Current Plan"
                  value={plan.name}
                  icon={!prerenderUser.isMeteredBilling ? cm.currPlan.icon : null}
                  isLoading={inProgress}
                  badge={prerenderUser.isMeteredBilling ? undefined : 'Retired'}
                  badgeTheme="warning"
                >
                  {showUpgradePlanIcon(prerenderUser) && (
                    <Link className="btn btn-sm btn-outline-primary mr-2" to="/billing/plans">
                      {cm.currPlan.btnText}
                    </Link>
                  )}
                </DataPointCard>
                <PlanPriceCards plan={plan} inProgress={inProgress} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-6 test-recent-cached">
                <Card
                  width="col-12"
                  title={cm.recentlyCached.title}
                  btn={cm.recentlyCached.btnText}
                  btnTheme="outline-primary"
                  btnTarget="/cache"
                >
                  {cachedPages && !cachedPages.inProgress && Array.isArray(cachedPages.pages) && (
                    <ListGroup>
                      {cachedPages.pages.map((page, index) => (
                        <ListGroupItem key={`lgcp${index}`}>
                          <div className="col-9 d-inline-block text-truncate">
                            <Link
                              style={{ color: 'unset' }}
                              to={{ pathname: page.url }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {removeTrailingString(page.url.replace('https://', '').replace('http://', ''), '/')}
                            </Link>
                          </div>
                          <div className="col-auto ml-auto text-muted">
                            <small>{getAgeString(new Date(page.last_refresh), 'ago', 'short', SHOW_NEVER)}</small>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                  {cachedPages && cachedPages.inProgress && (
                    <ListGroup>
                      {forLoop.map((i) => (
                        <ListGroupItem key={`lgcpph${i}`}>
                          <div className="col-11 d-inline-block">
                            <LoadingTextBar c="w-100" />
                          </div>
                          <div className="col-1">
                            <LoadingTextBar c="w-100" />
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </Card>
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-6 test--recent-visits">
                <Card
                  width="col-12"
                  title={cm.recentlyCrawled.title}
                  btn={cm.recentlyCrawled.btnText}
                  btnTheme="outline-primary"
                  btnTarget="/crawl-stats"
                >
                  {crawlerActivity && !crawlerActivity.inProgress && (
                    <ListGroup>
                      {crawlerActivity.requests.map((item, index) => (
                        <ListGroupItem key={`lgcp${index}`}>
                          <div className="col-auto">
                            <CrawlerIcon agent={item.user_agent} />
                          </div>
                          <div className="col-9 d-inline-block text-truncate">
                            <small>
                              {removeTrailingString(item.url.replace('https://', '').replace('http://', ''), '/')}
                            </small>
                          </div>
                          <div className="col-auto ml-auto text-muted">
                            <small>{getAgeString(new Date(item.time))}</small>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                  {crawlerActivity && crawlerActivity.inProgress && (
                    <ListGroup>
                      {forLoop.map((i) => (
                        <ListGroupItem key={`lxcpph${i}`}>
                          <div className="col-11 d-inline-block">
                            <LoadingTextBar c="w-100" />
                          </div>
                          <div className="col-1">
                            <LoadingTextBar c="w-100" />
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </Layout.Content>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    team: state.page.contentData.Team,
    ssrEntryUrl: state.page.ssrEntryUrl,
    language: state.page.language,
    prerenderUser: state.prerenderUser,
    cachedPages: state.cachedPages.dashboard,
    crawlerActivity: state.crawlerActivity.dashboard,
    dashboardStats: state.dashboardStats,
    showHasBillingAddressBadge: state.billingInfo.showHasBillingAddressBadge,
    showHasBillingInfoBadge: state.billingInfo.showHasBillingInfoBadge,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      getPrerenderUser,
      cachedPagesSearch,
      crawlerActivitySearch,
      dashboardStatsSearch,
      openModal,
      closeModal,
      setShowOnboarding,
      clearOnboardingContent: Onboarding.actions.clearContent,
    },
    dispatch
  );
}

function loadData(store) {
  const state = store.getState();
  return store.dispatch(getContent({ groups: ['dashboard', 'default'], lang: state.page.language }));
}

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(DashboardPage)),
  loadData,
};
