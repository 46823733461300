import { Flex, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const NavigationButtons = ({ onGoBack, onGoNext, disabledNext, disabledBack, nextBtnText = 'Continue', showIcon }) => {
  return (
    <Flex justify="space-between" style={{ marginTop: 'auto' }}>
      <Button type="default" onClick={onGoBack} disabled={disabledBack}>
        Back
      </Button>
      <Button type="primary" onClick={onGoNext} disabled={disabledNext}>
        {showIcon && <CheckOutlined style={{ paddingRight: 8 }} />}
        {nextBtnText}
      </Button>
    </Flex>
  );
};

export default NavigationButtons;
