import { restApi } from '../../api/restApiSlice';

const date = new Date();
const timezoneOffsetInMinutes = date.getTimezoneOffset();

export const dashboardApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsageOverview: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/usage-overview',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPagesDeliveredToCrawlers: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/page-delivered-user-agent/overview',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPagesDeliveredToCrawlersDaily: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/page-delivered-user-agent/daily',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPagesDeliveredByStatusCode: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/page-delivered-status-code/overview',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPagesDeliveredByStatusCodeDaily: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/page-delivered-status-code/daily',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPagesDeliveredDaily: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/page-delivered/daily',
        method: 'GET',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
  }),
});

export const {
  useGetUsageOverviewQuery,
  useGetPagesDeliveredToCrawlersQuery,
  useGetPagesDeliveredToCrawlersDailyQuery,
  useGetPagesDeliveredByStatusCodeQuery,
  useGetPagesDeliveredByStatusCodeDailyQuery,
  useGetPagesDeliveredDailyQuery,
} = dashboardApiSlice;
