import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent } from '../actions/pageActions';
import GettingStartedWrapper from '../components/GettingStarted/GettingStartedWrapper';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const GettingStartedPage = ({ cm, language, getContent: doGetContent }) => {
  useEffect(() => {
    if (!cm) {
      doGetContent({ component: 'FeatureRequestPage', lang: language });
    }
  }, [doGetContent, language, cm]);

  if (!cm) {
    return <h1>Loading...</h1>;
  }
  return (
    <AdminTemplate>
      <GettingStartedWrapper />
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  cm: state.page.contentData['FeatureRequestPage'],
  language: state.page.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContent,
    },
    dispatch
  );

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(requireAuth(GettingStartedPage)),
};
