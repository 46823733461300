import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '../auth';
import config from '../../assets/config';

export const restApiLegacy = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl.dataServer}/api`,
    prepareHeaders: (headers) => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('x-prerender-authorization', accessToken);
      }
      return headers;
    },
  }),
  tagTypes: [],
  reducerPath: 'legacyApi',
  endpoints: () => ({}),
});
