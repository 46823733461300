import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { Container } from '../../../components/CssFrameworkComponents';
import { useDispatch, useSelector } from 'react-redux';

import { initChargebee } from '../../../chargebee/chargebee';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import PackageCard from '../packages/PackageCard';
import { useGetPackagesQuery, useSetPackageSubscriptionMutation } from '../api/packageApiSlice';
import SpinningWheel from '../../../components/SpinningWheel';
import ChangePackageConfirmationModal from '../packages/ChangePackageConfirmationModal';
import {
  REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE,
  setDisableKeywordPackages,
} from '../../../utils/setDisableKeywordPackages';
import PackageCardKeyword100Plus from './PackageCardKeyword100Plus';
import { useLocation } from 'react-router-dom';

const BillingPackages = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const billingInfoContentData = useSelector((state) => state.page.contentData.BillingInfo);

  const user = usePrerenderUser();

  const [packagesList, setPackagesList] = useState([]);
  const [showChangePackageConfirmModal, setShowChangePackageConfirmModal] = useState(false);
  const { data, isFetching, refetch: reFetchPackages } = useGetPackagesQuery();
  const [setPackageSubscription, { isLoading: isMutatingPackage }] = useSetPackageSubscriptionMutation();
  const [pendingNewPackage, setPendingNewPackage] = useState(null);

  const { track } = useEvent();
  const { state } = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('utm_source');

  useEffect(() => {
    if (data) {
      const packages = setDisableKeywordPackages(data.data);
      setPackagesList(packages);
    }
  }, [data]);

  useEffect(() => {
    if (state?.scrollTo === 'keywords' && packagesList?.length > 0) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'instant' });
    }
  }, [packagesList]);

  const handleSelectPackage = useCallback(
    (newPackage) => {
      track('Package Selected', {
        selected_package: newPackage.name,
        selected_package_price: newPackage.costInCents / 100,
        source: source,
      });
      setPendingNewPackage(newPackage);
      setShowChangePackageConfirmModal(true);
    },
    [packagesList]
  );

  const handleCancelPackage = useCallback(() => {
    setShowChangePackageConfirmModal(false);
    setPendingNewPackage(null);
    track('Package Select Flow Cancelled', { selected_package: pendingNewPackage.name, source: source });
  }, [pendingNewPackage]);

  const isKeywordPackageUpgrade = (pendingNewPackageId) => {
    const activeKeywordPackage = packagesList.some(
      (p) => REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE.test(p.chargeBeeAddonId) && p.isActive
    );
    const isPendingPackageKeyword = REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE.test(pendingNewPackageId);
    return activeKeywordPackage && isPendingPackageKeyword;
  };

  const handleKeywordPackageUpgrade = async (pendingNewPackageId) => {
    const oldActiveKeywordPackage = packagesList.find(
      (p) => REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE.test(p.chargeBeeAddonId) && p.isActive
    );
    await setPackageSubscription({ packageId: oldActiveKeywordPackage.chargeBeeAddonId, enable: false }).unwrap();
    await setPackageSubscription({ packageId: pendingNewPackageId, enable: true }).unwrap();
  };

  const handleConfirmPackageChange = useCallback(
    async (packageId, enable) => {
      try {
        if (isKeywordPackageUpgrade(packageId)) {
          await handleKeywordPackageUpgrade(packageId);
        } else {
          await setPackageSubscription({ packageId, enable }).unwrap();
        }
        reFetchPackages();

        const pkg = packagesList.find((p) => p.chargeBeeAddonId === packageId);
        track('Package Purchased', {
          selected_package: pkg.name,
          selected_package_price: pkg.costInCents / 100,
        });
        dispatch(getPrerenderUser);
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: 'error',
          content: 'Unable to change package. Please contact support@prerender.io.',
        });
      }

      setShowChangePackageConfirmModal(false);
    },
    [dispatch, user, packagesList]
  );

  useEffect(() => {
    const url = window.location.href;
    initChargebee(billingInfoContentData.chargeBee.site, billingInfoContentData.chargeBee.domain, url);
  }, [billingInfoContentData.chargeBee]);

  if (isFetching) {
    return <SpinningWheel />;
  }

  return (
    <Container fluid addClass={'p-0'}>
      {contextHolder}
      {showChangePackageConfirmModal ? (
        <ChangePackageConfirmationModal
          isPending={isMutatingPackage}
          pendingNewPackage={pendingNewPackage}
          onClose={handleCancelPackage}
          onConfirmPackageChange={handleConfirmPackageChange}
        />
      ) : (
        <>
          <div className="position-relative">
            <div className="mt-5">
              <h3 className="">Packages</h3>
              <p className="text-base text-grey">
                Select a package that fits your needs. These are add-ons to your existing plan.
              </p>
              <div className="plans-grid">
                {packagesList.length > 0 &&
                  packagesList.map((item, index) => (
                    <PackageCard packageData={item} key={index} onSelectPackage={() => handleSelectPackage(item)} />
                  ))}
                <PackageCardKeyword100Plus />
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default BillingPackages;
