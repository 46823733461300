import { Flex, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { memo, useEffect, useMemo, useState } from 'react';
import { usePrerenderUser } from '../../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../../events/hooks/useEvent';
import { formatIntegration } from '../../../../../../utils/formatIntegration';

export const defaultIntegrationMethods = [
  'cloudflare',
  'fastly',
  'node',
  'nginx',
  'apache',
  'magento-2',
  'ruby',
  'NextJS',
  'iis',
  'other',
];
const ONBOARDING_IMAGE_PATH = '/assets/img/onboarding/';
const GAP = 8;

const { Text } = Typography;

const Integrations = ({ selectedMethod, setSelectedMethod, suggestedIntegrations }) => {
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  const integrationMethods = useMemo(() => {
    return Array.from(new Set([...suggestedIntegrations, ...defaultIntegrationMethods]));
  }, [suggestedIntegrations]);

  useEffect(() => {
    setSelectedMethod(integrationMethods[0]);
    track('Integration Wizard: Method Preselected', {
      integration_method: integrationMethods[0],
      subscription_plan: prerenderUser.chargebeePlanId,
      is_suggested_method: suggestedIntegrations?.includes(integrationMethods[0]),
    });
  }, [integrationMethods, prerenderUser]);

  return (
    <Flex vertical gap={GAP}>
      {suggestedIntegrations.length !== 0 && (
        <Flex vertical gap={GAP}>
          <Text strong>Recommended for you</Text>
          {suggestedIntegrations.map((integration) => (
            <div key={integration} className="h-100">
              <button
                type="button"
                className="btn w-100"
                style={{ background: selectedMethod === integration ? '#E8FAE5' : null, borderColor: '#2DA01D' }}
                onClick={() => {
                  track('Integration Method Selected', {
                    integration_method: integration,
                    subscription_plan: prerenderUser.chargebeePlanId,
                    is_suggested: suggestedIntegrations.includes(integration),
                  });
                  setSelectedMethod(integration);
                }}
              >
                <Flex>
                  {selectedMethod === integration && (
                    <div style={{ position: 'absolute', color: '#2DA01D', right: 28 }}>
                      <CheckCircleFilled />
                    </div>
                  )}
                  <img
                    src={`${ONBOARDING_IMAGE_PATH + integration.toLowerCase()}.svg`}
                    width="24px"
                    height="24px"
                    alt={integration}
                    style={{ marginRight: 8 }}
                  />
                  <Text>{formatIntegration(integration)}</Text>
                </Flex>
              </button>
            </div>
          ))}
          <div style={{ marginTop: 14 }}>
            <Text strong>Available Integrations</Text>
          </div>
        </Flex>
      )}
      {integrationMethods.map(
        (integration) =>
          !suggestedIntegrations.includes(integration) && (
            <div key={integration} className="h-100">
              <button
                type="button"
                className="btn w-100"
                style={{ background: selectedMethod === integration ? '#E8FAE5' : null, borderColor: '#2DA01D' }}
                onClick={() => {
                  track('Integration Method Selected', {
                    integration_method: integration,
                    subscription_plan: prerenderUser.chargebeePlanId,
                    is_suggested: suggestedIntegrations.includes(integration),
                  });
                  setSelectedMethod(integration);
                }}
              >
                <Flex>
                  {selectedMethod === integration && (
                    <div style={{ position: 'absolute', color: '#2DA01D', right: 28 }}>
                      <CheckCircleFilled />
                    </div>
                  )}
                  <img
                    src={`${ONBOARDING_IMAGE_PATH + integration.toLowerCase()}.svg`}
                    width="24px"
                    height="24px"
                    alt={integration}
                    style={{ marginRight: 8 }}
                  />
                  <Text>{formatIntegration(integration)}</Text>
                </Flex>
              </button>
            </div>
          )
      )}
    </Flex>
  );
};

export default memo(Integrations);
