import { update } from 'lodash';
import { restApi } from '../../api/restApiSlice';

const domainStatisticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainStatistics: builder.query({
      query: (params) => ({ url: `/domain-statistics`, method: 'GET', params }),
      providesTags: ['domainStatistics'],
    }),
  }),
});

export const { useGetDomainStatisticsQuery, useLazyGetDomainStatisticsQuery } = domainStatisticsApiSlice;

const domainDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainDetails: builder.query({
      query: (domain) => ({ url: `/domains/${domain}/config`, method: 'GET' }),
      providesTags: ['domainDetails'],
    }),
    updateDomainDetails: builder.mutation({
      query: ({ domain, ...body }) => ({ url: `/domains/${domain}/config`, method: 'PATCH', body }),
      invalidatesTags: ['domainDetails'],
    }),
  }),
});

export const { useGetDomainDetailsQuery, useUpdateDomainDetailsMutation } = domainDetailsApiSlice;
