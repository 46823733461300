import { Typography, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

const listData = [
  {
    key: 'send-invite',
    title: 'Send an invite',
    content: 'Invite your friends or anyone who can benefit from Prerender via email.',
  },
  {
    key: 'successful-referral',
    title: 'Successful Referral',
    content:
      'For a referral to be successful, the new user must sign up and become a paying customer using your email link.',
  },
  {
    key: 'earn-credits',
    title: 'Earn Credits',
    content: "Once the new user completes these steps, you'll earn $45 in credit.",
  },
];

const InformationBlock = () => {
  const { token } = theme.useToken();

  return (
    <>
      <Title level={5} style={{ marginBottom: token.marginXL }}>
        How Does It Work?
      </Title>
      <ol>
        {listData.map((item) => (
          <li key={item.key} style={{ marginBottom: token.marginMD }}>
            <div>
              <Text strong>{item.title}</Text>
              <br />
              <Paragraph>{item.content}</Paragraph>
            </div>
          </li>
        ))}
      </ol>

      <Title level={5} style={{ marginTop: token.marginXL }}>
        Not An Invite To Your Workspace
      </Title>
      <Paragraph style={{ margin: 0 }}>
        Referring someone does not invite them to join your account or share your content. If you'd like to collaborate
        with someone, you can <Link to="/security/team-members">add them directly to your team</Link>.
      </Paragraph>
    </>
  );
};

export default InformationBlock;
