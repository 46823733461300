import { Result, Typography, Button, theme } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import TextLoadingAnimation from './TextLoadingAnimation';
import './ScreenshotFrame.css';

function ErrorMsg() {
  return (
    <>
      <Typography.Paragraph>We're fine-tuning your domain for the best performance.</Typography.Paragraph>
      <Typography.Paragraph>Next up, integrating Prerender!</Typography.Paragraph>
    </>
  );
}

const ScreenshotFrame = ({ loading, error, homepageScreenshot, showSubtitle, onGoNext }) => {
  const { token } = theme.useToken();
  const screenshotContainerContent = (loading, error) => {
    if (loading) {
      return <TextLoadingAnimation />;
    } else if (error.isError || !homepageScreenshot) {
      return (
        <Result
          title="Preview unavailable"
          subTitle={showSubtitle && <ErrorMsg />}
          icon={<InfoCircleFilled style={{ color: token.colorTextDisabled }} />}
          extra={
            <Button type="primary" onClick={onGoNext}>
              Continue
            </Button>
          }
        />
      );
    } else {
      return (
        <img
          src={`data:image/png;base64, ${homepageScreenshot}`}
          alt="home page screenshot"
          className="screenshot_image"
        />
      );
    }
  };

  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <div className="screenshot-container">{screenshotContainerContent(loading, error)}</div>
      <img src="/assets/img/integration_wizard_laptop_frame.png" className="screenshot-frame" />
    </div>
  );
};

export default ScreenshotFrame;
