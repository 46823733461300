import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

function toFixedIfNecessary(value) {
  if (!value) {
    return undefined;
  }

  return +parseFloat(value).toFixed(2);
}

export function toFixedDuration(value) {
  if (!value) {
    return undefined;
  }

  const duration = dayjs.duration(Number(value)).asSeconds();

  return toFixedIfNecessary(duration);
}
