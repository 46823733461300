import SearchBarV2 from './SearchBarV2';
import React from 'react';
import { Button, Col, Flex, Row } from 'antd';
import ExportButton from './ExportButton';
import { usePrerenderUser } from '../hooks/usePrerenderUser';
import { isFreePlan } from '../chargebee/chargebee';

const SearchPanel = ({
  searchBarId,
  initialSearchQuery,
  setState,
  performQuerySearch,
  performQuerySearchAnyway,
  onSearchConditionChanged,
  onUpdateTable,
  onChangeTable,
  setFilteredInfo,
  clearFilters,
  domains,
}) => {
  const user = usePrerenderUser();
  const isFreeUser = isFreePlan(user.plan);

  const clearTableFilters = () => {
    setFilteredInfo({});
    clearFilters();
  };

  return (
    <Row>
      <Col flex="auto" offset={domains.domains.length > 1 && !isFreeUser ? 1 : 0} style={{ marginLeft: 0 }}>
        <SearchBarV2
          id={searchBarId}
          initialValue={initialSearchQuery}
          onTypingStart={() => setState({ customMessage: null })}
          onTypingEnd={performQuerySearch}
          onEnterPressed={(query) => performQuerySearchAnyway(query)}
          onSearchConditionChanged={(condition) => onSearchConditionChanged(condition)}
          placeholder="Please enter at least 3 characters to start your search"
        />
      </Col>
      <Col flex="210px" className="m-auto">
        <Flex justify="end">
          <Button className="ml-2" onClick={onUpdateTable} size="small">
            <i className="fe fe-rotate-cw"></i>
          </Button>
          <Button className="ml-2" onClick={clearTableFilters} size="small">
            Clear filters
          </Button>
          <ExportButton isDisabled={isFreeUser} onClick={() => onChangeTable({ action: 'openExportModal' })} />
        </Flex>
      </Col>
    </Row>
  );
};

export default SearchPanel;
