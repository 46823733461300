import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import React from 'react';

export default function SeoScoreComponent({ score }) {
  if (score === null) {
    return (
      <Tooltip title={'Will be scored on the next rendering'} placement="top">
        <Tag icon={<ClockCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="default">
          Not Scored
        </Tag>
      </Tooltip>
    );
  } else if (score < 0) {
    return (
      <Tooltip title={'Very Low SEO'} placement="top">
        <Tag icon={<CloseCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="error">
          {score}
        </Tag>
      </Tooltip>
    );
  } else if (score < 30) {
    return (
      <Tooltip title={'Not Optimized'} placement="top">
        <Tag icon={<ExclamationCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="processing">
          {score}
        </Tag>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={'Healthy'} placement="top">
        <Tag icon={<CheckCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="success">
          {score}
        </Tag>
      </Tooltip>
    );
  }
}
