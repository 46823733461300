import { memo, useCallback, useState } from 'react';
import { Button, Modal } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { useInviteUserMutation } from '../api/appUserApiSlice';
import { useEvent } from '../events/hooks/useEvent';
import InvitationForm from './InvitationForm';

const InviteTeamMember = ({ style, selectRoles, source }) => {
  const [invitation, setInvitationResult] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const chargebeePlanId = useSelector((state) => state.prerenderUser.chargebeePlanId);
  const [inviteUser] = useInviteUserMutation();
  const { track } = useEvent();

  const doInviteUserAndRefresh = useCallback(
    async (userData) => {
      setInvitationResult({});
      return inviteUser(userData)
        .then(({ error: inviteUserError }) => {
          track(`User invited`, {
            subscription_plan: chargebeePlanId,
            source,
          });
          const internalError = { error: 'An unexpected error occured, please try again' };
          const invitationResult = inviteUserError
            ? inviteUserError.data
              ? inviteUserError.data
              : internalError
            : userData;

          setInvitationResult(invitationResult);
        })
        .catch((e) => console.error(e));
    },
    [inviteUser]
  );

  return (
    <div style={style}>
      <Button
        type="default"
        size="small"
        onClick={() => {
          setModalOpen(true);
          track(`Invite a team member button clicked`, { subscription_plan: chargebeePlanId, source });
        }}
      >
        <UserAddOutlined style={{ paddingRight: 8 }} />
        Invite a team member
      </Button>
      <Modal title="Invite Colleagues" open={isModalOpen} onCancel={() => setModalOpen(false)} footer={null}>
        <InvitationForm invitation={invitation} fullWidth onSubmit={doInviteUserAndRefresh} selectRoles={selectRoles} />
      </Modal>
    </div>
  );
};

export default memo(InviteTeamMember);
