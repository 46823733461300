import React from 'react';
import { Switch, Route, useHistory, useLocation, useRouteMatch, Redirect } from 'react-router-dom';
import { Typography, Tabs } from 'antd';

import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import { useEvent } from '../features/events/hooks/useEvent';
import { PreRenderTokenPage, TeamMembersPage, AuditLogPage } from '../features/SecurityAndAccess';
import { isSSOSite } from '../features/auth/keycloakAuth';

const KEY_LABEL_MAP = {
  'prerender-token': 'Prerender Token',
  'team-members': 'Team Members',
  'audit-log': 'Audit Log',
};

const TABS = [
  {
    key: 'prerender-token',
    label: KEY_LABEL_MAP['prerender-token'],
    component: PreRenderTokenPage,
  },
  {
    key: 'team-members',
    label: KEY_LABEL_MAP['team-members'],
    component: TeamMembersPage,
    hide: isSSOSite(),
  },
  {
    key: 'audit-log',
    label: KEY_LABEL_MAP['audit-log'],
    component: AuditLogPage,
  },
].filter((tab) => !tab.hide);

function SecurityAndAccess() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { track } = useEvent();

  const activeTab = React.useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  const onTabClick = (tabKey) => {
    history.push(`/security/${tabKey}`);
    track(`${KEY_LABEL_MAP[tabKey]} tab viewed`);
  };

  return (
    <AdminTemplate>
      <Typography.Title level={2}>Security and Access</Typography.Title>
      <Typography.Paragraph type="secondary">
        This section allows you to manage security related configurations, generate access token, invite team members
        and more.
      </Typography.Paragraph>

      <Tabs activeKey={activeTab} items={TABS} onTabClick={onTabClick} />

      <Switch>
        {TABS.map(({ key, component: Component }) => (
          <Route path={`${url}/${key}`} key={key}>
            <Component />
          </Route>
        ))}
        <Route path={url}>
          <Redirect to={`${url}/${TABS[0].key}`} />
        </Route>
      </Switch>
    </AdminTemplate>
  );
}

export default { component: requireAuth(SecurityAndAccess) };
