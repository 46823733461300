import { Link } from 'react-router-dom';

const UncaughtError = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <h1>Uncaught Error</h1>
      <img src="/assets/img/error-page.png" style={{ maxWidth: 800 }} className="w-100 h-auto" />
      <p>Oops, this is embarrasing, we've received an error</p>
      <Link to="/">Back to dashboard</Link>
    </div>
  );
};

export default {
  component: UncaughtError,
};
