import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, setLastErrorCode } from '../../../actions/pageActions';
import ErrorLoader from '../../../components/ErrorLoader';
import { Alert, Modal, Form, Input } from 'antd';
import DeviceTypeSelector from '../../../components/DeviceTypeSelector';
import { useAddUrlsMutation } from '../../urls/api/urlsApiSlice';
import { isValidUrl } from '../../validators/url';

const cid = 'CachedPagesAdd';

export default function CachedPagesAdd({ onEvent, mobileAdaptive, showModal, onModalClose }) {
  const defaultAdaptiveTypes = mobileAdaptive ? ['desktop', 'mobile'] : ['desktop'];
  const [adaptiveTypes, setAdaptiveTypes] = useState(defaultAdaptiveTypes);
  const [customMessage, setCustomMessage] = useState(null);
  const lastErrorCode = useSelector((state) => state.page.lastErrorCode);
  const apiErrTxt = useSelector((state) => state.page.contentData.apiError);
  const cm = useSelector((state) => state.page.contentData[cid]);
  const [urlsAlreadyExists, setUrlsAlreadyExists] = useState([]);
  const dispatch = useDispatch();
  const [addUrls] = useAddUrlsMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!cm) dispatch(getContent({ component: cid, lang: language }));
    return () => {
      dispatch(setLastErrorCode(''));
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ urls: '' });
    setUrlsAlreadyExists([]);
  }, [showModal]);

  const handleModalClose = () => {
    setCustomMessage(null);
    onModalClose();
  };

  const saveUrlList = async (urlList, adaptiveTypes) => {
    const urls = urlList.flatMap((url) =>
      adaptiveTypes.map((adaptiveType) => ({
        url,
        adaptiveType,
      }))
    );
    try {
      const addUrlsResult = (await addUrls(urls).unwrap()).data.result;
      const urlsAlreadyExistsInAddedResult = Array.isArray(addUrlsResult)
        ? addUrlsResult.filter((r) => r.error?.urlAlreadyExists).map((e) => e.item)
        : [];

      setUrlsAlreadyExists(urlsAlreadyExistsInAddedResult);
      if (urlsAlreadyExistsInAddedResult.length === 0) {
        onEvent({ action: 'urlsAdded', payload: addUrlsResult });
        handleModalClose();
      }
    } catch (e) {
      console.error('Cannot add urls', e);
      setCustomMessage({ text: 'Unknown error occurred. Please try again later.' });
    }
  };

  const saveForm = ({ urls }) => {
    if (!urls) return;

    const urlList = urls.split('\n').filter((u) => !!u);
    const invalidLine = urlList.findIndex((u) => !isValidUrl(u));

    // Set the error message
    setCustomMessage(
      urlList.length > 100
        ? { text: cm.list.errMax.replace('{{amt}}', urlList.length) }
        : invalidLine !== -1
        ? { text: cm.list.errSyntax.replace('{{line}}', invalidLine + 1) }
        : ''
    );

    if (urlList.length > 0 && invalidLine === -1) {
      saveUrlList(urlList, adaptiveTypes);
    }
  };

  const renderUrlsAlreadyExistsBox = () => {
    const MAX_URLS_TO_SHOW = 10;
    const urlsAlreadyExistsHead = urlsAlreadyExists.slice(0, MAX_URLS_TO_SHOW);
    if (urlsAlreadyExists.length > MAX_URLS_TO_SHOW) {
      urlsAlreadyExistsHead.push('...');
    }
    return (
      <Alert
        type="warning"
        message="URLs already exist!"
        description={
          <>
            {urlsAlreadyExistsHead.map((u, i) => (
              <div key={`url-already-exists-${i.url}`} className="text-truncate">
                {u.url} - {u.adaptiveType}
              </div>
            ))}
          </>
        }
      />
    );
  };

  if (!cm) return null;

  return (
    <>
      <Modal
        closable
        open={showModal}
        title="Add URLs"
        okText="Add"
        cancelText="Cancel"
        onCancel={() => {
          handleModalClose();
          setUrlsAlreadyExists([]);
        }}
        okButtonProps={{ htmlType: 'submit', form: 'addCachedPage' }}
        styles={{ footer: { display: 'flex', justifyContent: 'space-between' } }}
      >
        <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
          <Alert
            showIcon
            type="error"
            message={apiErrTxt[lastErrorCode] ? apiErrTxt[lastErrorCode] : customMessage?.text}
          />
        </ErrorLoader>
        {urlsAlreadyExists.length ? <div style={{ marginTop: 8 }}>{renderUrlsAlreadyExistsBox()}</div> : null}
        <Form
          id="addCachedPage"
          form={form}
          layout="vertical"
          onFinish={(values) => {
            saveForm(values);
          }}
        >
          <p style={{ marginTop: 16, marginBottom: 16 }}>Enter a list of up to 100 URLs</p>
          <p style={{ marginBottom: 4 }}>Device type</p>
          <DeviceTypeSelector mobileAdaptive={mobileAdaptive} setAdaptiveTypes={setAdaptiveTypes} />
          <Form.Item name="urls">
            <Input.TextArea
              rows={10}
              placeholder="https://example.com/index.html&#10;https://example.com/about&#10;..."
              style={{ marginTop: 16 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
