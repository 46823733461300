import { Route, Switch } from 'react-router-dom';

import requireAuth from '../components/hocs/requireAuth';
import { DomainsListPage, DomainDetailsPage } from '../features/RankTracker';

function RankTracker() {
  return (
    <Switch>
      <Route path="/rank-tracker" exact component={DomainsListPage} />
      <Route path="/rank-tracker/:domainId" exact component={DomainDetailsPage} />
    </Switch>
  );
}

export default requireAuth(RankTracker);
