import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { Container } from '../../../components/CssFrameworkComponents';
import { useDispatch, useSelector } from 'react-redux';

import { BillingChangePlanConfirmModal } from './BillingChangePlanConfirmModal';
import BillingPlansCard from './BillingPlansCard';
import XIcon from '../../../assets/icons/x-icon.svg';
import { initChargebee } from '../../../chargebee/chargebee';
import { useChangePlanMutation } from '../api/billingApiSlice';
import { useGetPlansQuery } from '../api/billingApiSlice';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import SpinningWheel from '../../../components/SpinningWheel';
import { UserSnap } from '../../CustomerSupport';
import DowngradePlanDisclaimer, { shouldDisplayDowngradePlanDisclaimer } from './DowngradePlanDisclaimer';

const BillingPlans = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [showPlansOverlay, setShowPlansOverlay] = useState(false);

  const [pendingNewPlan, setPendingNewPlan] = useState(null);
  const [showChangePlanConfirmModal, setShowChangePlanConfirmModal] = useState(false);
  const billingInfoContentData = useSelector((state) => state.page.contentData.BillingInfo);
  const [downgradedPlan, setDowngradedPlan] = useState(null);

  const user = usePrerenderUser();
  const currentRenders = React.useMemo(
    () => Math.floor(user.numPagesCached * (30 / user.cacheFreshness)),
    [user.numPagesCached, user.cacheFreshness]
  );
  const isUserOnCustomPlan = typeof user.customPrice === 'number';

  const {
    data: plansListResponse,
    isLoading: isLoadingPlans,
    isError: isErrorLoadingPlans,
    refetch: reFetchPlans,
  } = useGetPlansQuery(null, { refetchOnMountOrArgChange: true });
  const [changePlan, { isLoading: isUpdatingPlans }] = useChangePlanMutation();

  const { track } = useEvent();
  const { openChurnQuestionnaire } = UserSnap.useUserSnap();

  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('utm_source');

  const plansList = plansListResponse?.data;
  const currentPlan = plansList?.find((plansEntry) => plansEntry.current);

  const onConfirmDowngrade = () => {
    setDowngradedPlan(null);
    handleSelectPlan(downgradedPlan);
  };

  const onCancelDowngrade = () => setDowngradedPlan(null);

  const onPlanClicked = (newPlan) => {
    if (shouldDisplayDowngradePlanDisclaimer(currentRenders, newPlan)) {
      setDowngradedPlan(newPlan);
    } else {
      handleSelectPlan(newPlan);
    }
  };

  const handleSelectPlan = useCallback(
    (newPlan) => {
      track('Subscription Plan Selected', {
        subscription_plan: currentPlan.name,
        selected_subscription_plan: newPlan.name,
        selected_subscription_price: newPlan.costInCents / 100,
        source: source,
      });

      if (isUserOnCustomPlan) {
        setShowPlansOverlay(true);
      } else {
        setPendingNewPlan(newPlan);
        setShowChangePlanConfirmModal(true);
      }
    },
    [isUserOnCustomPlan, plansList, currentPlan]
  );

  const handleConfirmPlanChange = useCallback(
    async (planId) => {
      if (planId === 'basic-001') {
        openChurnQuestionnaire();
      }
      try {
        await changePlan({ planId }).unwrap();
        reFetchPlans();
        dispatch(getPrerenderUser);
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: 'error',
          content: 'Unable to change plan. Please contact support@prerender.io.',
        });
      }
      setShowChangePlanConfirmModal(false);
    },
    [dispatch, user, openChurnQuestionnaire]
  );

  const handleCancelPlanChange = useCallback(() => {
    setShowChangePlanConfirmModal(false);
    setPendingNewPlan(null);
    track('Subscription Update Flow Cancelled', { subscription_plan: currentPlan.name, source: source });
  }, [currentPlan]);

  useEffect(() => {
    const url = window.location.href;
    initChargebee(billingInfoContentData.chargeBee.site, billingInfoContentData.chargeBee.domain, url);
  }, [billingInfoContentData.chargeBee]);

  if (!plansList) {
    return <SpinningWheel matchParent />;
  }

  return (
    <Container fluid addClass={'p-0'}>
      <DowngradePlanDisclaimer
        downgradedPlan={downgradedPlan}
        onConfirm={onConfirmDowngrade}
        onCancel={onCancelDowngrade}
      />
      {contextHolder}
      {showChangePlanConfirmModal && pendingNewPlan ? (
        <>
          <h3 className="">Confirm your new plan</h3>
          {isLoadingPlans || isUpdatingPlans ? (
            <div
              style={{ height: '300px', maxWidth: '600px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          ) : (
            <BillingChangePlanConfirmModal
              pendingNewPlan={pendingNewPlan}
              currentPlanIndex={currentPlan.index}
              onConfirmPlanChange={handleConfirmPlanChange}
              onClose={handleCancelPlanChange}
            />
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h3 className="">Prerender Plans</h3>
            {(isLoadingPlans || isUpdatingPlans) && (
              <div className="ml-3 spinner-border spinner-border-sm text-primary" role="status"></div>
            )}
          </div>
          <p className="text-base text-grey">Select an account plan that fits your needs.</p>
          <div className="position-relative">
            {showPlansOverlay && (
              <div className="plans-overlay">
                <div className="plans-overlay-content">
                  <div className="d-flex justify-content-end">
                    <button className="btn p-0" onClick={() => setShowPlansOverlay(false)}>
                      <img src={XIcon} />
                    </button>
                  </div>
                  <p className="font-weight-bold text-lg">You're on a Custom plan!</p>
                  <span>
                    Your account runs on a custom plan, so you are not able to change your plan via this page. If you
                    want to re-negotiate your plan, please send us an email at
                  </span>{' '}
                  <a className="font-weight-bold" href="mailto:billing@prerender.io">
                    billing@prerender.io
                  </a>
                  <span>.</span>
                </div>
              </div>
            )}
            {isErrorLoadingPlans ? (
              <p style={{ color: 'red' }}>
                An unexpected error occurred when retrieving the plans. Please try again by reloading the page.
              </p>
            ) : (
              <div className="plans-grid">
                {plansList?.length > 0 &&
                  plansList
                    .filter((plan) => plan.available)
                    .map((plan) => (
                      <BillingPlansCard
                        key={plan.name}
                        plan={plan}
                        currentPlanIndex={currentPlan ? currentPlan.index : -1}
                        onPlanClicked={onPlanClicked}
                      />
                    ))}
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default BillingPlans;
