import { Card, Flex, Select, Table, DatePicker, message, Typography, ConfigProvider, Empty } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useGetEventsHistoryQuery } from '../oversightApiSlice';
import { useState } from 'react';
import { getDisplayRange } from '../../../utils/getDisplayRange';
import dayjs from 'dayjs';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const PAGE_SIZE = 50;

const columns = [
  {
    title: 'Recorded At',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    width: '25%',
  },
  {
    title: 'Event Type',
    dataIndex: 'eventType',
    key: 'eventType',
    width: '25%',
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    width: '50%',
  },
];

const mapToApiPayload = (params) => {
  return {
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    from: params.from,
    to: params.to,
    events: params.eventType,
  };
};

const EventHistoryTab = ({ eventTypes, eventTypesError, loadingEventTypes }) => {
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'created_at',
      order: 'descend',
    },
  };
  const [tableParams, setTableParams] = useState(initialParams);
  const {
    data: events,
    error: eventsError,
    isFetching: isFetchingEvents,
  } = useGetEventsHistoryQuery(mapToApiPayload(tableParams), {
    refetchOnMountOrArgChange: true,
  });
  const totalCount = events?.totalCount || 0;

  const eventsList = eventTypes?.data?.map((event) => event.name) || [];

  const tableData = useMemo(() => {
    return events?.data?.map(
      (event, index) =>
        ({
          key: index,
          eventType: event.type,
          created_at: dayjs(event.created_at).format('YYYY-MM-DD HH:mm:ss'),
          details: event.details,
        } || [])
    );
  }, [events]);

  useEffect(() => {
    if (eventsError) {
      message.error(eventsError.data.error);
    }
    if (eventTypesError) {
      message.error(eventTypesError.data.error);
    }
  }, [eventsError?.data?.error, eventTypesError?.data?.error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter,
    });
  };

  const handleSelectChange = (value) => {
    setTableParams((oldParams) => ({
      ...oldParams,
      pagination: { ...oldParams.pagination, current: 1 },
      eventType: eventTypes?.data?.find((event) => event.name === value)?.id,
    }));
  };

  const handleRangeChange = (value) => {
    const [start, end] = value ?? [];
    const from = start ? dayjs(start).toISOString() : undefined;
    const to = end ? dayjs(end).toISOString() : undefined;

    setTableParams((oldParams) => ({
      ...oldParams,
      pagination: { ...oldParams.pagination, current: 1 },
      from,
      to,
    }));
  };

  const displayRange = getDisplayRange(tableParams.pagination, totalCount);

  return (
    <div>
      <Card title="Alerts">
        <Flex justify="space-between" style={{ marginBottom: 20 }}>
          <Flex align="baseline" gap="large" style={{ marginRight: 24 }}>
            <Flex align="baseline" gap="small">
              <span>Event Type</span>
              <Select
                allowClear
                onChange={handleSelectChange}
                style={{ width: 200 }}
                placeholder="Select Event type"
                loading={loadingEventTypes}
                options={eventsList.map((event) => ({ label: event, value: event })) || []}
              />
            </Flex>
            <Flex align="baseline" gap="small">
              <span>Timeframe</span>
              <RangePicker onChange={handleRangeChange} showTime />
            </Flex>
          </Flex>
          <Paragraph>
            Displaying {displayRange} events from total of {totalCount} records
          </Paragraph>
        </Flex>
        <ConfigProvider
          renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No events found" />}
        >
          <Table
            columns={columns}
            loading={isFetchingEvents}
            dataSource={tableData}
            onChange={handleTableChange}
            pagination={{
              current: tableParams.pagination.current,
              pageSize: tableParams.pagination.pageSize,
              total: totalCount,
            }}
            bordered
          />
        </ConfigProvider>
      </Card>
    </div>
  );
};

export default EventHistoryTab;
