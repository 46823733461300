import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent, setLastErrorCode } from '../actions/pageActions';
import { Alert, Input, Form, Modal, Radio, Space, Button, Flex } from 'antd';

const regexPattern = /^[a-zA-Z0-9_-]*$/;

const UrlParamEditor = ({
  lastErrorCode,
  apiErrTxt,
  urlParam,
  urlParams,
  setLastErrorCode,
  onEvent,
  showModal,
  onModalClose,
}) => {
  const [activeRule, setActiveRule] = useState('ignore');
  const [form] = Form.useForm();
  const viewMode = urlParam && urlParam.id ? 'edit' : 'add';
  const errorMessage = 'Please only enter the name of the query parameter that needs to be ignored e.g. ‘page’';

  useEffect(() => {
    return () => {
      if (lastErrorCode) {
        setLastErrorCode('');
      }
    };
  }, []);

  useEffect(() => {
    if (urlParam) {
      const activeRule = urlParam.id ? (urlParam.ignore ? 'ignore' : 'allow') : 'ignore';
      setActiveRule(activeRule);
      form.setFieldsValue({ param: urlParam.value, rule: activeRule });
    }
  }, [urlParam, form]);

  const onClose = () => {
    onModalClose();
    if (lastErrorCode) {
      setLastErrorCode('');
    }
  };

  const onModalSubmit = () => {
    saveForm(form.getFieldsValue());
    onModalClose();
  };

  const saveForm = ({ param = '' }) => {
    const { id } = urlParam;

    if (viewMode === 'edit') {
      onEvent({
        action: 'updateRule',
        payload: [{ id, value: param, ignore: activeRule === 'ignore' }],
      });
    } else {
      onEvent({
        action: 'setUrlParam',
        payload: { id, value: param, ignore: activeRule === 'ignore' },
      });
    }
  };

  return (
    <>
      <Modal
        closable
        open={showModal}
        title="Add URL Parameter"
        okText="Save"
        cancelText="Cancel"
        onCancel={onClose}
        okButtonProps={{ htmlType: 'submit', form: 'addUrlParam' }}
        styles={{ footer: { display: 'flex', justifyContent: 'space-between' } }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <Flex gap="small">
              {viewMode === 'edit' && (
                <Button
                  onClick={() => {
                    onEvent({ action: 'deleteParams', payload: [urlParam.id] });
                    onModalClose();
                  }}
                  disabled={urlParams.inProgress}
                  danger
                >
                  Delete from list
                </Button>
              )}
              <OkBtn />
            </Flex>
          </>
        )}
      >
        {apiErrTxt[lastErrorCode] && <Alert showIcon type="error" message={apiErrTxt[lastErrorCode]} />}
        {showModal && (
          <Form
            name="addUrlParam"
            form={form}
            initialValues={{ param: urlParam.value, rule: activeRule }}
            style={{ marginTop: 12 }}
            layout="vertical"
            onFinish={onModalSubmit}
          >
            <Form.Item
              style={{ marginTop: 4 }}
              name="param"
              label="Parameter string"
              rules={[
                {
                  required: true,
                  message: 'Please enter a URL query parameter',
                },
                {
                  max: 50,
                  message: errorMessage,
                },
                {
                  pattern: regexPattern,
                  message: errorMessage,
                },
              ]}
            >
              <Input id="param" placeholder="Enter a URL query parameter" autoFocus />
            </Form.Item>
            <Form.Item style={{ marginTop: 4 }} name="rule" label="Caching rule">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="ignore" checked={activeRule === 'ignore'} onChange={() => setActiveRule('ignore')}>
                    Ignore when Caching
                  </Radio>
                  <Radio value="allow" checked={activeRule === 'allow'} onChange={() => setActiveRule('allow')}>
                    Use when Caching
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.page.language,
    apiErrTxt: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
    urlParams: state.urlParams,
    prerenderUser: state.prerenderUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getContent, setLastErrorCode }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlParamEditor);
