import { Button, message } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import DisableRecacheCard from '../disableRecacheCard/disableRecacheCard';
import ForceHTTPSCard from '../forceHTTPSCard/ForceHTTPSCard';
import ImageLoadingCard from '../imageLoadingCard/imageLoadingCard';
import { saveSettings } from '../redux/settingsActions';
import RenderingDevicesCard from '../renderingDevicesCard/renderingDevicesCard';
import RenderingLocationsCard from '../renderingLocationsCard/renderingLocationsCard';
import RenderTimeoutCard from '../renderTimeoutCard/renderTimeoutCard';
import { useEvent } from '../../events/hooks/useEvent';
import { getStateFromPrerenderUser } from '../redux/settingsReducer';

const deviceStatusChanged = (originalState, settings) => {
  return (
    originalState.renderingSettings.mobile.enabled !== settings.renderingSettings.mobile.enabled ||
    originalState.renderingSettings.desktop.enabled !== settings.renderingSettings.desktop.enabled
  );
};

const GeneralSettings = ({
  prerenderUser,
  settings,
  saveSettings: doSaveSettings,
  getPrerenderUser: doGetPrerenderUser,
}) => {
  const onSaveClick = () => {
    const { track } = useEvent();
    const originalState = getStateFromPrerenderUser(prerenderUser);
    if (deviceStatusChanged(originalState, settings)) {
      track('Rendering Device Status Updated', {
        mobile_is_activated: settings.renderingSettings.mobile.enabled,
        desktop_is_activated: settings.renderingSettings.desktop.enabled,
      });
    }

    doSaveSettings({
      settings,
      userId: prerenderUser.id,
    })
      .then(({ error }) => {
        if (error) {
          throw error;
        }
        doGetPrerenderUser();
        message.success('Settings saved successfully');
        track('Settings Updated', {
          token_is_updated: false,
          recaching_status_is_updated: deviceStatusChanged(originalState, settings),
          rendering_timeout_is_updated: originalState.pageLoadTimeout !== settings.pageLoadTimeout,
          rendering_location_is_updated:
            originalState.renderLocation.renderRegion !== settings.renderLocation.renderRegion,
          https_status_is_updated: originalState.forceHTTPS !== settings.forceHTTPS,
        });
      })
      .catch(() => {
        message.error('An error occurred while saving settings');
      });
  };

  return (
    <div className="row">
      <DisableRecacheCard />
      <RenderingDevicesCard />
      <RenderTimeoutCard />
      <ImageLoadingCard />
      <RenderingLocationsCard />
      <ForceHTTPSCard />

      <div className="col-12 mb-7">
        <Button
          type="primary"
          style={{ width: '100%' }}
          disabled={!settings.diff || settings.inProgress}
          onClick={onSaveClick}
        >
          Apply Changes
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    prerenderUser: state.prerenderUser,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveSettings, getPrerenderUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
