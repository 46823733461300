import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { setForceHTTPS } from '../redux/settingsActions';
import { Switch } from 'antd';

const ForceHTTPSCard = ({ cm, settings, setForceHTTPS: doSetForceHTTPS }) => {
  const toggleForceHTTPS = () => {
    const current = !settings.forceHTTPS;
    doSetForceHTTPS(current);
  };

  return (
    <Card title={cm.title}>
      <div className="col">
        {cm.descriptions.map((description, i) => (
          <div className="row" key={i}>
            <p>{description.map(createHtmlContentFromCmText)}</p>
          </div>
        ))}
        <div className="row">
          <span>
            <Switch
              checked={settings.forceHTTPS}
              onClick={toggleForceHTTPS}
              disabled={settings.inProgress}
              style={{ marginRight: 12 }}
            />
            {settings.forceHTTPS ? cm.switch.activeText : cm.switch.enableText}
          </span>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      title: 'Enforce HTTPS Protocol',
      switch: {
        enableText: 'Not Enforced',
        activeText: 'Enforced',
      },
      descriptions: [
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: 'When this configuration is applied, the rendering servers will only call your website through HTTPS (secure connection) even if the original request was made for an HTTP (not encrypted version).',
          },
        ],
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: "Usually, this does not change the website's content, but in exceptional cases where you can configure the origin server to serve different content, it might cause an issue.",
          },
        ],
      ],
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setForceHTTPS }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceHTTPSCard);
