import { ConfigProvider as ConfigProvider5 } from 'antd';
import 'babel-polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import FullPageLoading from './components/utils/FullPageLoading';
import { initKeycloak } from './features/auth/keycloakAuth';
import { enableMockingOnDevelopment } from './mock.api';
import store from './store';
import { themeConfig } from './theme';

initKeycloak(store.dispatch);

// Will make this toggleable later
enableMockingOnDevelopment().then(() => {
  ReactDOM.render(
    <Suspense fallback={<FullPageLoading />}>
      <ConfigProvider5 prefixCls="ant5" theme={themeConfig}>
        <Provider store={store}>
          <HelmetProvider>
            <BrowserRouter>{renderRoutes(Routes)}</BrowserRouter>
          </HelmetProvider>
        </Provider>
      </ConfigProvider5>
    </Suspense>,
    document.querySelector('#root')
  );
});
