import React from 'react';
import { Alert, Card } from 'antd';
import BillingRenderUsage from '../statistics/BillingRenderUsage';
import { Container } from '../../../components/CssFrameworkComponents';
import { BillingOverviewPlan } from './BillingOverviewPlan';
import { BillingOverviewCostEstimate } from './BillingOverviewCostEstimate';
import { BillingOverviewBillingActivity } from './BillingOverviewBillingActivity';
import BillingRenderExpenses from '../statistics/BillingRenderExpenses';
import BillingDues from '../dues/BillingDues';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { isEnterprise } from '@prerender/billing-shared';

const BillingOverview = () => {
  const user = usePrerenderUser();
  const isUserOnCustomPlan = typeof user.customPrice === 'number';

  return (
    <Container fluid addClass={'p-0'}>
      <h3 className="">Billing Overview</h3>
      <p className="text-base text-grey">Here you will find information about your subscription plan and costs.</p>
      <BillingDues />
      {isEnterprise(user.chargebeePlanId) && (
        <Alert
          showIcon
          type="info"
          // eslint-disable-next-line max-len
          message="Please disregard the cost estimate panel if your account is charged in advance for multiple months. This estimate is for month-to-month customers only."
          style={{ marginBottom: 16 }}
        />
      )}
      {isUserOnCustomPlan ? (
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-8 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingOverviewPlan user={user} isUserOnCustomPlan={isUserOnCustomPlan} />
            </Card>
          </div>
          <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingOverviewBillingActivity user={user} />
            </Card>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingOverviewPlan user={user} isUserOnCustomPlan={isUserOnCustomPlan} />
            </Card>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingOverviewCostEstimate user={user} />
            </Card>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingOverviewBillingActivity user={user} />
            </Card>
          </div>
        </div>
      )}
      <h4 className="mt-4">Render Usage</h4>
      <p className="text-base text-grey">Below, you'll find your render usage report for the specified time period.</p>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 col-xl-6 mb-3">
          <Card className="h-100" bordered={false}>
            <BillingRenderUsage />
          </Card>
        </div>
        {!isUserOnCustomPlan ? (
          <div className="col-12 col-md-12 col-lg-12 col-xl-6 mb-3">
            <Card className="h-100" bordered={false}>
              <BillingRenderExpenses />
            </Card>
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default BillingOverview;
