import { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../actions/pageActions';
import { getPrerenderUser } from '../../actions/prerenderUserActions';
import { createHtmlContentFromCmText } from '../../assets/contentFormatter';
import ConfirmationModal from '../../components/ConfirmationModal';
import ClearCacheModal from './clearCacheModal';
import ClearPartialCacheModal from './clearPartialCacheModal';
import { clearCache } from './redux/clearCacheActions';
import { useEvent } from '../events/hooks/useEvent';
import { Button } from 'antd';
import { DISABLE_CLEAR_CACHE_FEATURE } from '../../hooks/useFeatureFlag';
const NotAvailableText = [
  {
    type: 'text',
    text: 'It is only available for users in our current ',
  },
  {
    type: 'link',
    text: 'plans',
    link: 'https://prerender.io/pricing/',
  },
  {
    type: 'text',
    text: '. If you want to upgrade to one of our new metered plans to use all our advanced features, please reach out to us via ',
  },
  {
    type: 'link',
    text: 'growth@prerender.io',
    link: 'mailto:growth@prerender.io',
  },
  {
    type: 'text',
    text: '.',
  },
];

const ClearCacheCard = ({
  cm,
  closeModal: doCloseModal,
  openModal: doOpenModal,
  clearCache: doClearCache,
  getPrerenderUser: doGetPrerenderUser,
  prerenderUser,
}) => {
  const { isMeteredBilling } = prerenderUser;

  const getResultModalText = (error, data) => {
    if (error) {
      return 'Unknown error occurred. Please try again later.';
    }
    if (data.status === 'clear_pages_queued') {
      return 'Clear cache scheduled';
    }
    if (data.status === 'clear_pages_in_progress') {
      return 'Another cache clear is already in progress';
    }

    return 'Error';
  };
  const { track } = useEvent();
  const isClearCacheDisabled = prerenderUser.features.includes(DISABLE_CLEAR_CACHE_FEATURE);

  const didReceiveAction = useCallback(
    ({ action, actionArgs }) => {
      switch (action) {
        case 'openClearCacheModal':
          doCloseModal();
          if (isMeteredBilling) {
            doOpenModal('clearCacheModal', <ClearCacheModal onEvent={didReceiveAction} />);
          } else {
            doOpenModal(
              'exportModal',
              <ConfirmationModal
                title="Purge Cache Not Available"
                text={NotAvailableText.map(createHtmlContentFromCmText)}
                options={[{ action: 'cancel', text: 'Close' }]}
                onAction={(_action) => {}}
              />
            );
          }
          break;
        case 'closeClearPartialCacheModal':
          doCloseModal();
          break;

        case 'openClearPartialCacheModal':
          doCloseModal();
          doOpenModal(
            'clearPartialCacheModal',
            <ClearPartialCacheModal onEvent={didReceiveAction} wildcard={actionArgs.wildcard} />
          );
          break;
        case 'closeClearCacheModal':
          doCloseModal();
          break;
        case 'submitClearCache':
          doClearCache((error, data) => {
            const text = getResultModalText(error, data);

            doOpenModal(
              'exportModal',
              <ConfirmationModal
                title={'Clear Cache Confirmation'}
                text={text}
                options={[{ action: 'cancel', text: 'Close' }]}
                onAction={(_action) => {
                  doGetPrerenderUser();
                }}
              />
            );
          });

          track('Cache Purged', {
            subscription_plan: prerenderUser.chargebeePlanId,
            purge_is_confirmed: true,
          });
          break;
        default:
          console.log(`Unknown action: ${action}`); // TODO: handle this case
      }
    },
    [doOpenModal, doCloseModal, doClearCache, cm, isMeteredBilling, doGetPrerenderUser]
  );

  return (
    !isClearCacheDisabled && (
      <Button
        onClick={() => {
          track('Clear Cache Clicked', { subscription_plan: prerenderUser.chargebeePlanId });
          didReceiveAction({ action: 'openClearCacheModal' });
        }}
        className="ml-2"
      >
        Clear Cache
      </Button>
    )
  );
};

const mapStateToProps = (state) => ({
  prerenderUser: state.prerenderUser,
  usageStatistics: state.usageStatistics,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      clearCache,
      getPrerenderUser,
    },
    dispatch
  );

const ClearCacheComponent = connect(mapStateToProps, mapDispatchToProps)(ClearCacheCard);

export default ClearCacheComponent;
