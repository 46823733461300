import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../actions/pageActions';
import OnboardingModal from './onboardingModal';
import { OPEN_INTEGRATION_MODAL, MODAL_CANCALLED_ACTION, INTEGRATION_VERIFICATION_DONE } from './onboardingActions';
import { updateTrackingCodeInstalledLocal, setShowOnboarding } from '../../actions/prerenderUserActions';
import { clearContent, setStep } from './redux/onboardingActions';
import { useHistory } from 'react-router-dom';
import { useEvent } from '../events/hooks/useEvent';
import { Button } from 'antd';

const cid = 'Onboarding';

const OnboardingCard = ({
  cm,
  prerenderUser,
  validationResult,
  openModal: doOpenModal,
  closeModal: doCloseModal,
  updateTrackingCodeInstalledLocal: doUpdateTrackingCodeInstalledLocal,
  setShowOnboarding: doSetShowOnboarding,
  clearContent: doClearContent,
  setStep: doSetStep,
}) => {
  const search = useHistory();
  const { track } = useEvent();

  useEffect(() => {
    if (validationResult?.working) {
      doUpdateTrackingCodeInstalledLocal(true);
    }
  }, [validationResult, doUpdateTrackingCodeInstalledLocal]);

  const didReceiveAction = useCallback(
    ({ action }) => {
      switch (action) {
        case OPEN_INTEGRATION_MODAL:
          doOpenModal('onboarding', <OnboardingModal prerenderUser={prerenderUser} onEvent={didReceiveAction} />);
          break;
        case MODAL_CANCALLED_ACTION:
        case INTEGRATION_VERIFICATION_DONE:
          doSetStep(0);
          doClearContent();
          doSetShowOnboarding(false);
          doCloseModal();
          break;

        default:
          break;
      }
    },
    [doOpenModal, prerenderUser, doSetStep, doClearContent, doSetShowOnboarding, doCloseModal]
  );

  if (!cm || !prerenderUser) return null;

  return (
    <div className="card" style={{ width: '100%' }}>
      <div className="card-body">
        <div className="row align-items-center m-4">
          <div className="col-auto">
            <img src="/assets/img/onboarding/onboarding.svg" className="mx-auto" alt="plug in your browser" />
          </div>
          <div className="col ms-n2 align-middle">
            <h4 className="mb-1">Your Quick Integration Guide</h4>

            <p className="small mb-1">
              We call integration the server-side component that forwards requests made by crawlers, to our service.
            </p>
          </div>
          <div className="col-auto">
            <Button
              type="primary"
              onClick={() => {
                search.push('/integration-wizard');
                track('Integration Started', { location: 'Dashboard' });
              }}
            >
              Integrate Prerender
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    prerenderUser: state.prerenderUser,
    validationResult: state.onboarding.validationResult,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      closeModal,
      updateTrackingCodeInstalledLocal,
      setShowOnboarding,
      clearContent,
      setStep,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingCard);
