import { Tooltip } from 'antd';
import React from 'react';
import './PageElementsLib.css';

const Checkbox = ({ checked = false, onchange, labelText }) => (
  <div className="form-check">
    <input className="form-check-input list-checkbox" type="checkbox" checked={checked} onChange={() => onchange()} />
    <label className="form-check-label">{labelText}</label>
  </div>
);

const CheckboxCentered = ({ checked = false, disabled = false, onchange }) => (
  <div className="form-check d-flex align-items-center">
    <input
      className="form-check-input list-checkbox"
      style={{ marginLeft: '-20px' }}
      type="checkbox"
      checked={checked}
      onChange={onchange}
      disabled={disabled}
    />
    <label className="form-check-label"></label>
  </div>
);

const CheckboxPlaceholder = () => (
  <div className="form-check">
    <label className="form-check-label"></label>
  </div>
);

const CheckListOptions = ({
  width = '400px',
  count = 0,
  name = 'item',
  actions = [],
  onsubmit,
  onclose,
  show = true,
  isActive = true,
}) => {
  if (count === 0 || !show) return null;
  return (
    <div className="list-alert alert alert-dark alert-dismissible border show" style={{ width }} role="alert">
      <div className="row align-items-center">
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" id="listAlertCheckbox" type="checkbox" checked disabled />
            <label className="form-check-label text-white" htmlFor="listAlertCheckbox">
              <span className="list-alert-count">{count}</span> {count === 1 ? name : `${name}s`}
            </label>
          </div>
        </div>
        <div className="col-auto mr-n3">
          {actions.map((action) => (
            <button
              key={`ac${action.name}`}
              className="btn btn-sm btn-white-20 ml-1"
              onClick={() => onsubmit(action.name)}
              disabled={!isActive}
            >
              {action.text && action.text}
              {action.icon && <i className={`fe fe-${action.icon}`}></i>}
            </button>
          ))}
        </div>
      </div>
      <button type="button" className="list-alert-close close" aria-label="Close" onClick={() => onclose()}>
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

const CheckMark = ({ checked }) => {
  const cmColor = checked ? 'text-success' : 'text-white';
  return (
    <>
      <i className={`fa fa-check ${cmColor}`} style={{ fontSize: '1.6rem' }}></i>
    </>
  );
};

const ControlButton = ({
  type,
  rounded = true,
  onclick,
  text,
  addClass = '',
  action = '',
  size = 'sm',
  color = null,
  disabled = false,
  loading = null,
}) => {
  const btnClass = color
    ? text
      ? `btn btn-${color} btn-${size}${rounded ? ' btn-rounded' : ''}`
      : `btn btn-${color}${rounded ? ' btn-rounded-circle' : ''} btn-${size}`
    : text
    ? `btn btn-outline-primary btn-${size}${rounded ? ' btn-rounded' : ''}`
    : `btn btn-outline-primary${rounded ? ' btn-rounded-circle' : ''} btn-${size}`;
  const spanClass = type ? 'ml-1' : '';
  const btnType = action === 'submit' ? 'submit' : 'button';
  const icons = {
    reload: 'fa-refresh',
    close: 'fa-close',
    back: 'fa-chevron-left',
    enter: 'fa-check',
    edit: 'fa-pencil',
    search: 'fa-search',
    add: 'fa-plus',
    minus: 'fa-minus',
    up: 'fa-arrow-up',
    down: 'fa-arrow-down',
    delete: 'fa-trash-o',
    undo: 'fa-undo',
  };
  return (
    <button
      className={`${btnClass} ${addClass}`}
      type={btnType}
      onClick={(event) => {
        if (onclick) onclick(event);
      }}
      disabled={disabled}
    >
      {!loading && type && <i className={`fa ${icons[type]}`} style={{ lineHeight: 'inherit' }}></i>}
      {!loading && text && <span className={spanClass}>{text}</span>}
      {loading && (
        <div className={`spinner-border spinner-border-sm ${loading.color}`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </button>
  );
};

const CrawlerIcon = ({ agent }) => {
  const matcher = [
    { google: 'i_google' },
    { yandex: 'i_yandex' },
    { facebook: 'i_facebook' },
    { witterb: 'i_twitter' },
    { slack: 'i_slack' },
    { bing: 'i_bing' },
    { WhatsApp: 'i_whatsapp' },
    { Baiduspi: 'i_baidu' },
    { Applebot: 'i_apple' },
  ];
  const spiders = {
    google: 'Googlebot',
    yandex: 'Yandex',
    facebook: 'Facebookbot',
    witterb: 'Twitterbot',
    slack: 'Slackbot',
    bing: 'Bingbot',
    WhatsApp: 'WhatsApp',
    Baiduspi: 'BaiduSpider',
    Applebot: 'Applebot',
    default: 'Other Crawler',
  };
  const img = matcher.find((match) => agent.indexOf(Object.keys(match)[0]) !== -1) || { default: 'i_default' };
  if (Object.keys(img)[0] === 'google') spiders[Object.keys(img)] = 'Googlebot Mobile';
  return (
    <Tooltip placement="right" title={agent}>
      <img
        src={`/assets/img/${img[Object.keys(img)]}.png`}
        style={{ width: '18px', height: '18px', opacity: '.5' }}
        data-tip={`${spiders[Object.keys(img)]}:<br>${agent.match(/.{1,70}/g).join('<br>')}`}
        alt={`${agent} crawler`}
      />
    </Tooltip>
  );
};

const IconMore = () => <i className="text-lg text-grey fa fa-chevron-right"></i>;

const ToggleControl = ({
  checked = false,
  onclick,
  id,
  addClass = '',
  small = false,
  labelText = '',
  disabled = false,
  inputAddClass = '',
}) => (
  <div className={`form-check form-switch ${addClass}`}>
    <input
      type="checkbox"
      className={`form-check-input ${inputAddClass}`}
      id={id}
      checked={checked}
      onChange={onclick}
      disabled={disabled}
    />
    <label
      className="form-check-label"
      style={small ? { width: '100%', height: '84%', marginTop: '1px' } : {}}
      htmlFor={id}
    >
      {labelText}
    </label>
  </div>
);

const LoadingTextBar = ({ c = 'w-100' }) => (
  <div
    className={`mt-1 loading-background h-25px border-radius-2px${
      !c || (c && c.indexOf('mb-') === -1) ? ' mb-1' : ''
    } ${c}`}
  >
    &nbsp;
  </div>
);

export {
  Checkbox,
  CheckboxCentered,
  CheckboxPlaceholder,
  CheckListOptions,
  CheckMark,
  ControlButton,
  CrawlerIcon,
  IconMore,
  ToggleControl,
  LoadingTextBar,
};
