import React from 'react';
import { Button, Card, Typography, Flex, message, Skeleton, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import AdminTemplate from '../../layout/AdminTemplate';
import {
  useGetRankedDomainsQuery,
  useCreteRankedDomainMutation,
  useDeleteRankedDomainMutation,
} from './keywordsDomainsApi';
import DomainsTable from './DomainsTable';
import NewDomainModal from './NewDomainModal';
import AvailableKeywords from './AvailableKeywords';
import { useEvent } from '../events/hooks/useEvent';
import { useCharbeePlanId } from '../../hooks/usePrerenderUser';

const PAGE_SIZE = 30;

function useQuery({ defaultPageSize = 30 }) {
  const { search } = useLocation();

  return React.useMemo(() => {
    const queryObj = new URLSearchParams(search);
    return {
      page: queryObj.get('page') || 1,
      pageSize: queryObj.get('pageSize') || defaultPageSize,
    };
  }, [search]);
}

function DomainsListPage() {
  const [newDomainModalOpen, setNewDomainModalOpen] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const query = useQuery({ defaultPageSize: PAGE_SIZE });
  const history = useHistory();
  const { track } = useEvent();
  const planId = useCharbeePlanId();

  const listRankedDomainsResult = useGetRankedDomainsQuery(query, { refetchOnMountOrArgChange: true });
  const [createRankedDomain, createRankedDomainResult] = useCreteRankedDomainMutation();
  const [deleteRankedDomain, deleteRankedDomainResult] = useDeleteRankedDomainMutation();

  const onPaginationChange = (page, pageSize) => history.push(`?page=${page}&pageSize=${pageSize}`);

  const onNewDomainClick = () => setNewDomainModalOpen(true);
  const onCloseModal = () => setNewDomainModalOpen(false);

  const onDeleteRankedDomain = (id) => {
    const domain = listRankedDomainsResult.data?.domains.find((d) => d.id === id);
    deleteRankedDomain(id);

    track('Rank Tracker: Domain Delete clicked', {
      keywords_total_count: listRankedDomainsResult.data?.totalKeywords,
      keywords_used_count: listRankedDomainsResult.data?.usedKeywords - domain.keywords.length,
      domain: domain?.domain,
    });
  };

  React.useEffect(() => {
    if (listRankedDomainsResult.isError) {
      messageApi.error('Failed to fetch domains list');
    } else if (createRankedDomainResult.isError) {
      messageApi.error('Failed to create a new domain');
    } else if (createRankedDomainResult.isSuccess) {
      onCloseModal();
      messageApi.success('New domain added to the tracking list');
    } else if (deleteRankedDomainResult.isError) {
      messageApi.error('Failed to delete ranked domain');
    } else if (deleteRankedDomainResult.isSuccess) {
      messageApi.success('Ranked domain deleted successfully');
    }
  }, [
    listRankedDomainsResult.isError,
    createRankedDomainResult.isError,
    createRankedDomainResult.isSuccess,
    deleteRankedDomainResult.isError,
    deleteRankedDomainResult.isSuccess,
  ]);

  return (
    <AdminTemplate>
      {contextHolder}

      <Space direction="vertical" size="large">
        <Flex justify="space-between" align="center">
          <div>
            <Typography.Title level={2} style={{ marginBottom: 8 }}>
              Rank Tracker
            </Typography.Title>
            <Typography.Paragraph type="secondary">Monitor your site's keyword rankings</Typography.Paragraph>
          </div>
          {listRankedDomainsResult.isFetching ? (
            <Skeleton.Input active size="large" />
          ) : (
            <AvailableKeywords
              used={listRankedDomainsResult.data?.usedKeywords}
              total={listRankedDomainsResult.data?.totalKeywords}
            />
          )}
        </Flex>
        <Card
          title="Available Domains"
          extra={
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={onNewDomainClick}
              disabled={listRankedDomainsResult.data?.totalKeywords === 0}
            >
              Add New Domain
            </Button>
          }
        >
          <DomainsTable
            isLoading={listRankedDomainsResult.isFetching || deleteRankedDomainResult.isLoading}
            onDeleteClick={onDeleteRankedDomain}
            rows={listRankedDomainsResult.data?.domains}
            pagination={{
              current: query.page,
              pageSize: query.pageSize,
              total: listRankedDomainsResult.data?.totalCount,
              onChange: onPaginationChange,
            }}
          />
        </Card>
      </Space>

      <NewDomainModal
        loading={createRankedDomainResult.isLoading}
        open={newDomainModalOpen}
        onClose={onCloseModal}
        onCreate={createRankedDomain}
        existingDomains={listRankedDomainsResult.data?.domains.map((d) => d.domain)}
        maxKeywords={listRankedDomainsResult.data?.totalKeywords}
        usedKeywords={listRankedDomainsResult.data?.usedKeywords}
        planId={planId}
      />
    </AdminTemplate>
  );
}

export default DomainsListPage;
