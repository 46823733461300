import { Row, Col, Statistic, Card, Skeleton, theme } from 'antd';

function DomainStats({ isLoading, uniquePages, averageCacheMinutes, firstSeenAt, notIndexedPages }) {
  const { token } = theme.useToken();

  return (
    <Row gutter={[token.marginMD, token.marginMD]}>
      <Col xs={12} xl={4}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Unique Pages" value={uniquePages || '-'} />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Average Cache Age" value="Available soon" />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={8}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic
              title="Fist Seen At"
              value={firstSeenAt}
              formatter={(date) => (date ? new Date(date).toLocaleString() : '-')}
            />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Not Indexed Pages" value="Available soon" />
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
}

export default DomainStats;
