import { Button, Input, Select, Divider, Space, Flex, Checkbox, Tooltip } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useState, useRef, useEffect } from 'react';
import UserAgentState from './UserAgentState';
import './dropdownFilters.css';

const defaultUserAgents = [
  {
    label: 'Googlebot',
    value:
      'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html) Chrome/% Safari/537.36|Googlebot/2.1 (+http://www.google.com/bot.html)|Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)', // eslint-disable-line max-len
  },
  {
    label: 'Googlebot (Smartphone)',
    value:
      'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/% Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)', // eslint-disable-line max-len
  },
  {
    label: 'Bingbot',
    value: 'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)',
  },
  {
    label: 'Baiduspider',
    value: 'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html)',
  },
  {
    label: 'Yandex',
    value: 'Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots)',
  },
  {
    label: 'Facebook',
    value: 'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
  },
  {
    label: 'Twitter',
    value: 'Twitterbot/1.0',
  },
];

export const UserAgentDropdownFilter = ({ email, confirm, selectedKeys, setSelectedKeys, isVisible }) => {
  const userAgentState = new UserAgentState(email, defaultUserAgents);
  const [userAgents, setUserAgents] = useState(userAgentState.userAgents);
  const [caseSensitiveSearch, setCaseSensitiveSearch] = useState(!!selectedKeys?.[0]?.caseSensitiveSearch);
  const [selectedUserAgents, setSelectedUserAgents] = useState(selectedKeys?.[0]?.selectedUserAgents || []);
  const [customUserAgent, setCustomUserAgent] = useState('');
  const inputRef = useRef(null);

  const onSubmit = () => {
    setSelectedKeys([{ selectedUserAgents, caseSensitiveSearch }]);
    userAgentState.update(userAgents);
    confirm();
  };

  const addCustomUserAgent = () => {
    if (!customUserAgent.length) return;
    if (userAgents.find((ua) => ua.value === customUserAgent)) return;

    setUserAgents([...userAgents, { label: `Custom (${customUserAgent})`, value: customUserAgent }]);
    setSelectedUserAgents([...selectedUserAgents, customUserAgent]);
    setCustomUserAgent('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const removeUserAgent = (userAgent) => {
    setUserAgents(userAgents.filter((ua) => ua.value !== userAgent.value));
    setSelectedUserAgents(selectedUserAgents.filter((ua) => ua.value !== userAgent.value));
  };

  const onReset = () => {
    setUserAgents(defaultUserAgents);
    setSelectedUserAgents([]);
  };

  useEffect(() => {
    if (!isVisible) {
      onSubmit();
    }
  }, [isVisible]);

  return (
    <div className="dropdownFilters-layout">
      Crawl Bot User-Agent
      <div className="dropdownFilters-container">
        <Select
          placeholder="Click to add User-Agents"
          className="dropdownFilters-user-agent-select"
          mode="multiple"
          allowClear
          onChange={setSelectedUserAgents}
          value={selectedUserAgents}
          options={userAgents}
          optionRender={(option) => (
            <Flex style={{ width: '100%' }} align="center" justify="flex-start" vertical={false}>
              <div style={{ width: '100%', overflow: 'hidden' }}>{option.data.label}</div>
              {!selectedUserAgents.includes(option.data.value) && (
                <Tooltip title="Remove option">
                  <div style={{ margin: '0 auto' }}>
                    <Button
                      onClick={(ev) => {
                        removeUserAgent(option.data);
                        ev.stopPropagation();
                      }}
                      size="small"
                      type="text"
                      icon={<CloseOutlined />}
                    />
                  </div>
                </Tooltip>
              )}
            </Flex>
          )}
          placement="topLeft"
          maxTagCount="responsive"
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
              <span>+{omittedValues.length}</span>
            </Tooltip>
          )}
          maxTagTextLength={10}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Flex gap="small" vertical="true" align="center" justify="space-between">
                  <Input
                    placeholder="Enter User-Agent"
                    ref={inputRef}
                    value={customUserAgent}
                    onChange={(ev) => setCustomUserAgent(ev.target.value)}
                    onKeyDown={(ev) => {
                      if (ev.code === 'Enter') {
                        addCustomUserAgent();
                      }
                      ev.stopPropagation();
                    }}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={(ev) => {
                      addCustomUserAgent();
                      ev.preventDefault();
                    }}
                  >
                    Add User-Agent
                  </Button>
                </Flex>
              </Space>
            </>
          )}
        />
      </div>
      <div className="dropdownFilters-buttons-centered">
        <Checkbox
          checked={caseSensitiveSearch}
          onChange={({ target: { checked } = {} } = {}) => setCaseSensitiveSearch(checked)}
        >
          Case sensitive
        </Checkbox>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
          <Button size="small" onClick={onReset}>
            Reset User-Agents
          </Button>
          <Button type="primary" size="small" onClick={onSubmit}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};
