import { useSelector } from 'react-redux';

const PRERENDER_EMAIL = '@prerender.io';

export const usePrerenderUser = () => useSelector((state) => state.prerenderUser);

export const usePrerenderEmail = () => useSelector((state) => state.prerenderUser.email);
export const usePrerenderUserFeatures = () => useSelector((state) => state.prerenderUser.features);
export const useCharbeePlanId = () => useSelector((state) => state.prerenderUser.chargebeePlanId);

export const useIsPrerenderUser = () => {
  const email = usePrerenderEmail();
  return email.endsWith(PRERENDER_EMAIL);
};
