import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import SitemapListPage from './SitemapListPage';
import SitemapDetailsPage from './SitemapDetailsPage';

export default function SitemapRouter() {
  let { url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={url} exact>
          <SitemapListPage />
        </Route>
        <Route path={`${url}/map/:id`}>
          <SitemapDetailsPage />
        </Route>
        <Route path="*">Not found</Route>
      </Switch>
    </>
  );
}
