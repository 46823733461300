import { restApi } from '../../api/restApiSlice';

export const domainsApiSlice = restApi.injectEndpoints({
  tagTypes: ['addedDomains'],
  endpoints: (builder) => ({
    getAddedDomains: builder.query({
      query: (params) => ({ url: `/domain-statistics/added`, method: 'GET', params }),
      providesTags: ['addedDomains'],
    }),
  }),
});

export const { useGetAddedDomainsQuery } = domainsApiSlice;
