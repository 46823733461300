import { Card, Col, Row, Statistic, Typography, message, theme } from 'antd';
import ReferralStatusTable from './components/ReferralStatusTable';
import ReferralForm from './components/ReferralForm';
import { useGetReferralStatsQuery, useGetReferralsQuery, useSendReferralMutation } from './referralsApiSlice';
import InformationBlock from './components/InformationBlock';
import { useEffect, useMemo, useState } from 'react';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';

const PAGE_SIZE = 10;

const mapToApiPayload = (params) => {
  return {
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
  };
};

const convertToDollars = (totalInCents) => {
  const totalInDollars = (totalInCents / 100).toFixed(2);
  return totalInDollars;
};

const ReferralOverview = () => {
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'referredOn',
      order: 'descend',
    },
  };
  const [tableParams, setTableParams] = useState(initialParams);
  const { data: totalData, error, isFetching } = useGetReferralStatsQuery();
  const {
    data: referrals,
    error: referralsError,
    isFetching: isFetchingReferrals,
  } = useGetReferralsQuery(mapToApiPayload(tableParams), { refetchOnMountOrArgChange: true });
  const [sendReferral, { isLoading }] = useSendReferralMutation();
  const { track } = useEvent();
  const user = usePrerenderUser();
  const { token } = theme.useToken();
  const totalReferrals = referrals?.totalCount || 0;

  const tableData = useMemo(() => {
    return (
      referrals?.data?.map((item) => {
        const formattedReferralsDate = new Intl.DateTimeFormat().format(new Date(item.referredOn));
        return {
          key: item.id,
          name: item.name,
          email: item.email,
          status: item.status,
          referredOn: formattedReferralsDate,
        };
      }) || []
    );
  }, [referrals]);

  useEffect(() => {
    if (referralsError) {
      message.error('Failed to fetch referrals');
    }
  }, [referralsError?.data?.error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter,
    });
  };

  const onSendReferral = (referralData) => {
    sendReferral(referralData)
      .unwrap()
      .then(() => {
        message.success('Referral sent successfully');
        track('Referral page: Send message clicked', {
          subscription_plan: user.planName,
          invited_email: referralData.email,
          successfull: true,
        });
      })
      .catch((error) => {
        message.error(error.data?.message || 'Failed to send referral');
        track('Referral page: Send message clicked', {
          subscription_plan: user.planName,
          invited_email: referralData.email,
          successfull: false,
        });
      });
  };

  return (
    <div className="referral-overview">
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={17}>
          <Card
            title={
              <>
                <Typography.Title level={4} style={{ marginTop: token.paddingSM }}>
                  $45 for You, $45 for Them
                </Typography.Title>
                <Typography.Paragraph style={{ fontWeight: 'normal', whiteSpace: 'normal' }}>
                  Earn credits for every eligible person who signs up using your referral link and upgrades to a paid
                  Prerender plan.
                </Typography.Paragraph>
              </>
            }
          >
            <InformationBlock />
          </Card>
        </Col>
        <Col xs={8} xl={7}>
          <Card title="Refer Your Friend Now!" loading={isLoading}>
            <ReferralForm onFinish={onSendReferral} isLoading={isLoading} />
          </Card>
        </Col>
        <Col xs={24} xl={17}>
          <Card title="Referral Status">
            <ReferralStatusTable
              tableData={tableData}
              isFetchingReferrals={isFetchingReferrals}
              handleTableChange={handleTableChange}
              tableParams={tableParams}
              totalReferrals={totalReferrals}
            />
          </Card>
        </Col>
        <Col xs={8} xl={7}>
          <Card title="Total Credits Earned">
            <div>
              <Statistic
                value={convertToDollars(totalData?.totalCreditsEarned)}
                prefix="$"
                loading={isFetching}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReferralOverview;
