import React from 'react';
import { Card } from '../../../components/CssFrameworkComponents';

const RenderingLocationCard = ({ current, disabled, icon, title, buttonText, onClick, badgeText = null }) => (
    <Card additionalCardClasses={current ? 'bg-success' : 'bg-light'}>
      <div className='row'>
        <div className='col'>
          <div className='row text-center'>
            <i className={`fa-3x fe ${icon}`}></i>
          </div>
          <div className='row text-center'>
            <p>{title}</p>
          </div>
          {badgeText
          && <div className='row text-center pr-5 pl-5'>
            <span className="badge bg-success">{badgeText}</span>
          </div>
          }
          {!badgeText
          && <div className='row text-center pr-5 pl-5'><span className={`badge ${current ? 'bg-success' : 'bg-light'}`}>&nbsp;</span></div>}
          <div className="card-footer border-0">
            <button className={`btn btn-block ${current ? 'btn-light' : 'btn-primary'}`}
                    disabled={disabled || current}
                    onClick={onClick}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </Card>
);

export default RenderingLocationCard;
