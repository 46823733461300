export const themeConfig = {
  token: {
    colorSuccess: '#35b879',
    colorPrimary: '#2da01d',
    colorInfo: '#0f42fe',
    fontSize: 14,
    colorPrimaryBgHover: '#ccf5c7',
    colorPrimaryBorder: '#9deb93',
    colorPrimaryBorderHover: '#6be15b',
    colorPrimaryHover: '#3cd827',
    colorPrimaryActive: '#248217',
    colorPrimaryBg: '#e8fae5',
    colorPrimaryTextHover: '#3cd827',
    colorPrimaryText: '#2da01d',
    colorPrimaryTextActive: '#248217',
    colorWarning: '#f37f2c',
    colorError: '#db232d',
    colorTextBase: '#262626',
    boxShadow:
      '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n0 3px 6px -4px rgba(0, 0, 0, 0.12),\n0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    boxShadowSecondary:
      '\n    0 6px 16px 0 rgba(0, 0, 0, 0.08),\n0 3px 6px -4px rgba(0, 0, 0, 0.12),\n0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },
  components: {
    Button: {
      colorLink: 'rgb(45, 160, 29)',
      colorLinkActive: 'rgb(36, 130, 23)',
      colorLinkHover: 'rgb(60, 216, 39)',
    },
    Progress: {
      defaultColor: '#2da01d',
    },
  },
};
