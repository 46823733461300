/*
renders:
- a error alert box for display E0XXX error code messages within components

pulls the last API error code directly from state.page.lastErrorCode.
However, a 'custom' Error Code can also be transmitted via the errorCode argument if necessary.

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { configForOrigin } from '../features/auth/keycloakAuth';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const cid = 'Navigation';
const menuItems = [
  {
    name: 'My Account',
    target: '/account',
    external: true,
    active: true,
    slug: 'my-account',
  },
  {
    name: 'Billing',
    target: '/billing',
    slug: 'billing',
  },
  {
    name: 'divider',
    active: true,
  },
  {
    name: 'Sign Out',
    target: '/signout',
    active: true,
    slug: 'sign-out',
  },
];

class UserDropdownMenu extends Component {
  render() {
    const { cm } = this.props;
    const realmConfig = configForOrigin();
    return (
      <div className="dropdown">
        <a
          href="#"
          id="sidebarIcon"
          className="dropdown-toggle"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Avatar icon={<UserOutlined />} style={{ marginRight: 24, color: '#1A5F11', backgroundColor: '#CCF5C7' }} />
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
          {menuItems.map((item, index) => {
            if (item.slug && item.slug === 'my-account') {
              if (realmConfig && realmConfig.url) {
                item.target = `${realmConfig.url}realms/${realmConfig.realm}/account`;
              }
            }
            return item.name === 'divider' ? (
              <hr key={`dpi${index}`} className="dropdown-divider" />
            ) : item.external ? (
              <a
                key={`dpi${index}`}
                href={item.target}
                className="dropdown-item"
                target="_blank"
                rel="noreferrer noopener"
              >
                {item.name}
              </a>
            ) : (
              <Link key={`dpi${index}`} to={item.target} className="dropdown-item">
                {item.name}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    prerenderUser: state.prerenderUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdownMenu);
