import { Typography, Layout } from 'antd';
import { useEffect, useState } from 'react';
import NavigationButtons from '../../../NavigationButtons';
import DeliveryTime from '../../../deliveryTime';
import ScreenshotFrame from '../components/ScreenshotFrame';
import { useGetPreviewQuery } from '../../../../api/integrationWizardSlice';
import { useMediaQuery } from 'react-responsive';

const { Title, Text } = Typography;
const { Content } = Layout;

const MOCK_DELIVERY_SPEED = { base: 6700, improved: 2000 };

const RenderedPagePreview = ({ onGoBack, onGoNext, domain }) => {
  const [previewGetStarted] = useState(Date.now());
  const [previewPollingInterval, setPreviewPollingInterval] = useState(1000);
  const { currentData: previewData, error: previewError } = useGetPreviewQuery(domain, {
    refetchOnMountOrArgChange: true,
    pollingInterval: previewPollingInterval,
    skip: !domain,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, message: '' });
  const [deliverySpeed, setDeliverySpeed] = useState(MOCK_DELIVERY_SPEED); //remove when we get it from BE
  const [pageContent, setPageContent] = useState({
    title: '',
    text: '',
  });

  const isSmallScreen = useMediaQuery({ query: `(max-width: 1000px)` });

  useEffect(() => {
    if (previewError && previewError.status !== 404) {
      setPreviewPollingInterval(0);
      setLoading(false);
      setError({ isError: true, message: 'We could not create a preview' });
    } else if (previewData?.preview) {
      setPreviewPollingInterval(0);
      setLoading(false);
    } else if (Date.now() - previewGetStarted > 65000) {
      setPreviewPollingInterval(0);
      setLoading(false);
      setError({ isError: true, message: 'Preview timed out' });
    } else {
      setPreviewPollingInterval(1000);
      setLoading(true);
    }
  }, [previewData, previewError]);

  useEffect(() => {
    if (loading && !error.isError) {
      setPageContent({ title: `Scanning your domain ${domain}`, text: 'This may take a few moments...' });
    } else if (!loading && !error.isError) {
      setPageContent({ title: 'Scan completed', text: 'This is the version the bots and crawlers will see' });
    }
  }, [loading, error, domain]);

  const showScanResult = !loading && !error.isError;

  const showScreenshotFrameSubtitle = !isSmallScreen && !loading && error.isError;

  return (
    <Layout style={{ width: '100%' }}>
      <Content style={{ minHeight: '75vh' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Title level={3} style={{ marginTop: 0 }}>
            {pageContent.title}
          </Title>
          {showScanResult && (
            <Title level={4} style={{ marginTop: 0 }}>
              Cached homepage view
            </Title>
          )}
          <Text>{pageContent.text}</Text>
        </div>
        <ScreenshotFrame
          loading={loading}
          error={error}
          homepageScreenshot={previewData?.preview}
          showSubtitle={showScreenshotFrameSubtitle}
          onGoNext={onGoNext}
        />
        {/* TODO: enable it when have delivery delta */}
        {false && <DeliveryTime base={deliverySpeed.base} improved={deliverySpeed.improved} />}
      </Content>
      <NavigationButtons onGoNext={onGoNext} onGoBack={onGoBack} />
    </Layout>
  );
};

export default RenderedPagePreview;
