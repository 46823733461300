import { useMemo } from 'react';
import { isSSOSite } from '../features/auth/keycloakAuth';

export function useIsSSO() {
  const isSSO = useMemo(() => {
    return isSSOSite();
  }, [window.origin]);

  return isSSO;
}
