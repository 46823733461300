import React from 'react';
import { Card, Row, Col } from 'antd';

import CurrentToken from './CurrentToken';
import RegenerateToken from './RegenerateToken';

function PreRenderTokenPage() {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={20} xxl={15}>
        <Card title="Prerender token (API Token)" size="small">
          <CurrentToken />
        </Card>
      </Col>
      <Col xs={24} lg={20} xxl={9}>
        <Card title="Token Regenerator" size="small">
          <RegenerateToken />
        </Card>
      </Col>
    </Row>
  );
}

export default PreRenderTokenPage;
