import { restApi } from './restApiSlice';

export const environmentApi = restApi.injectEndpoints({
  tagTypes: ['EnvironmentHistory', 'Environment'],
  endpoints: (builder) => ({
    getEnvironment: builder.query({
      query: () => ({ url: '/environment', method: 'GET' }),
      providesTags: ['Environment'],
    }),
    setQueryParamsSettings: builder.mutation({
      query: (body) => ({
        url: `/environment`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Environment', 'EnvironmentHistory'],
    }),
  }),
});

export const { useSetQueryParamsSettingsMutation, useGetEnvironmentQuery } = environmentApi;
