import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

export const EMPTY_TAG = 'EMPTY_TAG';

const checkEmptyTag = (metaTag) => ((metaTag === EMPTY_TAG) ? undefined : metaTag);

class MetaTags extends Component {
  render() {
    const { tags, defaultMeta } = this.props;
    const tag = tags || {};
    const title = checkEmptyTag(tag.title || defaultMeta.title);
    const description = checkEmptyTag(tag.description || defaultMeta.description);
    const ogTitle = checkEmptyTag(tag.ogTitle || defaultMeta.ogTitle);
    const ogDescription = checkEmptyTag(tag.ogDescription || defaultMeta.ogDescription);
    const ogImage = checkEmptyTag(tag.ogImage || defaultMeta.ogImage);
    const ogUrl = checkEmptyTag(tag.ogUrl || defaultMeta.ogUrl);
    const robots = checkEmptyTag(tag.robots || defaultMeta.robots);
    const canonical = checkEmptyTag(tag.canonical || defaultMeta.canonical);
    return (
      <Helmet>
        <title>{title}</title>
        <meta id="fb:app_id" content="" />
        <meta id="meta-description" name="description" content={description} />
        <meta id="og-title" property="og:title" content={ogTitle} />
        <meta id="og-description" property="og:description" content={ogDescription} />
        <meta id="og-image" property="og:image" content={ogImage} />
        <meta id="og-url" property="og:url" content={ogUrl} />
        <meta id="og-type" property="og:type" content="website" />
        { robots && <meta name="robots" content={robots} />}
        <link rel="canonical" href={canonical} />
      </Helmet>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultMeta: state.page.contentData.default.meta,
    language: state.page.language,
  };
}

export default connect(mapStateToProps)(MetaTags);
