import React, { useEffect, useRef, useState } from 'react';
import { Flex, Typography, Card, Button, Skeleton } from 'antd';
import { LockFilled } from '@ant-design/icons';
// import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

const { Paragraph } = Typography;

import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';

import {
  useGetPagesDeliveredToCrawlersQuery,
  useGetPagesDeliveredToCrawlersDailyQuery,
} from '../api/dashboardApiSlice';

import { DashboardPieChart } from './util/DashboardPieChart';
import { BlurringOverlay } from '../../../components/BlurringOverlay/BlurringOverlay';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

const colorsPie = [
  '#C1277B',
  '#176BFA',
  '#58DA9B',
  '#B692F6',
  '#F55A36',
  '#F53936',
  '#E536F5',
  '#F57B36',
  '#2FC2B6',
  '#2FC2B6',
  '#36C6F5',
  '#2BF5A1',
  '#364EF5',
];

export function DeliveredToCrawlerCharts({ days, trackingCodeInstalled }) {
  const { data: dataPieRaw, isFetching: isLoadingPie } = useGetPagesDeliveredToCrawlersQuery(days);
  const { data: dataChartRaw, isFetching: isLoadingChart } = useGetPagesDeliveredToCrawlersDailyQuery(days);

  const isMobile = useIsMobileView();

  if (isLoadingChart || isLoadingPie) {
    return (
      <Card bordered={false} style={{ width: '70%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Skeleton />
        </Flex>
      </Card>
    );
  }

  const dataChart =
    dataChartRaw?.data?.map(({ day, ...fields }) => {
      const name = dayjs(day).format('MMM DD');

      const filteredFields = {};

      for (const [key, value] of Object.entries(fields)) {
        const valueNum = Number(value);
        if (valueNum > 0) {
          filteredFields[key] = valueNum;
        }
      }

      return {
        name,
        ...filteredFields,
      };
    }) || [];

  const barNames = dataChart.reduce((acc, curr) => {
    const keys = Object.keys(curr).filter((d) => d !== 'name' && !acc.includes(d));
    return [...acc, ...keys];
  }, []);

  const dataPie = (dataPieRaw?.data ? Object.keys(dataPieRaw?.data) : [])
    .map((bot) => {
      return {
        name: bot,
        value: Number(dataPieRaw.data[bot]),
      };
    })
    .filter(({ value }) => value > 0);

  return (
    <Flex style={{ width: !isMobile ? '70%' : '100%', position: 'relative' }}>
      <BlurringOverlay
        enabled={!trackingCodeInstalled}
        location="Dashboard Pages delivered to Crawler"
        content="Complete Prerender integration to unlock its full potential."
      />
      <Card bordered={false} style={{ width: '100%', filter: !trackingCodeInstalled ? 'blur(3px)' : null }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Paragraph strong style={{ marginBottom: '0' }}>
            Pages delivered to Crawler
          </Paragraph>
          <Flex gap="small" wrap="wrap" align={'center'}>
            <Link to="/crawl-stats">
              <Button size={'small'}>CDN Analytics</Button>
            </Link>
            {/* <SettingOutlined /> */}
          </Flex>
        </Flex>

        <Flex style={{ height: '100%', width: '100%' }} vertical={isMobile}>
          <div style={{ width: !isMobile ? '50%' : '100%' }}>
            <ResponsiveContainer width="95%" height={400}>
              <ComposedChart
                data={dataChart}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                {barNames.map((name, index) => {
                  const fill = colorsPie[index] ? colorsPie[index] : colorsPie[colorsPie.length - 1];
                  return <Bar key={name} dataKey={name} stackId="a" fill={fill} />;
                })}

                {/* <Line dot={false} type="monotone" dataKey="pv" id="trendline0" stroke="#FFB353" strokeWidth={2} /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div
            style={{
              width: !isMobile ? '50%' : '100%',
              borderLeft: !isMobile ? '1px solid rgba(0,0,0,.1)' : null,
              borderTop: isMobile ? '1px solid rgba(0,0,0,.1)' : null,
            }}
          >
            {!isLoadingPie && (
              <Flex justify={'space-between'} align={'center'} style={{ height: isMobile ? '300px' : '100%' }}>
                <DashboardPieChart data={dataPie} colors={colorsPie}></DashboardPieChart>
              </Flex>
            )}
            {isLoadingPie && <Skeleton />}
          </div>
        </Flex>
      </Card>
    </Flex>
  );
}
