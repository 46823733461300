import { isFree, isEnterprise } from '@prerender/billing-shared';
import { Button, Card, Divider, Flex, Typography, Progress, Space } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useCharbeePlanId } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';

function AddMoreCta({ isFreePlan, isCustomPlan }) {
  const { track } = useEvent();
  if (isCustomPlan) {
    return null;
  }
  const linkTo = {
    pathname: '/billing/packages',
    state: { scrollTo: 'keywords' },
  };

  return (
    <>
      <Divider type="vertical" />

      <Link to={linkTo}>
        <Button type="primary" onClick={() => track('Billing Page Viewed', { location: 'Rank Tracker' })}>
          {isFreePlan ? 'Buy a package' : 'Add More'}
        </Button>
      </Link>
    </>
  );
}

function KeywordsUsage({ used, total }) {
  const hasReachedLimit = used >= total;

  return (
    <div>
      <Space size="large">
        <Typography.Text strong>
          {used}/{total} keywords used
        </Typography.Text>
      </Space>
      <Progress
        style={{ margin: 0 }}
        percent={(used / total) * 100}
        showInfo={false}
        strokeColor={hasReachedLimit ? 'red' : 'primary'}
      />
    </div>
  );
}

function AvailableKeywords({ used = 0, total = 10 }) {
  const planId = useCharbeePlanId();
  const customPrice = useSelector((state) => state.prerenderUser.customPrice);
  const isUserOnCustomPlan = isEnterprise(planId) || customPrice !== null;

  const isFreePlan = isFree(planId);
  return (
    <Card size="small">
      <Flex align="center" justify="center" gap="middle">
        {isFreePlan && <Typography.Text strong>You need a package to start tracking</Typography.Text>}

        {!isFreePlan && <KeywordsUsage used={used} total={total} />}

        <AddMoreCta isFreePlan={isFreePlan} isCustomPlan={isUserOnCustomPlan} />
      </Flex>
    </Card>
  );
}

export default AvailableKeywords;
