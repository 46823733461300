import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEvent } from '../events/hooks/useEvent';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

export const useIntegrationWizardSteps = (stepsConfig) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [prevStep, setPrevStep] = useState(-1);
  const { track } = useEvent();
  const user = usePrerenderUser();

  useEffect(() => {
    const localStorageCurrentStep = localStorage.getItem('integrationWizardStep');
    if (localStorageCurrentStep) {
      const storedIndex = stepsConfig.findIndex((item) => item.path === localStorageCurrentStep);
      if (storedIndex !== -1) {
        setCurrentStep(storedIndex);
      }
      history.push(`/integration-wizard/${localStorageCurrentStep}`);
    }
  }, []);

  const onGoBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
    setPrevStep(currentStep);
    localStorage.setItem('integrationWizardStep', stepsConfig[currentStep - 1].path);
    history.push(`/integration-wizard/${stepsConfig[currentStep - 1].path}`);
  }, [currentStep, history, stepsConfig]);

  const onGoNext = useCallback(() => {
    if (currentStep !== stepsConfig.length - 1) {
      setCurrentStep(currentStep + 1);
      setPrevStep(currentStep);
      localStorage.setItem('integrationWizardStep', stepsConfig[currentStep + 1].path);
      history.push(`/integration-wizard/${stepsConfig[currentStep + 1].path}`);
    } else {
      history.push('/billing/plans');
      track('Integration wizard: Upgrade now button clicked', { subscription_plan: user.chargebeePlanId });
    }
  }, [currentStep, history, stepsConfig]);

  const goToStart = useCallback(() => {
    setCurrentStep(0);
    setPrevStep(currentStep);
    localStorage.setItem('domainSubstep', 'enterDomain');
    localStorage.setItem('integrationWizardStep', stepsConfig[0].path);
    history.push(`/integration-wizard/${stepsConfig[0].path}`);
  }, [history, stepsConfig]);

  const Step = stepsConfig[currentStep].component;

  return { Step, onGoBack, onGoNext, goToStart, stepsConfig, currentStep, setCurrentStep, prevStep };
};
