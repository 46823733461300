import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { createHubSpotFormUrl } from '../../../utils/createHubSpotFormUrl';

const PackageCardKeyword100Plus = () => {
  const user = useSelector((state) => state.prerenderUser);
  const hubSpotFormUrl = createHubSpotFormUrl(user.firstName, user.email);

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <p style={{ fontSize: '20px' }} className="ml-2 pt-2 card-title font-weight-bold ">
            Keyword 1000+
          </p>
        </div>

        <div className="d-flex align-items-end">
          <span style={{ fontSize: '60px' }} className="font-weight-bold d-flex align-items-end">
            Custom
          </span>
        </div>

        <div style={{ minHeight: 230 }}>
          <p>Need more than 1000 keywords? Our sales team will tailor a solution to fit your enterprise-level needs.</p>
        </div>

        <a href={hubSpotFormUrl} target="_blank">
          <Button type="primary" style={{ width: '100%' }} onClick={() => {}}>
            Contact Us
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PackageCardKeyword100Plus;
