import { VALIDATE_INTEGRATION_IN_PROGRESS, VALIDATION_FINISHED, CLEAR_VALIDATION_RESULT,
  INTEGRATION_CONTENT_LOADING, INTEGRATION_CONTENT_LOADED, STEP_CHANGED } from './actionTypes';

const initialState = {
  inProgress: false,
  validationResult: null,
  integrationContentLoading: false,
  integraionContent: null,
  selectedIntegration: null,
  step: 0,
};

const onboardingReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case VALIDATE_INTEGRATION_IN_PROGRESS:
      if (payload === true) {
        return { ...state, inProgress: payload, validationResult: null };
      }

      return { ...state, inProgress: payload };

    case VALIDATION_FINISHED:
      return { ...state, validationResult: { ...payload.validationResult, url: payload.url } };

    case CLEAR_VALIDATION_RESULT:
      return { ...state, validationResult: null };

    case INTEGRATION_CONTENT_LOADING:
      return { ...state, integrationContentLoading: true, integraionContent: null };

    case INTEGRATION_CONTENT_LOADED:
      return { ...state, integrationContentLoading: false, selectedIntegration: payload.integration, integraionContent: payload.content };

    case STEP_CHANGED:
      return { ...state, step: payload };

    default: return state;
  }
};

export default onboardingReducer;
