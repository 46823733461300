import { Flex } from 'antd';
import { useEffect, useState } from 'react';
import { useGetDomainQuery, useSetDomainMutation } from '../../../api/integrationWizardSlice';
import SpinningWheel from '../../../../../components/SpinningWheel';
import EnterDomainForm from './domainSubsteps/EnterDomainForm';
import RenderedPagePreview from './domainSubsteps/RenderedPagePreview';

const domainSubsteps = ['enterDomain', 'scanDomain'];

const ScanDomainStep = ({ onGoNext }) => {
  const { data, isFetching, error } = useGetDomainQuery(null, { refetchOnMountOrArgChange: true });
  const [setDomain, { isSuccess: setDomainIsSuccess, isError: setDomainIsError, data: setDomainData }] =
    useSetDomainMutation();
  const [domainInput, setDomainInput] = useState('');
  const [currentDomainSubstep, setCurrentDomainSubstep] = useState(domainSubsteps[0]);

  useEffect(() => {
    if (data) {
      setDomainInput(data.domain || '');
    }
  }, [data]);

  useEffect(() => {
    if (setDomainIsSuccess) {
      setCurrentDomainSubstep(domainSubsteps[1]);
      setDomainInput(setDomainData.domain.domain);
    }
  }, [setDomainIsSuccess, setDomainData]);

  const onFinish = (values) => {
    const newDomain = values.domain;
    setDomain(newDomain);
    setDomainInput('');
  };

  const onGoBack = () => {
    setCurrentDomainSubstep(domainSubsteps[0]);
  };

  if (isFetching) return <SpinningWheel />;

  return (
    <Flex vertical align="center" justify="center" gap="middle" style={{ minHeight: 450 }}>
      {currentDomainSubstep === 'enterDomain' && (
        <EnterDomainForm onFinish={onFinish} domain={domainInput} loading={isFetching} error={setDomainIsError} />
      )}
      {currentDomainSubstep === 'scanDomain' && (
        <RenderedPagePreview onGoNext={onGoNext} onGoBack={onGoBack} domain={domainInput} />
      )}
    </Flex>
  );
};

export default ScanDomainStep;
