import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

const CustomTooltip = ({ isFreePlan, hasSelectedUrls, children }) => {
  if (isFreePlan)
    return (
      <Tooltip
        title={
          <p>
            The export is only available for our paid subscriptions. You can change your subscription in the{' '}
            <Link to="/billing/plans">Billing section</Link>
          </p>
        }
      >
        {children}
      </Tooltip>
    );

  if (hasSelectedUrls)
    return (
      <Tooltip title="The export action will run for all filtered URLs and not only a selected batch.">
        {children}
      </Tooltip>
    );

  return children;
};

const ExportButton = ({ isFreePlan = false, hasSelectedUrls = false, onClick }) => {
  return (
    <CustomTooltip isFreePlan={isFreePlan} hasSelectedUrls={hasSelectedUrls}>
      <Button className="ml-2" size="small" disabled={isFreePlan} onClick={onClick}>
        Export
      </Button>
    </CustomTooltip>
  );
};

export default ExportButton;
